import { useForm } from "@mantine/form";
import { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Editor, Element, Path, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import useOutsideClick from "./useOutsideClick";

export const createLinkNode = (href, text) => ({
    type: "link",
    href,
    children: [{ text }]
});

export const insertLink = (editor, url, text) => {
    if (!url) return;

    const { selection } = editor;
    const link = createLinkNode(url, text || "link");

    ReactEditor.focus(editor);

    if (!!selection) {
        const [parentNode, parentPath] = Editor.parent(
            editor,
            selection.focus?.path
        );

        // Remove the Link node if we're inserting a new link node inside of another link.
        if (parentNode.type === "link") {
            removeLink(editor);
        }

        if (editor.isVoid(parentNode)) {
            // Insert the new link after the void node
            Transforms.insertNodes(editor, createParagraphNode([link]), {
                at: Path.next(parentPath),
                select: true
            });
        } else if (Range.isCollapsed(selection)) {
            // Insert the new link in our last known location
            Transforms.insertNodes(editor, link, { select: true });
        } else {
            // Wrap the currently selected range of text into a Link
            Transforms.wrapNodes(editor, link, { split: true });
            Transforms.insertText(editor, text)
            Transforms.collapse(editor, { edge: "end" });
        }
    } else {
        // Insert the new link node at the bottom of the Editor when selection is falsey
        Transforms.insertNodes(editor, createParagraphNode([link]));
    }
};

export const currentLink = (editor) => {
    const { selection } = editor;
    let nodes = Editor.nodes(editor, selection.focus?.path)
}


export const updateLink = (editor, { url, text }, path) => {
    Transforms.setNodes(editor, { href: url }, { at: path })
    if (text) {
        Transforms.insertText(editor, text, { at: path })
    }
}

export const removeLink = (editor, opts = {}) => {
    Transforms.unwrapNodes(editor, {
        ...opts,
        match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "link"
    });
};

const createParagraphNode = (children = [{ text: "" }]) => ({
    type: "paragraph",
    children
});

export function LinksPopout({ editor, onSave, initialText = "", initialURL = "", close }) {

    const { t } = useTranslation('chat')
    const wrapperRef = useRef(null);
    const form = useForm({
        initialValues: {
            text: initialText,
            url: initialURL,
        },
    });


    useOutsideClick(wrapperRef, () => {
        close(false)
    })

    const onSaveLink = () => {
        if (onSave) {
            onSave(form.values['url'], form.values['text'])
            return
        }

        insertLink(editor, form.values['url'], form.values['text'])
        close();
    }

    return (
        <div ref={wrapperRef} className='link-popout'>
            <div className="d-flex flex-row">
                <Form.Group className="flex-grow-1 mr-1">
                    <Form.Label>{t("Link Text")}<span className="general-error ml-2">{ }</span></Form.Label>
                    <Form.Control type="text" placeholder="Text" maxLength={55}  {...form.getInputProps('text')} />
                </Form.Group>
                <Form.Group className="flex-grow-1 ml-1" >
                    <Form.Label>{t("Link URL")}</Form.Label>
                    <Form.Control type="text" placeholder="www.example.com" maxLength={55}  {...form.getInputProps('url')} />
                </Form.Group>
            </div>
            <div className="ml-auto">
                <Button size="sm" variant="secondary" type="submit" className="ml-auto" onClick={close}>{t("Cancel")}</Button>
                <Button size="sm" variant="primary" type="submit" className="ml-2" onClick={onSaveLink}>{t("Add")}</Button>
            </div>
        </div >
    )
}