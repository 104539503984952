import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { forgot } from "../../logic/api";
import backgroundSide from '../../resources/askly-small-white.svg';
import { LanguageSwitch } from "../base/minor/LanguageSwitch";
import { AuthWrap } from "./SignIn";

export default function Forgot(props) {
    const [forgotForm, setForgotForm] = useState({ email: "", errors: {} });
    const { t } = useTranslation("noauth")
    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (forgotForm.email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: t("Email is required") })
            validated = false;
        }

        setForgotForm({ email: forgotForm.email, errors: tmpErrors })
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validateForm()) {
            forgot(forgotForm.email).then(() => {
                setForgotForm({ email: forgotForm.email, errors: { general: t("Email sent") } })
            }).catch(err => {
                if (err.statusCode && err.statusCode === "NO_USER") {
                    setForgotForm({ email: forgotForm.email, errors: { email: t("No such user") } })
                } else {
                    setForgotForm({ email: "", errors: { general: t("Something went wrong. Try again later.") } })
                }
            })
        }
    }

    const leftContent = (
        <div className="left-body">
            <img className="left-bg" src={backgroundSide} alt="bg-askly" />
            <div className="text-wrap">
                <div className="left-txt-main">{t("Forgot password ?")}</div>
                <div className="left-txt-sub mt-5 ">
                    <Trans t={t}
                        defaults="Enter your email and we’ll send a link to reset your password."
                    />
                </div>
            </div>
        </div>
    )
    const rightContent = (<>
        <div style={{ display: 'flex', alignItems: 'center', height: "heigth:200px", margin: "28px 0px", float: "right" }}>
            <LanguageSwitch className='mr-3' />
            <Link to="/sign-in" className="df-btn auth-switch">{t("Sign-in")}</Link>
        </div>
        <div className="form-wrap">
            <div className="auth-form" style={{ margin: "auto" }}>
                <div style={{ maxWidth: "485px" }}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <p className="login-details my-4">{t("Reset your password")}</p>
                        <p className="general-error mb-5">{forgotForm.errors.general}</p>
                        <div className="mx-auto text-left" style={{ width: "75%" }}>
                            <Form.Group>
                                <Form.Label>{t("Email address")}</Form.Label>
                                <Form.Control type="email" placeholder={t("Enter email")} onChange={e => setForgotForm({ email: e.target.value, errors: {} })} />
                                <Form.Text className="error-text-sm">{forgotForm.errors.email}</Form.Text>
                            </Form.Group>
                        </div>
                        <button className="df-btn" style={{ marginTop: "32px" }}>{t("Send email")}</button>
                    </form>
                </div>
            </div>
        </div>
    </>)

    return <AuthWrap leftContent={leftContent} rightContent={rightContent} />
}