import classNames from 'classnames';
import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { CustomMenu, CustomToggle, CustomToggleWithPrefix, CustomToggleWithQuickpicks } from './CustomDropdown';
import './SearchDropDown.scss';

export function SearchDropdownWithQuickPicks({ className, menuWidth, prefixRight, quickPicks, topAlign, leftAlign, label, currentValue, currentSelect, allSelection = [], onSelect, valueKey, labelKey, secondLabelKey }) {
    let allowedSelectElements = dropdownItems({ allSelection, onSelect, valueKey, labelKey, secondLabelKey })
    let quickPickElems = quickPicks.map(qp => <div className={classNames('fc-sub-btn', { active: (currentValue === qp.value) })} onClick={e => { e.stopPropagation(); onSelect(qp.value) }} key={qp.value}>{qp.label}</div>)

    return (
        <Form.Group className={classNames(className, " search-dropdown-wrap", { 'prefix-right': prefixRight })}>
            <div className="search-dropdown">
                <Dropdown >
                    <Dropdown.Toggle as={CustomToggleWithQuickpicks} quickPicks={quickPickElems} prefix={label}>
                        {currentSelect}
                    </Dropdown.Toggle>
                    <Dropdown.Menu topAlign={topAlign} leftAlign={leftAlign} menuWidth={menuWidth} as={CustomMenu} >
                        {allowedSelectElements}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Form.Group>
    )
}

export function SearchDropdownWithLabelPrefix({
    className,
    disabled,
    leftAlign,
    topAlign,
    menuWidth,
    useLabelAsSuffix,
    label = "Select",
    currentSelect,
    allSelection = [],
    onSelect,
    valueKey,
    labelKey,
    secondLabelKey
}) {
    let allowedSelectElements = dropdownItems({ allSelection, onSelect, valueKey, labelKey, secondLabelKey })
    return (
        <Form.Group className={className + " search-dropdown-wrap"}>
            <div className="search-dropdown">
                <Dropdown >
                    <Dropdown.Toggle as={CustomToggleWithPrefix} disabled={disabled} prefix={useLabelAsSuffix ? null : label} suffix={useLabelAsSuffix ? label : null}>
                        {currentSelect}
                    </Dropdown.Toggle>
                    <Dropdown.Menu topAlign={topAlign} leftAlign={leftAlign} menuWidth={menuWidth} as={CustomMenu} >
                        {allowedSelectElements}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Form.Group>
    )
}

export default function SearchDropdown({ className, topAlign, leftAlign, menuWidth, label = "Select", currentSelect, allSelection = [], onSelect, valueKey, labelKey, secondLabelKey }) {
    let allowedSelectElements = dropdownItems({ allSelection, onSelect, valueKey, labelKey, secondLabelKey })

    return (
        <Form.Group className={className + " search-dropdown-wrap"}>
            {label && <Form.Label>{label}</Form.Label>}
            <div className="search-dropdown">
                <Dropdown >
                    <Dropdown.Toggle as={CustomToggle}>
                        {currentSelect}
                    </Dropdown.Toggle>
                    <Dropdown.Menu topAlign={topAlign} as={CustomMenu} menuWidth={menuWidth} leftAlign={leftAlign} >
                        {allowedSelectElements}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Form.Group>
    )
}

function dropdownItems({ allSelection = [], onSelect, valueKey, labelKey, secondLabelKey }) {
    return allSelection.map((element, i) => {
        if (valueKey && !element[valueKey]) {
            return null
        }
        if (labelKey && !element[labelKey]) {
            return null
        }

        if (labelKey && secondLabelKey) {
            if (!element[labelKey] || !element[secondLabelKey])
                return null

            return <Dropdown.Item key={i} searchkey={element[labelKey] + element[secondLabelKey]} onSelect={onSelect} eventKey={(valueKey && element[valueKey]) ? element[valueKey] : element}>
                <div className="multirow-item">
                    <div className="multirow-item-main">{element[labelKey]}</div>
                    <div className="multirow-item-secondary">{element[secondLabelKey]}</div>
                </div>
            </Dropdown.Item>
        }

        return <Dropdown.Item key={i} onSelect={onSelect} eventKey={(valueKey && element[valueKey]) ? element[valueKey] : element}>
            {(labelKey && element[labelKey]) ? element[labelKey] : element}
        </Dropdown.Item>
    })
}