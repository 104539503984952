import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import './EditableDetail.scss';


export default function EditableDetail({ placeHolder, baseValue, onChange, allowEmpty }) {
    const ref = useRef(null);
    const activePastRef = useRef(false);
    const [value, setValue] = useState(baseValue || '');
    const [active, setActive] = useState(false);

    const exitEdit = e => {
        setActive(false)
    }

    const toggleEdit = e => {
        setActive(!active)
    }

    useEffect(() => {
        setValue(baseValue || '')
    }, [baseValue])

    useEffect(() => {
        if (active) {
            ref.current.focus();
            activePastRef.current = true;
        } else if (activePastRef.current) {
            onChange?.(value)
            activePastRef.current = false;
        }
    }, [active])

    const onInputKeyDown = e => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            e.preventDefault();
            setActive(false)
        }
    }

    return (
        <div className={classNames("editable-detail", { active })} >
            <div className="detail-wrap">
                <input ref={ref}
                    placeholder={placeHolder}
                    maxLength={120}
                    value={value}
                    onChange={(e) => { setValue(e.target.value) }}
                    onKeyDown={onInputKeyDown}
                    disabled={!active}
                    onBlur={exitEdit}></input>
                <div className='edit-btn' onClick={toggleEdit}><i className="fas fa-pen"></i></div>
            </div>
        </div>
    )
}
