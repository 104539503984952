import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from "react-i18next";
import { genUUID } from '../../logic/util';
import './Toast.scss';

export let addInfoToast;
export let addGeneralTopToast;
export let addReconnectingToast;
export let addReconnectSuccessToast;
export let addErrorToast;
export let clearTopToast

export default class ToastContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toasts: [],
            topToast: null
        }

        addGeneralTopToast = (text) => {
            this.setState(previousState => ({
                ...previousState,
                topToast: <GeneralTopToast text={text} />,
            }));
        }

        addReconnectingToast = (text) => {
            this.setState(previousState => ({
                ...previousState,
                topToast: <ReconnectingToast />,
            }));
        }

        addReconnectSuccessToast = (text) => {
            this.setState(previousState => ({
                ...previousState,
                topToast: <ReconnectSuccessToast onDelete={this.removeTopToast} />,
            }));
        }

        addInfoToast = (text) => {
            this.setState(previousState => ({
                toasts: [...previousState.toasts, { id: genUUID(), text: text, type: "info" }]
            }));
        }

        addErrorToast = (text) => {
            this.setState(previousState => ({
                toasts: [...previousState.toasts, { id: genUUID(), text: text, type: "warning" }]
            }));
        }

        clearTopToast = () => {
            this.setState({ topToast: null });
        }

        this.removeToast = this.removeToast.bind(this)
        this.removeTopToast = this.removeTopToast.bind(this)
    }

    removeToast(index) {
        var array = [...this.state.toasts]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ toasts: array });
        }
    }

    removeTopToast() {
        this.setState({ topToast: null });
    }

    render() {
        let toasts = this.state.toasts.map((el, i) => (
            el.type === "info" ?
                <InfoAlert onDelete={this.removeToast} text={el.text} key={el.id} id={el.id} /> :
                <ErrorAlert onDelete={this.removeToast} text={el.text} key={el.id} id={el.id} />
        ))


        return (
            <>
                <div aria-live="polite" aria-atomic="true" className="toasts-container">
                    <div className="toasts-list">
                        {toasts}
                    </div>
                </div>
                <div aria-live="polite" aria-atomic="true" className="toasts-center-container">
                    <div className="toasts-list dark">
                        {this.state.topToast}
                    </div>
                </div>
            </>)
    }
}

function ErrorAlert(params) {
    return (
        <Toast className="tw-toast d-flex" onClose={() => params.onDelete(params.id)} delay={4500} autohide>
            <i className="fad fa-exclamation-circle my-auto fa-error"></i>
            <div className="toast-body my-auto">{params.text}</div>
        </Toast>
    )
}


function InfoAlert(params) {
    return (
        <Toast className="tw-toast d-flex" onClose={() => params.onDelete(params.id)} delay={4500} autohide>
            <i className="fad fa-info-circle my-auto fa-success"></i>
            <div className="toast-body my-auto">{params.text}</div>
        </Toast>
    )
}

function GeneralTopToast({ text }) {
    return (
        <Toast className="tw-toast d-flex" >
            <div className="toast-body my-auto">{text}</div>
        </Toast>
    )
}

function ReconnectingToast() {
    const { t } = useTranslation("common")
    return (
        <Toast className="tw-toast d-flex">
            <div className="toast-body my-auto">{t("Connection lost. Reconnecting ...")}</div>
        </Toast>
    )
}

function ReconnectSuccessToast(params) {
    const { t } = useTranslation("common")

    return (
        <Toast className="tw-toast d-flex" onClose={() => params.onDelete(params.id)} delay={2500} autohide>
            <div className="toast-body my-auto">{t("Connected")}</div>
        </Toast>
    )
}