import classNames from 'classnames'
import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import logo from '../../resources/askly-small.svg'
import './MainSideBar.scss'
import { SeparatorDots } from './minor/Common'

function MainSideBar({ match }) {
    const chatsWrap = useSelector(state => state.chatList)
    const [quickstartOpen, setQuickStartOpen] = useState(false)
    const { t } = useTranslation("common")

    return (
        <div className="main-side-bar-wrap">
            <div className={classNames("main-side-bar ", { wide: quickstartOpen })}>
                <div className='d-flex flex-column'>
                    <img src={logo} alt="Askly Logo" />
                    <div className="side-bar-inner mt-4">
                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Chats")}</Tooltip>}>
                            {(props) => (
                                <Link {...props} to="/chats" className={`sb-step-1 mx-auto side-bar-link ${match.path === "/chats" ? "active" : ""}`} >
                                    <i className="far fa-comment-dots"></i>
                                    {(chatsWrap?.allNotifications?.length > 0) && <div className="red-dot"></div>}
                                </Link>)}
                        </OverlayTrigger>
                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Analytics")}</Tooltip>}>
                            {(props) => (
                                <Link {...props} to="/analytics" className={`sb-step-2 mx-auto side-bar-link ${match.path === "/analytics" ? "active" : ""}`} >
                                    <i className="far fa-analytics"></i>
                                </Link>)}
                        </OverlayTrigger>
                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Boosters")}</Tooltip>}>
                            {(props) => (
                                <Link {...props} to="/popups" className={`sb-step-2 mx-auto side-bar-link ${match.path.startsWith("/popups") ? "active" : ""}`} >
                                    <i className="far fa-rocket"></i>
                                </Link>)}
                        </OverlayTrigger>

                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Settings")}</Tooltip>}>
                            {(props) => (
                                <Link {...props} to="/settings/personal" className={`sb-step-2 mx-auto side-bar-link ${match.path === "/settings" ? "active" : ""}`} >
                                    <i className="far fa-cog"></i>
                                </Link>)}
                        </OverlayTrigger>
                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Help")}</Tooltip>}>
                            {(props) => (
                                <Link {...props} to="/tutorials" className={`mx-auto side-bar-link mb-0 ${match.path === "/tutorials" ? "active" : ""}`} >
                                    <i className="far fa-question-circle"></i>
                                </Link>)}
                        </OverlayTrigger>
                    </div>
                    <QuickStartIco setQuickStartOpen={setQuickStartOpen} quickstartOpen={quickstartOpen} />

                    <div className="bottom-extra d-flex flex-column" style={{ margin: "auto", marginBottom: "0px" }}>
                        <OverlayTrigger delay={400} transition={false} placement="right" overlay={<Tooltip>{t("Log out")}</Tooltip>}>
                            {(props) => (
                                <a {...props} href="/api/logout" className="mx-auto side-bar-link" >
                                    <i className="far fa-sign-out"></i>
                                </a>)}
                        </OverlayTrigger>
                    </div>
                </div>
                <QuickStart open={quickstartOpen} close={() => setQuickStartOpen(false)} />
            </div>
        </div>
    )
}

export default MainSideBar;

function QuickStartIco(params) {
    const user = useSelector(state => state.user)
    const { t } = useTranslation("common")

    let stepsTaken = 0

    if (user?.siteDetails?.siteSettingsWrap?.settings?.schedules?.times?.length > 0)
        stepsTaken++

    if (user?.siteDetails?.facebookChannel?.length > 0)
        stepsTaken++

    if (user.userDetails?.languages?.length > 0)
        stepsTaken++

    if (stepsTaken === 3) {
        return null
    }

    return (
        <div className={classNames('quickstart-ico ', { active: params.quickstartOpen })} onClick={() => { params.setQuickStartOpen(!params.quickstartOpen) }}>
            {t("Look Here")}
            <i className="fas fa-graduation-cap"></i>
            <div className={classNames('load-steps')}>{stepsTaken} / 3</div>
        </div>
    )
}
function QuickStart(params) {
    const user = useSelector(state => state.user)
    const { t } = useTranslation("common")

    let scheduleSet = user?.siteDetails?.siteSettingsWrap?.settings?.schedules?.times?.length > 0
    let fbConnected = user?.siteDetails?.facebookChannel?.length > 0
    let languagesAdded = user.userDetails?.languages?.length > 0


    return (<div className={classNames('quickstart-wrap', { open: params.open })}>
        <div className='qs-title-wrap'>
            <div className='qs-title-text-wrap'>
                <div className='qs-title-text-title'>{t("Getting started")}</div>
                <div className='qs-title-text-sub'>{t("Checklist to get more value")}</div>
            </div>
            <div className='actions' onClick={params.close}>{t("Close")}</div>
        </div>

        <SeparatorDots />
        <div>
            <QSStep
                to="/settings/site#fb-connect"
                done={fbConnected}
                title={t("Connect Facebook")}
            />
            <QSStep
                to="/settings/personal"
                done={languagesAdded}
                title={t("Set your languages")}
            />
            <QSStep
                to="/settings/site/web-widget#set-schedule"
                title={t("Set working hours")}
                done={scheduleSet}
            />
        </div>
        <SeparatorDots />
        <div className='qs-text-wrap'>
            <Trans t={t}
                defaults="Download our mobile app for iOS or android by searching <i>Askly Chat</i> on App Store or Play store"
            />
        </div>
    </div>)
}

function QSStep(params) {

    return (
        <div className={classNames('qs-step', { done: params.done })} onClick={params.onClick}>
            {params.nonStep && <div className="qs-step-title" >
                <div className=""> <i className="fad fa-check-circle"></i> {params.title}</div>
            </div>}
            {!params.nonStep && <Link to={params.to} className="qs-step-title" >
                <div className=""> <i className="fad fa-check-circle"></i> {params.title}</div>
            </Link>}
        </div>
    )
}