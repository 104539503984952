import cn from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { withTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { saveSiteSettingsFeatures } from '../../logic/api'
import gaLogo from '../../resources/integrations/google-analytics-icon.svg'
import { fetchMyDetails } from '../../state/currentUser'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import './Modal.scss'

class GoogleAnalyticsManage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            googleAnalytics: {
                enabled: false,
                auto: true,
                trackingKey: "",
            }
        }
        this.saveGoogleAnalytics = this.saveGoogleAnalytics.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
    }

    componentDidMount() {
        let googleAnalytics = this.props.data.siteDetails?.siteSettingsWrap?.settings?.features?.googleAnalytics

        this.setState({
            googleAnalytics: {
                enabled: (googleAnalytics?.enabled || false),
                auto: (googleAnalytics ? (googleAnalytics?.auto || false) : true),
                trackingKey: (googleAnalytics?.trackingKey || ""),
            }
        })
    }

    cancelClick() {
        if (this.props.onCancel) {
            this.props.onCancel()
            this.props.onClose();
        } else {
            this.props.onClose()
        }
    }

    saveGoogleAnalytics() {
        const { t } = this.props;

        let features = {
            googleAnalytics: {
                enabled: this.state.googleAnalytics?.enabled,
                auto: this.state.googleAnalytics?.auto,
                trackingKey: this.state.googleAnalytics?.trackingKey,
            }
        }

        saveSiteSettingsFeatures(features).then(() => {
            addInfoToast(t("Google Analytics connection saved"))
            this.props.dispatch(fetchMyDetails())
            this.props.onClose()
        }).catch(e => {
            addErrorToast(t("Could not save Google Analytics connection"))
        })
    }

    render() {
        const { t } = this.props;
        let googleAnalytics = this.state.googleAnalytics;

        return (
            <Modal className={cn("tw-modal confirm-modal", { 'secondary': this.props.isSecondary })} centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                <div className="modal-icon">
                    <img style={{ height: '100%', width: '100%', padding: 10 }} src={gaLogo} alt="google analytics logo" />
                </div>
                <div className='d-flex align-items-center'>
                    <h5 className="modal-title my-2">
                        {t("Google analytics")}
                    </h5>
                    <div className='d-flex ml-auto align-items-center mr-2'>
                        <Form.Label className='mb-0 d-flex align-items-center user-select-none'>
                            {t(googleAnalytics.enabled ? "Enabled" : "Disabled")}
                            <Form.Check type="switch" id="form-switch" className='ml-2' checked={googleAnalytics.enabled} onChange={() => { this.setState({ googleAnalytics: { ...googleAnalytics, enabled: !googleAnalytics.enabled } }) }} />
                        </Form.Label>
                    </div>
                </div>
                <div className="mt-3 mx-3">
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Label className='d-flex'>
                            <Form.Check type="radio" name="ga-option" value={"auto"} label="" checked={googleAnalytics.auto} disabled={!googleAnalytics.enabled} onChange={(e) => { this.setState({ googleAnalytics: { ...googleAnalytics, auto: e.target.value === "auto" } }) }} />
                            {t("GA automatic detection")}
                        </Form.Label>
                        <div style={{ color: "#adb5bd", fontSize: 14 }}>{t("Askly will use Google Analytics available in window context of the page and use it to send events.")}</div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='d-flex'>
                            <Form.Check type="radio" name="ga-option" label="" value={"manual"} checked={!googleAnalytics.auto} disabled={!googleAnalytics.enabled} onChange={(e) => { this.setState({ googleAnalytics: { ...googleAnalytics, auto: e.target.value === "auto" } }) }} />
                            {t("GA tracking ID")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("G-XXXXXXXXXX")}
                            disabled={!googleAnalytics.enabled || googleAnalytics.auto}
                            value={googleAnalytics?.trackingKey || ""}
                            onChange={e => { this.setState({ googleAnalytics: { ...googleAnalytics, trackingKey: e.target.value } }) }} />
                    </Form.Group>
                </div>
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto">

                    </div>
                    <Button disabled={this.state.isLoading} className="mr-3" variant="outline-primary" onClick={this.cancelClick}>
                        {t("Cancel", { ns: 'common' })}
                    </Button>
                    <Button disabled={this.state.isLoading} variant="primary" onClick={this.saveGoogleAnalytics}>
                        <span className="confirm-text">{t("Save", { ns: 'common' })}</span>
                        <div className="loader">{t("Loading...", { ns: 'common' })}</div>
                    </Button>
                </div>
            </Modal >
        );
    }
}

export default withTranslation("integrations")(connect()(GoogleAnalyticsManage));