import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './MediaModal.scss'
import './Modal.scss'

export default function MediaModal(props) {
    let mediaElem;
    if (props.data.mimeType?.includes("video")) {
        mediaElem = <video controls autoplay preload="auto" src={props.data.mediaURL} />
    } else {
        mediaElem = <img src={props.data.mediaURL} alt="Media" />
    }

    return (
        <Modal fullscreen={true}
            backdropClassName="dark-backdrop"
            className="tw-modal media-modal"
            centered
            show={props.show}
            onHide={props.onClose}
            dialogAs={MediaDialog}
            animation={false}>
            <div>
                <div className='media-tools position-absolute'>
                    <a href={props.data.mediaURL} target="_blank" rel="noreferrer" download className='tool-opt download-media' onClick={props.onClose}>
                        <i className="far fa-arrow-to-bottom"></i>
                    </a>
                    <div className='tool-opt ml-auto close-media-modal' onClick={props.onClose}>
                        <i className="far fa-times"></i>
                    </div>
                </div>
                {mediaElem}
            </div>

        </Modal >
    );
}


function MediaDialog(params) {
    return (
        <div className='media-wrap d-flex'>
            {params.children}
        </div>
    )
}