import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './Modal.scss'

class LockedModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false }
    }

	render() {
		return (
            <Modal className="tw-modal confirm-modal" centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                {this.props.data.modalIcon && <div className="modal-icon"> {this.props.data.modalIcon}</div>}
                <h5 className="modal-title my-2">{this.props.data.title}</h5>
                <p className="footnote">{this.props.data.body}</p>
            </Modal>
		);
	}
}

export default LockedModal;