import { useForm } from '@mantine/form'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useAddEmailAddressMutation } from '../../state/api'
import { addErrorToast } from '../base/ToastManager'
import './AddEmailDomain.scss'
import './Modal.scss'



export default function AddEmailAddress(props) {
    const [addEmailAddress, { isLoading }] = useAddEmailAddressMutation()
    const { t } = useTranslation("modal_add_email_domain")
    const form = useForm({
        initialValues: {
            name: "",
            email: "",
            domain: props.strictDomain ? (props.emailSettings?.domains?.find((domain) => domain.id === props.strictDomain).domain || "tickets.askly.me") : "tickets.askly.me",
            externalEmailDomainID: props.strictDomain ? props.strictDomain : 0,
        },
    });

    useEffect(() => {
        form.resetDirty()
    }, [])

    const createEmailAddress = () => {
        // we async create domain

        addEmailAddress(form.values).unwrap().then((data) => {
            props.onClose()
        }).catch((err) => {
            console.log(err)
            addErrorToast(t("Failed to add domain"))
        })
    }

    //  TODO: IF no domains have been added just show a message to the user that they need to add a domain first

    return (
        <Modal className="tw-modal add-email-domain-modal" size="md" centered onShow={() => form.resetDirty()} show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title my-2">{t("Add email address")}</h5>
            <p className="footnote">{t("Add sending address that emails will be sent under.")}</p>

            <div className='mx-4'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t("Name")}</Form.Label>
                    <Form.Control type="email" placeholder="Enter name" onChange={(e) => { form.setFieldValue('name', e.target.value) }} />
                    <Form.Text className="text-muted">
                        {t("Email contact name")}
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t("Email address")}</Form.Label>
                    <InputGroup className="">
                        <Form.Control placeholder='support' onChange={(e) => { form.setFieldValue('email', e.target.value) }} />
                        <InputGroup.Append>
                            <InputGroup.Text>@</InputGroup.Text>
                        </InputGroup.Append>
                        <DropdownButton
                            variant="outline-secondary"
                            title={form.values.domain ? form.values.domain : "Select domain"}
                            id="input-group-dropdown-2"
                            onChange={(e) => { form.setFieldValue('domain', e.target.value) }}
                            align="start"
                            as={InputGroup.Append}
                        >
                            {props.emailSettings?.domains?.map((domain, index) => (
                                <Dropdown.Item key={domain.id} onSelect={() => { form.setValues((prev) => ({ ...prev, domain: domain.domain, externalEmailDomainID: domain.id })); form.setFieldValue('domain', domain.domain) }}>{domain.domain}</Dropdown.Item>
                            ))}
                            {/*<Dropdown.Item onSelect={() => { form.setValues((prev) => ({ ...prev, domain: 'tickets.askly.me', externalEmailDomainID: 0 })) }}>tickets.askly.me</Dropdown.Item>*/}
                        </DropdownButton>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        {t("Choose a domain and add an email address")}
                    </Form.Text>
                </Form.Group>
            </div>
            <div className="mt-4 mb-2 mx-3 d-flex">
                <div className="ml-auto"></div>
                {!isLoading && <Button className="mr-2" disabled={isLoading} variant="outline-primary" onClick={props.onClose}>
                    {t("Cancel")}
                </Button>}
                <Button disabled={isLoading} variant="primary" onClick={createEmailAddress}>
                    {!isLoading && <span className="confirm-tex">{t("Add address")}</span>}
                    {isLoading && <div className="loader">{t("Loading...")}</div>}
                </Button>
            </div>
        </Modal>
    );
}
