

import cn from 'classnames';
import isHotkey from 'is-hotkey';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Editor,
    Node,
    Element as SlateElement,
    Transforms
} from 'slate';
import { Editable, useFocused, useSelected, useSlate, useSlateStatic } from 'slate-react';
import { AttachmentManager } from './ChatInputFooter';
import { ImagesPopup } from './EmailEditorImages';
import { LinksPopout, removeLink, updateLink } from './EmailEditorLinks';
import { addErrorToast } from './ToastManager';
import { SeparatorVertical, ThinButton } from './minor/Common';
import { CustomPlainToggle } from './minor/CustomDropdown';
import { HTMLParser } from './minor/EditorHTML';
import EmailChips from './minor/EmailChips';
import useOutsideClick from './useOutsideClick';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const emptyInitialValue = [{ type: 'paragraph', children: [{ text: '' }] }]

const EmailEditor = ({ editor, form, emailAddresses }) => {
    const { t } = useTranslation('chat')
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    useEffect(() => {
        if (emailAddresses && emailAddresses.length > 0) {
            form.setFieldValue("fromAddressID", emailAddresses[0].id)
        }
    }, [emailAddresses])

    useEffect(() => {
    }, [])

    let pendingFiles = form.values?.files.map((file, i) => {
        let name = file.name?.split(/(\\|\/)/g).pop();
        let icon = null // file.type?.includes("image") ? <i className="file-ico far fa-file-image"></i> : <i className="file-ico far fa-file-alt"></i>
        return <span className='attachment-file' onClick={() => { }} key={i}>{icon}{name}<i className="close-ico fal fa-times"></i></span>
    })

    const ccButtons = (<div className='cc-buttons-wrap'>
        <div className='cc-button'>
            CC
        </div>
        <div className='cc-button'>
            BCC
        </div>
    </div>)

    return (
        <div className='email-creator-wrap d-flex flex-column w-100' style={{ border: '1px solid #DAE1EF', borderRadius: 6, fontSize: 14 }}>
            <EmailFormField fieldName={"fromAddressID"} fieldTitle={t("From")} fieldType={"dropdown"} form={form} emailAddresses={emailAddresses} />
            {<EmailFormField fieldName={"subject"} fieldTitle={t("Subject")} fieldType={"input"} form={form} placeholder={t("Write subject here...")} />}
            {false && <EmailFormField fieldName={"cc"} fieldTitle={"CC"} fieldType={"email-chips"} form={form} placeholder={"Add cc"} extra={<div className='cc-buttons-wrap'><div className='cc-button'>Remove</div></div>} />}
            {false && <EmailFormField fieldName={"bcc"} fieldTitle={"BCC"} fieldType={"input"} form={form} placeholder={"Add bcc"} extra={<div className='cc-buttons-wrap'><div className='cc-button'>Remove</div></div>} />}

            <Editable
                style={{ outline: 'none', margin: '10px', minHeight: 120, maxHeight: 220, overflow: 'auto' }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder=""
                spellCheck
                autoFocus
                onKeyDown={event => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                            event.preventDefault()
                            const mark = HOTKEYS[hotkey]
                            toggleMark(editor, mark)
                        }
                    }
                }}
            />
            {pendingFiles?.length > 0 && <div className='d-flex w-100 pr-2' style={{ borderTop: '1px solid #DAE1EF' }}>
                <div className='px-3 py-1' style={{ borderBottomLeftRadius: 6, borderRight: '1px solid #DAE1EF', background: '#f5f6f8', color: '#42526e' }}>{t("Attachments")}</div>
                <div className='d-flex flex-row align-items-center flex-wrap'>{pendingFiles}</div>
            </div>}
        </div>
    )
}

function EmailFormField({ fieldName, fieldType, fieldTitle, placeholder, emailAddresses, extra, form, ...props }) {
    const { t } = useTranslation('chat')
    const selectedEmail = fieldName === "fromAddressID" && emailAddresses?.find(address => address.id === form.values["fromAddressID"])
    const hasEmails = emailAddresses?.length > 0

    return (
        <div className='d-flex w-100 pr-2' style={{ borderBottom: '1px solid #DAE1EF' }}>
            <div className='px-3 py-1' style={{ borderTopLeftRadius: 6, borderRight: '1px solid #DAE1EF', background: '#f5f6f8', color: '#42526e', width: 85 }}>{fieldTitle}</div>
            {fieldType === "input" && <input type="text" placeholder={placeholder} className='subject-input' value={form.values[fieldName]} onChange={(e) => { form.setFieldValue(fieldName, e.target.value) }} />}
            {fieldType === "email-chips" && <EmailChips form={form} fieldName={fieldName} placeholder={placeholder} />}
            {fieldName === "fromAddressID" && hasEmails && <Dropdown className="mx-2 my-auto" onToggle={isOpen => { }}>
                <Dropdown.Toggle className="" as={CustomPlainToggle}>
                    {selectedEmail && <><span>{selectedEmail.name}</span> <span style={{ color: "#4a5974" }}>{selectedEmail.email}</span></>}
                    {!selectedEmail && <span>{t("Select from address")}</span>}
                    <i className='ml-2 fal fa-angle-down'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align={"left"} style={{ fontSize: 12 }}>
                    {emailAddresses?.map((address, index) => (
                        <Dropdown.Item key={address.id} className="option-item" onSelect={() => {
                            form.setValues((prev) => ({ ...prev, fromAddressID: address.id }))
                        }}>
                            <span>{address.name}</span> <span style={{ color: "#4a5974" }}>{address.email}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>}
            {fieldName === "fromAddressID" && !hasEmails && <div className='d-flex align-items-center px-2'><Link to="/settings/site/email" >{t("Add email address")} </Link>  </div>}
            {extra}
        </div>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element, renderEmail }) => {
    const style = { textAlign: element.align }

    switch (element.type) {
        case "image":
            return <Image renderEmail={renderEmail} attributes={attributes} element={element} children={children} />
        case "link":
            if (renderEmail)
                return <LinkRendered attributes={attributes} element={element} children={children} />

            return <LinkElement renderEmail={renderEmail} attributes={attributes} element={element}  >{children}</LinkElement>;
        case 'block-quote':
            const blockQuoteDefaultStyles = { borderLeft: "1px solid rgb(204, 204, 204)", paddingLeft: "1ex", margin: "0px 0px 0px 0.8ex" }

            return (
                <blockquote style={{ ...blockQuoteDefaultStyles, ...style }} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            const h1DefaultStyles = { fontSize: 22, fontWeight: 800, margin: 0, marginBottom: 6, padding: 0 }
            return (
                <h1 style={{ ...h1DefaultStyles, ...style }} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            const h2DefaultStyles = { fontSize: 16, fontWeight: 800, margin: 0, marginBottom: 6, padding: 0 }
            return (
                <h2 style={{ ...h2DefaultStyles, ...style }} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            const pDefaultStyles = { fontSize: 14, margin: 0, padding: 0 }
            return (
                <p style={{ ...pDefaultStyles, ...style }} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children || <>&#xFEFF;</>}</span>
}

export default EmailEditor


const BlockButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    return (
        <span
            className={className}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <i className={cn("area-action", icon, { 'is-open': isActive })}></i>
        </span>
    )
}

const MarkButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)

    return (
        <span
            className={className}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <i className={cn("area-action", icon, { 'is-open': isActive })}></i>
        </span>
    )
}


const SignatureButton = ({ form, emailAddresses, className, icon }) => {
    const editor = useSlate()

    const appendSignature = () => {
        const selectedEmail = emailAddresses?.find(address => address.id === form.values["fromAddressID"])
        if (!selectedEmail) {
            addErrorToast('Please select an email address')
            return
        }
        if (!selectedEmail.signature) {
            addErrorToast('Signature is not set for this email address')
            return
        }

        let signature = HTMLParser(selectedEmail.signature)
        Transforms.insertNodes(
            editor,
            [{ type: 'paragraph', children: [{ text: '' }] }, ...signature],
            { at: [editor.children.length] })
    }

    return (
        <span
            className={className}
            onMouseDown={event => {
                event.preventDefault()
                appendSignature();
            }}
        >
            <i className={cn("area-action", icon, { 'is-open': false })}></i>
        </span>
    )
}


const LinkButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)
    const [show, setShow] = useState(false)
    const [initialText, setInitialText] = useState("")


    const onClick = () => {
        const getText = (node) => { return node.text ? node.text : node.children?.map(child => getText(child)).join("") }
        let initialText = editor.selection == null ? '' : Node.fragment(editor, editor.selection).map(node => getText(node)).join("")
        setInitialText(initialText)
        if (!show) setShow(true)
    }

    return (
        <span
            id="editor-link-button"
            className={className}
            onClick={onClick}
        >
            <i className={cn("area-action", icon, { 'is-open': isActive })}></i>
            {show && <LinksPopout editor={editor} initialText={initialText} close={() => { setShow(false) }} />}
        </span>
    )
}


const ImageButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, () => {
        if (show)
            setShow(false)
    })

    return (
        <span
            ref={wrapperRef}
            className={className}
            onClick={event => { if (!show) setShow(true) }}
        >
            <i className={cn("area-action", icon, { 'is-open': isActive })}></i>
            {show && <ImagesPopup editor={editor} close={() => { setShow(false) }} />}
        </span>
    )
}

export function EditorButtons({ canSendEmail, form, emailAddresses, notesClick, submitClick, files = [], filesChanged }) {
    const { t } = useTranslation('chat')

    return (
        <>
            <ThinButton type="secondary" className="note-btn" onClick={notesClick}>
                {t("Private note")}
                <i className="far fa-comment-alt-lines" ></i>
            </ThinButton>
            <SeparatorVertical />
            <div className='menu-area'>
                <BlockButton format="heading-one" icon="far fa-h1" />
                <BlockButton format="heading-two" icon="far fa-h2" />
                <BlockButton className="mr-3" format="block-quote" icon="far fa-quote-right" />

                <MarkButton format="bold" icon="far fa-bold" />
                <MarkButton format="italic" icon="far fa-italic" />
                <MarkButton format="underline" icon="far fa-underline" />
                {/*<ImageButton format="image" icon="far fa-image" /> */}
                <LinkButton className="mr-3" format="link" icon="far fa-link" />

                {/*<BlockButton format="numbered-list" icon="far fa-list-ol" />*/}
                {/*<BlockButton className="mr-3" format="bulleted-list" icon="far fa-list-ul" />*/}

                <AttachmentManager className={"mr-3"} filesChanged={filesChanged} files={files} />

                <SignatureButton icon="far fa-signature" form={form} emailAddresses={emailAddresses} />
            </div>
            <ThinButton type="primary" className="ml-auto position-relative" onClick={submitClick}>
                <span className='send-text-wrap'>
                    {t("Send")}
                    <i className="fas fa-paper-plane" ></i>
                </span>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </ThinButton>
        </>
    )
}


const Image = ({ attributes, element, children, renderEmail }) => {
    const selected = useSelected();
    const focused = useFocused();

    if (renderEmail) {
        return <img {...attributes} src={"cid:" + element.cid}></img>
    }

    return (
        <div
            {...attributes}
            className={cn("element-image", { highlight: selected && focused })}
        >
            <div contentEditable={false}>
                <img alt={element?.alt} src={element.url} style={{ maxHeight: 420, maxWidth: 420 }} />
            </div>
            {children}
        </div>
    );
};

const LinkRendered = ({ attributes, element, children }) => {
    return <a {...attributes} href={element.href}>
        {children}
    </a>
}

const LinkElement = ({ attributes, element, children, renderEmail }) => {
    const { t } = useTranslation('chat')

    const [show, setShow] = useState(false)
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();

    if (selected && focused) {
        let s = editor.selection
        let node = editor.children[s.anchor.path[0]]

        for (let i = 1; i < s.anchor.path.length; i++) {
            node = node.children[s.anchor.path[i]]
        }
    }

    const getLinkAttributes = () => {
        let s = editor.selection
        let node = editor.children[s.anchor.path[0]]
        const getText = (node) => { return node.text ? node.text : node.children.map(child => getText(child)).join("") }

        for (let i = 1; i < s.anchor.path.length; i++) {
            node = node.children[s.anchor.path[i]]

            if (node.type === "link") {
                let internalText = getText(node)
                return {
                    initialURL: node.href,
                    initialText: internalText,
                    onSave: (url, text) => {
                        updateLink(editor, { url, text: text === internalText ? null : text }, s.anchor.path.slice(0, i + 1))
                        setShow(false)
                    }
                }
            }
        }

        return {}
    }

    const editorLinkBtn = document.getElementById('editor-link-button');
    const fullLink = element.href?.startsWith("http") ? element.href : "//" + element.href

    return (
        <span {...attributes} className="element-link">
            <a href={element.href}>
                {children}
            </a>
            {selected && focused && (
                <span className="popup" contentEditable={false}>
                    <a href={fullLink} rel="noreferrer" target="_blank">
                        {t("Open")}
                    </a>
                    <button onClick={() => setShow(true)}>
                        {t("Edit")}
                    </button>
                    <button onClick={() => removeLink(editor)}>
                        {t("Remove")}
                    </button>
                </span>
            )}
            {show && editorLinkBtn && createPortal(<LinksPopout editor={editor} close={() => { setShow(false) }} {...getLinkAttributes()} />, editorLinkBtn)}
        </span>
    );
};

export function convertValueToHtml(elems) {
    const asReactObject = (elem) => {
        if (elem.type) {
            return <Element renderEmail={true} element={elem} children={<>{elem.children.map(child => asReactObject(child))}</>} />
        } else {
            return <Leaf renderEmail={true} leaf={elem} children={elem.text} />
        }
    }
    const objs = elems.map(element => (asReactObject(element)));
    const html = renderToStaticMarkup(objs)
    let htmlWrap = `<html><head><meta http-equiv="Content-Type" content="text/html charset=UTF-8" /></head><body>${html}</body></html>`
    return htmlWrap
}
