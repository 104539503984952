import { useForm } from '@mantine/form';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Trans, useTranslation } from "react-i18next";
import { executePromiseMinTime, extractInitials, humanFileSize } from '../../../logic/util';
import { useDeleteAssistantDocumentsMutation, useGetAssistantDocumentsQuery, useGetAssistantQuery, useUpdateAssistantDocumentMutation, useUpdateAssistantMutation, useUploadAssistantDocumentsMutation } from '../../../state/api';
import { addErrorToast, addGeneralTopToast, addInfoToast, clearTopToast } from '../../base/ToastManager';
import GlobalNavigationBlocker from '../../base/minor/GlobalNavigationBlocker';
import SearchDropdown from '../../base/minor/SearchDropdown';
import { InfoTooltip } from '../../base/minor/Tooltip';
import { DefaultSettingsWrap } from './Base';
import { TabInfo } from './ChatWidget';


export default function AsklyAssistant() {
    const [tab, setTab] = useState("general")
    const { data: assistantData, isLoading: assistantIsLoading } = useGetAssistantQuery()
    const { data: documents } = useGetAssistantDocumentsQuery()
    const [uploadAssistantDocuments] = useUploadAssistantDocumentsMutation()
    const [deleteDocument] = useDeleteAssistantDocumentsMutation()
    const [updateAssistantDocument] = useUpdateAssistantDocumentMutation()
    const [updateAssistant, { isLoading: isUpdating }] = useUpdateAssistantMutation()
    const { t } = useTranslation("settings_assistant")
    let fileInput = useRef(null);


    const attachmentsUpdate = (e) => {
        if (fileInput.current.files) {
            addGeneralTopToast(t("Uploading files..."))

            let uploadPromise = uploadAssistantDocuments(fileInput.current.files).unwrap().then(() => {
                addInfoToast(t("Files uploaded"))
            }).catch(() => {
                addErrorToast(t("Failed to upload document"))
            }).finally(() => {
                fileInput.current.value = ''
                fileInput.current.files = null
            })

            executePromiseMinTime(uploadPromise, 1500).finally(() => {
                clearTopToast()
            })
        }
    };

    const updateAssistantWrap = ({ enabled, personality, useFAQ }) => {
        updateAssistant({ enabled, personality, useFAQ }).unwrap().then(() => {
            addInfoToast(t("Assistant updated"))
        }).catch(() => {
            addErrorToast(t("Failed to update assistant"))
        })
    }

    const toggleActive = (id, active) => {
        updateAssistantDocument({ id, data: { active } }).unwrap().then(() => {
            addInfoToast(t("Document updated"))
        }).catch(() => {
            addErrorToast(t("Failed to update document"))
        })
    }

    const deleteDoc = (docID) => {
        deleteDocument(docID).unwrap().then(() => {
            addInfoToast(t("Document removed"))
        }).catch((e) => {
            addErrorToast(t("Failed to remove document"))
        })
    }

    return (
        <DefaultSettingsWrap
            subPageTitle={t("Askly AI assistant", { ns: 'settings_nav' })}
            optionClick={(val) => { setTab(val) }}
            canSave={tab === "general"}
            activeSubOption={tab}
            secondRow={[
                { name: <span><i className="fas fa-cog mr-2 my-auto"></i>{t("General")}</span>, value: "general" },
                { name: <span><i className="fas fa-book mr-2 my-auto"></i>{t("Documents")}</span>, value: "documents" },
            ]}
            buttons={(<>
                {tab === "documents" && <div>
                    <Button variant="success" className="float-right" onClick={() => { fileInput?.current?.click() }}>{t("Upload")}</Button>
                    <input ref={fileInput} onChange={attachmentsUpdate} type="file" accept=".adk,.pdf,.txt,.doc,.docx,.html,.md" multiple={true} disabled={false} style={{ display: 'none' }} />
                </div>}
            </>)}>
            <div className="chat-settings-wrap">
                {!assistantIsLoading && tab === "general" && <GeneralTab initialData={assistantData} isUpdating={isUpdating} updateAssistant={updateAssistantWrap} />}

                {tab === "documents" &&
                    <Form>
                        <TabInfo noToggle >
                            <div className='d-flex flex-column'>
                                {t("Add documents to improve the performance of Askly AI assistant when replying to the customers")}
                            </div>
                        </TabInfo>
                        <Form.Group className="">
                            {!documents?.length && <div style={{ fontSize: 14, color: "#bdc8e0" }}>{t("No documents")}</div>}
                            {documents.map((d => {
                                return <DocumentRow key={d.id} data={d} delete={() => { deleteDoc(d.id) }} toggle={toggleActive} />
                            }))}
                        </Form.Group>
                    </Form>}

            </div>
        </DefaultSettingsWrap >
    )
}

function GeneralTab({ isUpdating, initialData, updateAssistant }) {
    const { t } = useTranslation("settings_assistant")
    const form = useForm({
        initialValues: {
            enabled: false,
            useFAQ: false,
            personality: "default",
            ...initialData,
        },
    });

    useEffect(() => {
        form.resetDirty()
    }, [initialData])

    const updateAssistantForm = (e) => {
        e.preventDefault()
        updateAssistant(form.values);
    }

    return (
        <Form id="current-settings-form" onSubmit={updateAssistantForm}>
            <GlobalNavigationBlocker check={form.isDirty} message={t("Changes that you made may not be saved.")} />
            <TabInfo disabled={isUpdating} checked={form.values['enabled'] || false} toggleName={t("Assistant")} onChange={(e) => { form.setFieldValue('enabled', e.target.checked) }}>
                <div className='d-flex flex-column'>
                    {t("Allow Askly assistant to handle customer queries")}
                </div>
            </TabInfo>
            <Form.Group className="bottom-separator cs-step-4">
                <h3>
                    {t("Knowledge base sources")}
                </h3>
                <Form.Check className="mb-1" type="switch" id="pos-switch" label={t("Use FAQ")} checked={form.values['useFAQ'] || false} onChange={(e) => { form.setFieldValue('useFAQ', e.target.checked) }} />
            </Form.Group>
            <Form.Group className="bottom-separator">
                <h3>{t("Handoff")} </h3>
                <Form.Row>
                    <Form.Group className='mr-5'>
                        <SearchDropdown
                            menuWidth={160}
                            leftAlign
                            label={t('General inquiries or questions')}
                            placeholder={t('Search for a person')}
                            currentSelect={t('Coming soon')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <SearchDropdown
                            menuWidth={160}
                            label={t('Technical problems')}
                            placeholder={t('Search for a person')}
                            currentSelect={t('Coming soon')}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group className='mr-5'>
                        <SearchDropdown
                            menuWidth={160}
                            leftAlign
                            label={t('Returns and refunds')}
                            placeholder={t('Search for a person')}
                            currentSelect={t('Coming soon')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <SearchDropdown
                            menuWidth={160}
                            label={t('Complaints or feedback')}
                            placeholder={t('Search for a person')}
                            currentSelect={t('Coming soon')}
                        />
                    </Form.Group>
                </Form.Row>
            </Form.Group>
            <Form.Group className="">
                <h3>
                    {t("Personality")}
                    <InfoTooltip>
                        <Trans t={t} defaults="Choose your chatbot tone of voice." shouldUnescape={true}
                            components={{ tourtip: <i className="tour-tip"></i> }} />
                    </InfoTooltip>
                </h3>
                <DropdownButton title={{
                    default: t("Default"),
                    casual: t("Casual"),
                    formal: t("Formal"),
                }[form.values['personality']] || t("Default")} onSelect={(val) => { form.setFieldValue('personality', val) }} disabled={false} >
                    <Dropdown.Item eventKey='default'>{t("Default")}</Dropdown.Item>
                    <Dropdown.Item eventKey='casual'>{t("Casual")}</Dropdown.Item>
                    <Dropdown.Item eventKey='formal'>{t("Formal")}</Dropdown.Item>
                </DropdownButton>
            </Form.Group>
        </Form>)
}

function DocumentRow(props) {
    const { t } = useTranslation("settings_assistant")

    return (
        <div className="member d-flex align-items-center mb-3">
            <div className="author d-flex align-items-center">
                <div className="avatar d-flex align-items-center justify-content-center">
                    {extractInitials(props.data.name)}
                </div>
                <div>
                    <p className="author-name">{props.data.name}</p>
                    <p className="email">{props.data.size ? humanFileSize(props.data.size) : ""}</p>
                </div>
                <div className="actions ml-auto"></div>
            </div>
            <div className="dropdown add-new-team-member ml-auto d-flex align-items-center">
                {props.data.status !== "error" && <Form.Check type="switch" className="mr-3" id={"doc-switch-" + props.data.id} checked={props.data.active || false} label={t(props.data.active ? "Active" : "Inactive")} onChange={() => { props.toggle(props.data.id, !props.data.active) }} />}
                {props.data.status === "error" && <div className='mr-3 text-danger'>{t("Error")}</div>}
                <DropdownButton title={<i className="far fa-ellipsis-v"></i>} menuAlign="right">
                    <Dropdown.Item href={"/api/site/assistant/document/" + props.data.id + "/download"} download target="_blank"  >{t("Download")}</Dropdown.Item>
                    <Dropdown.Item onClick={props.delete}>{t("Remove")}</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    )
}
