import { useForm } from "@mantine/form";
import cn from 'classnames';
import { React, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { signup } from "../../logic/api";
import { checkPassword, isEmail } from "../../logic/util";
import logo from '../../resources/main-logo.svg';
import { LanguageSwitch } from "../base/minor/LanguageSwitch";
import './MobileRegister.scss';

const isAndroid = navigator?.userAgent?.match(/android/i) ? true : false;
const isIOS = navigator?.userAgent?.match(/(ipod|ipad|iphone)/i) ? true : false;

export default function MobileRegister(props) {
    const { t } = useTranslation("noauth")
    const [confirmSent, setConfirmSent] = useState(false)
    const form = useForm({
        initialValues: {
            email: "",
            name: "",
            password: "",
        },
        validate: {
            email: value => {
                if (value.length < 1)
                    return t("Email is required")

                if (!isEmail(value))
                    return t("Valid email is required")
            },
            name: value => {
                if (value.length < 1)
                    return t("Name is required")
            },
            password: value => {
                if (value.length < 1)
                    return { msg: t("Password is required") }

                let errors = checkPassword(value) || [];
                if (errors.length > 0) {
                    return { msg: t("Password must match requirements"), passwordReq: errors }
                }
            }
        }
    });

    useEffect(() => {
        let loginHint = new URLSearchParams(props.location.search).get("login_hint")
        if (loginHint) {
            form.setFieldValue('email', loginHint)
            setStaticEmail(true)
        }
    }, [props.location]);

    const [staticEmail, setStaticEmail] = useState(false);

    function onSubmit(values) {
        signup(values).then(response => {
            setConfirmSent(true);
        }).catch(err => {
            if (err.statusCode) {
                if (err.statusCode === "EMAIL_TAKEN") {
                    form.setErrors({ email: t("Email taken") })
                } else if (err.statusCode === "INVALID_PASSWORD") {
                    form.setErrors({ password: t("Password must match requirements") })
                } else if (err.statusCode === "EMAIL_UNVERIFIED") {
                    form.setErrors({ email: t("Email unverified") })
                } else {
                    form.setErrors({ general: t("Something went wrong", { ns: 'common' }) })
                }
            } else {
                form.setErrors({ general: t("Something went wrong", { ns: 'common' }) })
            }
        });
    }

    return (<div className="mob register-mob-view" style={{ width: "100vw", minHeight: "100vh" }}>
        <div className="top-part">
            <div className="d-flex mb-5">
                <div className="d-flex"><img className="mx-auto logo" src={logo} alt="" /></div>
                <LanguageSwitch className='ml-auto' />
            </div>
            {confirmSent === false &&
                <form className="d-flex flex-column" onSubmit={form.onSubmit(onSubmit)}>
                    <div className="mt-3">
                        <WrapWithShapes className="mb-4">
                            <h3 className="text-center mb-0"><Trans t={t} defaults="Try Askly <br/> 14 days on us!" /></h3>
                            <div className="text-center">{t("No credit card. No obligations.")}</div>
                            <p className="general-error text-center mb-3">{form.errors.general}</p>
                        </WrapWithShapes>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>{t("Email address")}</Form.Label>
                            <Form.Control autoComplete="email" type="text" placeholder={t("Enter email")} disabled={staticEmail} {...form.getInputProps('email')} />
                            <Form.Text className="error-text-sm">{form.errors.email}</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("Full name")}</Form.Label>
                            <Form.Control autoComplete="name" type="text" placeholder={t("Full name")} maxLength={75} {...form.getInputProps('name')} />
                            <Form.Text className="error-text-sm">{form.errors.name}</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("Password")}</Form.Label>
                            <Form.Control autoComplete="new-password" type="password" placeholder={t("Password")}   {...form.getInputProps('password')} />
                            <Form.Text className="error-text-sm">{form.errors.password?.msg}</Form.Text>
                        </Form.Group>
                    </div>
                    <div className="row password-requirements px-2 mt-2">
                        <div className={"col-6" + (form.errors.password?.passwordReq?.includes("err_lower") ? " error" : "")}>{t("One lowercase character")}</div>
                        <div className={"col-6" + (form.errors.password?.passwordReq?.includes("err_upper") ? " error" : "")}>{t("One uppercase character")}</div>
                        <div className={"col-6" + (form.errors.password?.passwordReq?.includes("err_digits") ? " error" : "")}>{t("One number")}</div>
                        <div className={"col-6" + (form.errors.password?.passwordReq?.includes("err_len") ? " error" : "")}>{t("6 characters minimum")}</div>
                    </div>
                    <button className="df-btn" style={{ marginTop: "26px" }} type="submit">{t("Create account")}</button>
                    <div className="sidelines"><span >{t("OR")}</span></div>
                    <div className="social-grid">
                        <a href="/api/login/google" className="df-btn df-btn-inverse google">{t("Google")}</a>
                        <a href="/api/login/facebook" className="df-btn df-btn-inverse facebook">{t("Facebook")}</a>
                        <a href="/api/login/apple" className="df-btn df-btn-inverse apple ">{t("Apple")}</a>
                    </div>
                    <p className="tos-agree-text">
                        <Trans t={t} defaults="By clicking Create account, I confirm that I have read and agree to the <terms>Askly Terms of Service</terms>, <privacy>Privacy Policy</privacy>, and to receive emails and updates."
                            components={{
                                terms: <a href="https://askly.me/en/terms-of-service" />,
                                privacy: <a href="https://askly.me/en/privacy-policy" />,
                            }} />
                    </p>
                </form>}
            {confirmSent === true && <div className="confirm-sent mt-5">
                <WrapWithShapes>
                    <h3 className="text-center mt-5">{t("Email sent")}</h3>
                </WrapWithShapes>
                <p className="text-center pt-5 mb-auto">{t("Please verify your email. You can find the verification link on your email")}</p>
            </div>}
        </div>
    </div >)
}


function WrapWithShapes(params) {
    return (<div className={cn("shape-wrap", params.className)}>
        {params.children}
        <div className="shape pos-1"><Pie /></div>
        <div className="shape pos-2"><Leaf /></div>
        <div className="shape pos-3"><Triangle /></div>
        <div className="shape pos-4"><Circle /></div>
    </div>)
}

function Pie(params) {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.5 219.5" data-inject-url="https://www.askly.me/assets2/img/svg/pie.svg" class="svg-inject icon-svg w-100 h-100"><path class="svg-fill" d="M219.5 219.5H0c0-58.19 23.14-114.06 64.29-155.21A219.561 219.561 0 01219.5 0v219.5z"></path></svg>)
}


function Leaf(params) {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 457.71 446.45" data-inject-url="https://www.askly.me/assets2/img/svg/scribble.svg" class="svg-inject icon-svg w-100 h-100"><path class="svg-fill" d="M205.62 442.54c-.51-.41-.99-.84-1.46-1.31-10.4-10.39-3.61-27.12 11.63-52.5 11.72-19.51 28.48-43.74 46.22-69.39 17.53-25.34 35.66-51.54 49.01-73.47 14.73-24.19 18.57-35.01 19.51-39.48-3.86-.28-13.15.3-33.78 6.52-19.71 5.94-43.09 14.92-65.7 23.6-63.8 24.49-85.35 31.41-96.51 19.5-3.21-3.43-7.62-11.04-1.12-23.54 2.49-4.79 6.64-10.51 12.68-17.5 10.4-12.03 25.04-26.34 40.55-41.49 14.02-13.7 28.53-27.87 39.53-40.11 9.93-11.04 14.15-17.43 15.94-20.82-3.43-.75-10.24-1.51-23.25-.92-14.99.68-33.44 2.89-51.28 5.02-46.92 5.61-74.09 8.33-86.81-.99-4.78-3.5-7.67-8.42-8.41-14.24-1.97-15.58 12.45-33.15 29.14-53.5 5.31-6.47 13.31-16.22 17.36-22.68-4.51-.38-12.43-.4-25.97 1.1-16.54 1.82-35.62 5.22-50.95 7.96-12.62 2.25-22.6 4.03-28.48 4.49C6.6 39.3.58 34.17.04 27.28c-.54-6.88 4.6-12.9 11.48-13.44 4.66-.37 14.58-2.13 26.06-4.18C54.56 6.63 75.7 2.86 94 1.07c26.41-2.6 40.16-.54 47.48 7.13 7.01 7.34 6.45 17.66-1.68 30.66-4.72 7.55-11.63 15.98-18.95 24.9-6.18 7.53-12.57 15.31-17.24 22.19-3.35 4.92-4.95 8.13-5.71 10.07 3 .54 9.09 1.08 20.87.43 13.21-.73 29.07-2.63 45.86-4.64 59.99-7.17 94.33-10.22 102.49 10.62 7.4 18.93-16.51 43.51-62.99 88.92-12.69 12.4-24.68 24.11-34.04 34.28-3.17 3.45-5.68 6.34-7.67 8.75 15.88-4.42 41.18-14.13 59.67-21.22 62.4-23.96 101.69-37.87 121.09-29.14 5.38 2.42 9.26 6.47 11.23 11.72 7.48 19.95-16.15 57.31-71.83 137.82-15.49 22.39-30.12 43.55-41.18 61.25-7.13 11.4-11.3 19.11-13.75 24.25 8.97-3.3 25.58-11 55.73-28.8 32.68-19.29 70.61-44.04 101.09-63.94 24.62-16.07 44.07-28.76 54.65-34.68 6.03-3.37 13.64-1.22 17.01 4.81 3.37 6.03 1.22 13.64-4.81 17.01-9.83 5.5-29.92 18.61-53.18 33.79-33.46 21.84-75.1 49.01-110.05 69.21-49.35 28.51-70.85 35.44-82.46 26.07z"></path></svg>)
}


function Triangle(params) {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.42 329.24" data-inject-url="https://www.askly.me/assets2/img/svg/tri.svg" class="svg-inject icon-svg w-100 h-100"><g data-name="Layer 2"><path class="svg-fill" d="M164.34 21.92L8.72 251.15c-22.54 33.2 1.24 78.09 41.37 78.09h311.24c40.13 0 63.91-44.89 41.37-78.09L247.08 21.92a50 50 0 00-82.74 0z" data-name="Layer 1"></path></g></svg>)
}


function Circle(params) {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-inject-url="https://www.askly.me/assets2/img/svg/circle.svg" class="svg-inject icon-svg w-100 h-100"><g data-name="Layer 2"><path class="svg-fill" d="M250 0C111.93 0 0 111.93 0 250s111.93 250 250 250 250-111.93 250-250S388.07 0 250 0zm0 425a175 175 0 11175-175 175 175 0 01-175 175z" data-name="Layer 1"></path></g></svg>)
}