import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import appPackage from '../package.json';
import App from './App';
import { message_receive } from './logic/util';
import reportWebVitals from './reportWebVitals';
import store from './state/store';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import './custom-bootstrap.scss';
import './index.css';

window.addEventListener('storage', message_receive);

Sentry.init({
  dsn: "https://71f25c1a1d5f40edb744174db64d8b4e@o1134240.ingest.sentry.io/6181528",
  release: appPackage.version,
  ignoreErrors: [
    "Failed to read the 'localStorage'",
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: Load failed',
    'ResizeObserver loop limit exceeded',
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
