import { Suspense, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import ToastManager from './components/base/ToastManager';
import TourManager from './components/base/TourManager';
import ModalManager from './components/modals/ModalManager';
import AnalyticsPage from './components/pages/AnalyticsPage';
import ChatPage from './components/pages/ChatPage';
import { SwitchingSite } from './components/pages/DefaultLayout';
import Forgot from './components/pages/Forgot';
import GuidePage from './components/pages/GuidePage';
import MobileLanding from './components/pages/MobileLanding';
import MobileRegister from './components/pages/MobileRegister';
import MobileSetup from './components/pages/MobileSetup';
import PopupList from './components/pages/PopupList';
import PopupPage from './components/pages/PopupPage';
import Recover from './components/pages/Recover';
import SettingsPage from './components/pages/SettingsPage';
import SetupPage from './components/pages/SetupPage';
import SignIn from './components/pages/SignIn';
import SignInApp from './components/pages/SignInApp';
import SignUp from './components/pages/SignUp';
import './i18n';
import { verify } from './logic/api';
import { checkForValidOrgSession, checkForValidSession } from './logic/util';
import { onAppLoaded } from './state/store';

function App() {

  useEffect(() => {
    if (!isMobile)
      onAppLoaded()
  }, []);

  if (isMobile) {

    return (
      <Suspense fallback="">
        <div className="App" style={{ display: "flex", width: "100vw" }}>
          <Router>
            <Switch>
              <Route exact path="/authorize" component={SignInApp} />
              <Route exact path="/sign-in" component={MobileLanding} />
              <Route exact path="/register" component={MobileRegister} />
              <Route exact path="/confirm" component={ConfirmEmailMobile} />
              <Route exact path="/download-app" component={MobileLanding} />
              <PrivateRoute exact path="/setup" component={MobileSetup} />
              <RedirectToExistingPageMobile />
            </Switch>
            <ToastManager />
            <ModalManager />
          </Router>
        </div>
      </Suspense>
    );
  }

  return (
    <Suspense fallback="">
      <div className="App" style={{ display: "flex", width: "100vw" }}>
        <Router>
          <Switch>
            <PublicRoute routeToChat exact path="/sign-in" component={SignIn} />
            <PublicRoute routeToChat exact path="/register" component={SignUp} />
            <Route exact path="/confirm" component={ConfirmEmail} />
            <Route exact path="/recover" component={Recover} />
            <Route exact path="/forgot" component={Forgot} />
            <PrivateRoute needsOrg exact path="/chats" component={ChatPage} />
            <PrivateRoute needsOrg path="/popups/new" component={PopupPage} />
            <PrivateRoute needsOrg path="/popups/edit" component={PopupPage} />
            <PrivateRoute needsOrg path="/popups" component={PopupList} />
            <PrivateRoute needsOrg path="/settings" component={SettingsPage} />
            <PrivateRoute needsOrg path="/tutorials" component={GuidePage} />
            <PrivateRoute needsOrg path="/analytics" component={AnalyticsPage} />
            <PrivateRoute path="/setup" component={SetupPage} />
            <RedirectToExistingPage />
          </Switch>
          <ToastManager />
          <ModalManager />
          <TourManager />
        </Router>
      </div>
    </Suspense>
  );
}

export default (App);


const PublicRoute = ({ routeToChat, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {

      let loginHint = new URLSearchParams(props.location.search).get("login_hint")
      if (routeToChat && !loginHint && checkForValidSession(props))
        return <Redirect to={{ pathname: '/chats', state: { from: props.location } }} />

      return <Component {...props} />
    }} />
  )
}

const PrivateRoute = ({ needsOrg, component: Component, ...rest }) => {
  const isLoadingSite = useSelector(state => state.user?.changingSite)
  return (
    <Route {...rest} render={props => {
      if (!checkForValidSession(props))
        return <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />

      if (needsOrg && !checkForValidOrgSession())
        return <Redirect to={{ pathname: '/setup', state: { from: props.location } }} />

      if (isLoadingSite)
        return <SwitchingSite />

      return <Component {...props} />
    }
    } />
  )
}
const RedirectToExistingPage = () => (
  <Route render={props => {
    return checkForValidSession(props)
      ? <Redirect to={{ pathname: '/chats', state: { from: props.location } }} />
      : <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
  }} />
)

const RedirectToExistingPageMobile = () => (
  <Route render={props => {
    if (checkForValidSession(props) && !checkForValidOrgSession())
      return <Redirect to={{ pathname: '/setup', state: { from: props.location } }} />

    return <Redirect to={{ pathname: '/download-app', state: { from: props.location } }} />
  }} />
)

function ConfirmEmailMobile(props) {
  let history = useHistory();
  let code = new URLSearchParams(props.location.search).get("code")

  useEffect(() => {
    verify(code).then(() => {
      if (!checkForValidOrgSession())
        history.push("/setup");
      else
        history.push("/download-app");
    }).catch(() => {
      history.push("/download-app")
    })
  });

  return null;
}

function ConfirmEmail(props) {
  let history = useHistory();
  let code = new URLSearchParams(props.location.search).get("code")

  useEffect(() => {
    verify(code).then(() => {
      onAppLoaded()
      history.push("/chats");
    }).catch(() => {
      history.push("/sign-in")
    })
  });

  return null;
}