import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { addInfoToast } from '../ToastManager';
import './FieldWithCopy.scss';

export default function FieldWithCopy({ className, value }) {

    const copyToClipboard = () => {
        copy(value);
        addInfoToast("Copied to clipboard")
    }

    return (
        <div className={cn("field-with-copy", className)} onClick={copyToClipboard}>
            <div className="field-with-copy__field">
                {value}
            </div>
            <div>
                <i className="ml-1 far  fa-copy"></i>
            </div>
        </div>
    )
}