import classNames from 'classnames';
import { useState } from 'react';
import { ChromePicker, GithubPicker } from 'react-color';
import './ColorPicker.scss';


export default function ColorPicker(params) {
    const [displayPicker, setDisplayPicker] = useState(false)
    const pickedColor = params.value ? params.value : "#27304E"

    const handleClick = () => {
        setDisplayPicker(!displayPicker)
    };

    const handleClose = () => {
        setDisplayPicker(false)
    };

    let type = params.type ? params.type : "chrome"
    let ghLen = params.colors?.length < 7 ? ((params.colors?.length * 25) + 12) : 188

    return (
        <div className={classNames(params.className, "color-wrap", { "small-wrap": params.isSmall, "align-right": params.alignRight })} onKeyDown={e => { if (e.keyCode === 13) e.preventDefault() }}>
            <div className="swatch" onClick={handleClick}>
                <div className="swatch-color" style={{ background: pickedColor }} />
            </div>
            {displayPicker ? <div className="color-popover">
                <div className="popover-cover" onClick={handleClose} />
                {type === "chrome" && <ChromePicker disableAlpha color={pickedColor} onChange={(color) => { params.onChange(color.hex) }} />}
                {type === "block" && <GithubPicker triangle="hide" width={ghLen} colors={params.colors} color={pickedColor} onChange={(color) => { params.onChange(color.hex) }} />}
            </div> : null}
        </div>
    )
}