import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { subscriptionPrices } from '../../../logic/api';
import { toFormatedDateString } from '../../../logic/util';
import { DefaultSettingsWrap } from './Base';


export default function Subscription(params) {
    const siteDetails = useSelector(state => state.user.siteDetails)
    const { t } = useTranslation("settings_subscription")
    const [isLoading, setLoading] = useState(true)
    const [hasError, setError] = useState(false)
    const [prices, setPrices] = useState({})
    const [activePeriod, setActivePeriod] = useState("yearly")

    useEffect(() => {
        subscriptionPrices().then(prices => {
            setLoading(false)
            setPrices(prices)
        }).catch((e) => {
            setError(true)
        })
    }, [])


    var activePlan = (ws) => {
        if (ws.currentPackage !== "")
            return ws.currentPackage

        return ""
    }

    var workspaceSubscriptionStatus = (subscriptionPackage, ws) => {
        if (ws.currentPackage === subscriptionPackage && ws.isCanceled === true)
            return "cancelled"

        if (ws.currentPackage === subscriptionPackage && (ws.subscriptionStatus === "active" || ws.subscriptionStatus === "trialing"))
            return "active"

        return ""
    }

    var anyWorkspaceSubscriptionStatus = (ws) => {
        if (ws.currentPackage && ws.isCanceled === true)
            return "cancelled"

        // past_due, incomplete_expired
        if (ws.currentPackage && (ws.subscriptionStatus === "active" || ws.subscriptionStatus === "trialing"))
            return "active"

        return ""
    }

    const currentPackage = activePlan(siteDetails)
    const currentPackageStatus = anyWorkspaceSubscriptionStatus(siteDetails)
    const pricesData = {
        SOLO_PACK: {
            yearly: {
                amount: ((prices.yearlySoloPrice) / 100 / 12).toFixed(2),
                amountFull: ((prices.yearlySoloPrice) / 100).toFixed(2),
                id: prices.yearlySoloPriceID,
            },
            monthly: {
                amount: ((prices.monthlySoloPrice) / 100).toFixed(2),
                id: prices.monthlySoloPriceID,
            },
        },
        TEAM_PACK: {
            yearly: {
                amount: ((prices.yearlyTeamPrice) / 100 / 12).toFixed(2),
                amountFull: ((prices.yearlyTeamPrice) / 100).toFixed(2),
                id: prices.yearlyTeamPriceID,
            },
            monthly: {
                amount: ((prices.monthlyTeamPrice) / 100).toFixed(2),
                id: prices.monthlyTeamPriceID,
            },
        },
        ENT_PACK: {
            yearly: {
                amount: ((prices.yearlyEntPrice) / 100 / 12).toFixed(2),
                amountFull: ((prices.yearlyEntPrice) / 100).toFixed(2),
                id: prices.yearlyEntPriceID,
            },
            monthly: {
                amount: ((prices.monthlyEntPrice) / 100).toFixed(2),
                id: prices.monthlyEntPriceID,
            },
        }
    }

    if (hasError) {
        return (
            <DefaultSettingsWrap subPageTitle={t("Billing", { ns: 'settings_nav' })}>
                <div className="tab-error">
                    <div>{t("Something went wrong", { ns: 'common' })}</div>
                    <div>{t("Reload")}</div>
                </div>
            </DefaultSettingsWrap>
        )
    }

    if (isLoading) {
        return (
            <DefaultSettingsWrap subPageTitle={t("Billing", { ns: 'settings_nav' })}>
                <div className="tab-loader">{t("Loading...")}</div>
            </DefaultSettingsWrap>)
    }

    let manageBilling = siteDetails?.stripeUserID ? <Button variant="primary" href="/api/subscriptions/portal" className="float-right">
        {t("Manage billing")}
    </Button> : null

    return (
        <DefaultSettingsWrap subPageTitle={t("Billing", { ns: 'settings_nav' })} buttons={manageBilling}>
            <div className="plan-period-choice-wrap row mx-0">
                <PlanPeriodChoice title={t("Pay monthly", { ns: 'modal_sub' })} selected={activePeriod === "monthly"} onClick={() => { setActivePeriod("monthly") }} />
                <PlanPeriodChoice title={t("Pay yearly", { ns: 'modal_sub' })} selected={activePeriod === "yearly"} onClick={() => { setActivePeriod("yearly") }} />
            </div>
            <div className="subsciption-tab pt-5">
                <div className="d-flex subscriptions mb-5 mt-2">
                    <SubOption
                        name={t("Starter")}
                        packageName="SOLO_PACK"
                        specialDealText={t("-50% yearly")}
                        activePeriod={activePeriod}
                        prices={pricesData["SOLO_PACK"]}
                        priceID={pricesData["SOLO_PACK"][activePeriod].id}
                        siteDetails={siteDetails}
                        currentPackage={currentPackage}
                        currentPackageStatus={currentPackageStatus}
                        workspaceSubscriptionStatus={workspaceSubscriptionStatus}
                        active={false}
                        subInfo={(
                            <div>
                                <div className="my-2 text-center font-weight-bold">{t('1-2 team members')}</div>
                                <div className='sub-info  mx-3 mb-2'>
                                    {t('Up to 300 customer chats/monthly')}
                                </div>

                            </div>
                        )}
                    />
                    <SubOption
                        name={t("Business")}
                        packageName="TEAM_PACK"
                        specialDealText={t("-50% yearly")}
                        activePeriod={activePeriod}
                        prices={pricesData["TEAM_PACK"]}
                        priceID={pricesData["TEAM_PACK"][activePeriod].id}
                        siteDetails={siteDetails}
                        currentPackage={currentPackage}
                        currentPackageStatus={currentPackageStatus}
                        workspaceSubscriptionStatus={workspaceSubscriptionStatus}
                        active={false}
                        subInfo={(
                            <div>
                                <div className="my-2 text-center font-weight-bold">{t('3-5 team members')}</div>
                                <div className='sub-info mx-3 mb-2'>
                                    {t('Up to 1000 customer chats/monthly')}
                                </div>

                            </div>
                        )}
                    />
                    <SubOption
                        name={t("Enterprise")}
                        packageName="ENT_PACK"
                        specialDealText={t("-50% yearly")}
                        activePeriod={activePeriod}
                        prices={pricesData["ENT_PACK"]}
                        priceID={pricesData["ENT_PACK"][activePeriod].id}
                        siteDetails={siteDetails}
                        currentPackage={currentPackage}
                        currentPackageStatus={currentPackageStatus}
                        workspaceSubscriptionStatus={workspaceSubscriptionStatus}
                        active={false}
                        subInfo={(
                            <div>
                                <div className="my-2 text-center font-weight-bold">{t('6-10 team members')}</div>
                                <div className='sub-info mx-3 mb-2'>
                                    {t('Every additional agent +39 eur/month')}
                                    <br></br>
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div>
                    {t("Join these brands who care about their clients")}
                </div>
                <div className='brands-wrap' >
                    <div className='brand-img'>
                        <img src='/brands/home4you.jpg' alt='Home 4 You'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/ballzy.png' alt='Ballzy'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/on24.png' alt='On24'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/bestwine.png' alt='Bestwine'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/charlot.png' alt='Charlot'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/sportland.png' alt='Sportland'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/fruit-xpress.png' alt='Fruit-Xpress'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/onoff.png' alt='OnOff'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/aatrium.png' alt='Aatrium'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/marmara.png' alt='Marmara'></img>
                    </div>
                    <div className='brand-img'>
                        <img src='/brands/hookusbookus.png' alt='Hookusbookus'></img>
                    </div>
                </div>
            </div>
        </DefaultSettingsWrap >
    )
}

function SubOption({
    name,
    packageName,
    specialDealText,
    activePeriod,
    prices,
    active,
    priceID,
    siteDetails,
    subInfo,
    currentPackage,
    currentPackageStatus,
    workspaceSubscriptionStatus,
}) {
    const { t } = useTranslation("settings_subscription")

    function isLowerPack(packageName1, packageName2) {
        if (packageName1 === "TEAM_PACK" && packageName2 === "SOLO_PACK")
            return true

        if (packageName1 === "ENT_PACK" && (packageName2 === "SOLO_PACK" || packageName2 === "TEAM_PACK"))
            return true

        return false
    }

    function buttonContent(params) {
        if (!siteDetails.currentPackage)
            return (<a className="btn btn-primary " href={("/api/subscriptions/checkout?priceID=" + priceID)} >{t('Continue')}</a>)

        if (workspaceSubscriptionStatus(packageName, siteDetails) === "active")
            return (<>
                <p className=" text-secondary">{t('Next billing {{date}}', { date: toFormatedDateString(siteDetails.subscriptionPeriodEnd, true) })}</p>
                <a className="btn btn-primary " href="/api/subscriptions/portal" >{t('Cancel')}</a>
            </>)

        if (workspaceSubscriptionStatus(packageName, siteDetails) === "cancelled")
            return (<>
                <p className=" text-secondary">{t('Active until {{date}}', { date: toFormatedDateString(siteDetails.subscriptionPeriodEnd, true) })}</p>
                <a className="btn btn-primary " href="/api/subscriptions/portal" >{t('Resubscribe')}</a>
            </>)

        if (currentPackage && currentPackageStatus && isLowerPack(packageName, currentPackage))
            return (<>
                <div className=" text-secondary"></div>
                <a className="mb-1 btn btn-primary " href="/api/subscriptions/portal" >{t("Upgrade")}</a>
            </>)


        if (currentPackage && currentPackageStatus && isLowerPack(currentPackage, packageName))
            return (<>
                <div className=" text-secondary"></div>
                <a className="mb-1 btn btn-primary " href="/api/subscriptions/portal" >{t("Downgrade")}</a>
            </>)

        return (<>
            <div className=" text-secondary"></div>
            <a className="mb-1 btn btn-primary " href="/api/subscriptions/portal" >{t("Manage billing")}</a>
        </>)
    }

    const monthlyPrice = prices["monthly"]
    const yearlyPrice = prices["yearly"]

    return (
        <div className={cn("package-wrap col mx-4 d-flex flex-column", { active })}>
            {specialDealText && <div className="limited-offer">
                <div className="offer-percentage">{specialDealText}</div>
            </div>}
            <h3>{name}</h3>
            <div className={cn("price-wrap", { inactive: activePeriod !== 'monthly' })}>
                <div className='package-type'>{t("Monthly package")}</div>
                <div>
                    <Trans t={t}
                        defaults="<price>{{price}} €</price>/Month"
                        values={{ price: monthlyPrice.amount }}
                        components={{ price: <span className='price'></span> }} />
                </div>
            </div>
            <div className={cn("price-wrap", { inactive: activePeriod !== 'yearly' })}>
                <div className='package-type'>{t("Yearly package")}</div>
                <div>
                    <Trans t={t}
                        defaults="<price>{{price}} €</price>/Month"
                        values={{ price: yearlyPrice.amount }}
                        components={{ price: <span className='price'></span> }} />
                </div>
            </div>
            <div className='vat-warning'>{t("*VAT not included")}</div>
            {buttonContent()}
            {subInfo}
        </div>
    )
}

function PlanPeriodChoice(params) {
    return (
        <div className={params.selected ? "plan-period-choice selected" : "plan-period-choice"} onClick={params.onClick}>
            <div className="d-flex flex-column mx-2">
                <span>{params.title}</span>
            </div>
        </div>
    )
}