import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { openTour } from '../base/TourManager'
import './Modal.scss'
import './WelcomeModal.scss'

function WelcomeModal(props) {
    let data = props.data || {}
    return (
        <Modal className="tw-modal welcome-modal" centered show={props.show} onHide={props.onClose} animation={false}>
            {data.modalIcon &&
                <div className="modal-icon">
                    {data.modalIcon}
                </div>}
            <h2>Hi, great to have you onboard.</h2>
            <p className="footnote">Explore how to provide 💯 superb customer support</p>
            <div className="d-flex mt-4">
                <Button className="mx-auto mt-2 plain-btn" onClick={props.onClose}>Later</Button>
                <Button
                    className="mx-auto mt-2"
                    onClick={() => {
                        openTour();
                        props.onClose()
                    }}>START</Button>
            </div >
        </Modal >
    );
}

export default WelcomeModal;