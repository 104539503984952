import { addReconnectSuccessToast, addReconnectingToast } from '../components/base/ToastManager';
import LockedModal from '../components/modals/LockedModal';
import { addModal, clearModals } from '../components/modals/ModalManager';
import { wsEvent } from '../state/wsEvent';
import { asQueryString } from './api';
import { genUUID, getWSSessionDomain } from './util';

let instance;

class WSEventManager {
    constructor() {
        if (instance) {
            throw new Error("New instance cannot be created");
        }

        instance = this;

        this.isInited = false;
        this.isReconnecting = false;
        this.backoffLevel = 0;
        this.eventsManager = listenerManager();
        this.promiseCbs = {};
        this.unPushedActions = [];
        this.siteID = null;
        this.topics = [];

        this.socket = null;

        // If we do not have access to internet
        if (!navigator.onLine) {
            addModal(<LockedModal />, {
                locked: true,
                data: {
                    title: "Connection lost",
                    body: "We have lost connection to the server. Please refresh the page",
                    modalIcon: <i className="fad fa-wifi-slash"></i>,
                },
            })
        }

        window.addEventListener("offline", (() => {
            addModal(<LockedModal />, {
                locked: true,
                data: {
                    title: "Connection lost",
                    body: "We have lost connection to the server. Please refresh the page",
                    modalIcon: <i className="fad fa-wifi-slash"></i>,
                },
            })
        }));

        window.addEventListener("online", () => {
            clearModals()

            if (this.isInited && !this.isReconnecting) {
                this._reconnect();
            }
        });

        window.addEventListener("focus", this._connect.bind(this));
        window.onbeforeunload = function () {
            if (this.socket) {
                this.socket.onclose = function () { }; // Remove onclose
                this.socket.close(1000)
            }
        };
    }

    init(dispatch, siteID) {
        this.dispatch = dispatch;
        this.isInited = true;
        this.siteID = siteID

        this._connect();
    }

    restart() {
        if (this.socket) {
            this.socket.onclose = function () { }; // Remove onclose
            this.socket.close(3001)
        }

        this._connect();
    }

    addListener(name, cb) {
        var cbs = this.events[name];
        if (!cbs) {
            cbs = this.events[name] = [];
        }

        cbs.push(cb);
    }

    removeListener(name, cb) {
        if (this.events[name]) {
            let arr = this.events[name]
            for (let index = 0; index < arr.length; index++) {
                if (cb === arr[index]) {
                    arr.splice(index, 1);
                    return
                }
            }
        }
    }

    _emit(name, evt) {
        if (this.events[name]) {
            this.events[name]?.forEach(cb => {
                try {
                    if (cb)
                        cb(evt)
                } catch (error) { }
            });
        }
    }

    _reconnect() {
        this.isReconnecting = true
        addReconnectingToast()
        setTimeout(this._connect.bind(this), 2000 + Math.floor(Math.random() * 1000));
    }

    _connect() {
        if (!this.isInited) {
            return
        }

        if (this.socket) {
            switch (this.socket.readyState) {
                case WebSocket.OPEN:
                    if (this.isReconnecting) {
                        this.isReconnecting = false
                        addReconnectSuccessToast();
                    }
                    return;
                case WebSocket.CONNECTING:
                case WebSocket.CLOSING:
                    // Reconnect once the connection has been fully
                    this._reconnect();
                    return;
                default:
                // do nothing
            }
        }

        try {
            let queryParams = {}

            if (this.siteID)
                queryParams["site_id"] = this.siteID

            //if (chatData?.lastcheck ||  )
            //    queryParams.lastcheck = chatData?.lastcheck

            this.socket = new WebSocket(this._buildURL(queryParams));
            this.socket.onclose = this._socketonclose.bind(this)
            this.socket.onopen = this._socketonopen.bind(this)
            this.socket.onmessage = this._socketonmessage.bind(this)
        } catch (error) {
            this._reconnect();
        }
    }

    _buildURL(extraQueryparams) {
        return getWSSessionDomain() + "/api/ws/session?" + asQueryString(extraQueryparams);
    }

    _socketonclose(e) {
        //TODO
        this.dispatch({ type: "WS:CON", payload: false })

        this._reconnect();
    }

    _socketonopen() {
        if (this.isReconnecting) {
            addReconnectSuccessToast();
        }

        this.isReconnecting = false
        this.dispatch({ type: "WS:CON", payload: true })
    }

    _socketonmessage(socketEvent) {
        try {
            let event = JSON.parse(socketEvent.data);
            if (!event) return; // ignore empty msg or msg reset

            // If we have callback waiting for that event we execute the promise
            if (event.eventID && this.promiseCbs[event.eventID]) {
                if (event.status === "ERROR") {
                    this.promiseCbs[event.eventID].reject(event)
                } else {
                    this.promiseCbs[event.eventID].resolve(event)
                }

                delete this.promiseCbs[event.eventID]
            } else if (event.eventType) {
                this.dispatch(wsEvent(event))
            }
        } catch (e) {
            console.error(e)
        }
    }

    switchSiteID(siteID) {
        return this.sendEventWithCB({
            eventType: "WSB:SWITCH_SITE",
            wsbSiteID: siteID,
        })
    }

    sendEventWithCB(wsMsg) {
        wsMsg.eventID = genUUID()

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.unPushedActions.push(wsMsg)
        } else {
            this.socket.send(JSON.stringify(wsMsg));
        }

        return new Promise((function (resolve, reject) {
            this.promiseCbs[wsMsg.eventID] = { resolve, reject }
        }).bind(this));
    }


    sendEvent(event, raw) {
        let wsMsg = raw || { eventID: genUUID(), ...event }

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            this.unPushedActions.push(wsMsg)
            return
        }

        this.socket.send(JSON.stringify(wsMsg));
    }
}

let wsEventManager = new WSEventManager();
export default wsEventManager;


function listenerManager(params) {
    let events = {}

    function addListener(name, cb) {
        var cbs = events[name];
        if (!cbs) {
            cbs = events[name] = [];
        }

        cbs.push(cb);
    }

    function removeListener(name, cb) {
        if (events[name]) {
            let arr = events[name]
            for (let index = 0; index < arr.length; index++) {
                if (cb === arr[index]) {
                    arr.splice(index, 1);
                    return
                }
            }
        }
    }

    function emit(name, evt) {
        if (events[name]) {
            events[name]?.forEach(cb => {
                try {
                    if (cb)
                        cb(evt)
                } catch (error) { }
            });
        }
    }

    return {
        addListener,
        removeListener,
        emit,
    }
}

