import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ThinButton } from "./minor/Common";

export default function NotesArea({ onChange, note, submitNotes, isHidden }) {
    const ref = useRef(null);
    useEffect(() => {
        if (!isHidden) {
            ref.current?.focus()
        }
    }, [isHidden])

    const textAreaKeypress = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            submitNotes();
        }
    }

    if (isHidden) {
        return null
    }

    return (
        <div className='notes-wrap'>
            <textarea ref={ref} value={note} onChange={onChange} onKeyPress={textAreaKeypress} placeholder='Private notes are visible to you and your team.' spellCheck="true" type="text" />
        </div>
    )
}

export const NotesButtons = ({ backClick, submitClick }) => {
    const { t } = useTranslation('chat')

    return <>
        <ThinButton type="dark" className="" onClick={backClick}>
            {t("Back", { ns: 'common' })}
            <i className="far fa-arrow-left" ></i>
        </ThinButton>,
        <ThinButton type="primary" className="ml-auto position-relative" onClick={submitClick}>
            <span className='send-text-wrap'>
                {t("Send")}
                <i className="fas fa-paper-plane" ></i>
            </span>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </ThinButton>
    </>
}