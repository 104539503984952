import React from 'react'
import { Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import './GuideModal.scss'
import './Modal.scss'

const guides = {
    "shoproller": ShopRollerGuide,
    "shopify": ShopifyGuide,
    "wordpress": WordpressGuide,
    "woocommerce": WooCommerceGuide,
    "magento": MagentoGuide,
    "wix": WixGuide,
    "voog": VoogGuide,
}

function GuideModal(props) {
    const siteDetails = useSelector(state => state.user.siteDetails)
    let siteScript = '<script src="https://chat.askly.me/cw/chat/latest.js"\n\t tw-client-key="' + siteDetails?.siteKey + '" async ></script>'
    let ModalofGuide = guides[props.type]
    return (
        <Modal size="lg" className="tw-modal guide-modal" centered show={props.show} onHide={props.onClose} animation={false}>
            <div className='close-modal' onClick={props.onClose}>
                <i className="far fa-times"></i>
            </div>
            <ModalofGuide siteScript={siteScript} />
        </Modal >
    );
}

export default GuideModal;

function ShopRollerGuide({ siteScript }) {
    const { t } = useTranslation("modal_guides")
    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/shoproller_logo.png" alt="Shoproller" ></img>
            <h2>{t("Lisa Askly Chat Shoproller'i poodi")}</h2>
            <Trans t={t}
                defaults='<p>1. Ava Shoprolleri halduskeskkond</p><p>2. Vasak menüüst otsi<code>Kujundus</code></p><p>3. Kujunduse kastis kliki <code>Päis</code> peale</p><p>4. Kleebi sinna see kood ja salvesta</p>'
                components={{ code: <code></code> }}
            />
            <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
            <figure>
                <img className="guide-step-img" src="/images/guides/shoproller_step_1.png" alt="Shoproller step 1" ></img>
                <figcaption>{t("Koodi lisamise aken")}</figcaption>
            </figure>
        </div >
    )
}

function ShopifyGuide({ siteScript }) {
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/shopify_logo.svg" alt="Shopify" ></img>
            <h2>{t("Add Askly Chat to your Shopify page")}</h2>
            <Trans t={t}
                defaults='<p>1. Click on customize theme</p><p>2. Click on actions and click on <codeformat>edit code</codeformat></p><p>3. From sections look for header.liquid file</p><p>4. Add to script for first row to the file</p><p>5. Click on <codeformat>Preview</codeformat> and chat should be visible</p><p>6. Save and publish</p>'
                components={{ codeformat: <code></code> }}
            />
            <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
            <figure>
                <img className="guide-step-img" src="/images/guides/shopify_step_1.png" alt="Shopify step 1" ></img>
                <figcaption>{t("Customise theme button")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/shopify_step_2.png" alt="Shopify step 2" ></img>
                <figcaption>{t("Edit code button")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/shopify_step_3.png" alt="Shopify step 3" ></img>
                <figcaption>{t("Adding script to header.liquid file")}</figcaption>
            </figure>
        </div>
    )
}

function WordpressGuide(params) {
    const siteDetails = useSelector(state => state.user.siteDetails)
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/wordpress_logo.svg" alt="Wordpress" ></img>
            <h2>{t("Add Askly Chat to your Wordpress page")}</h2>
            <Trans t={t}
                defaults='<p>1. Open Wordpress dashboard</p><p>2. Choose Plugins on the left menu and <code>Add</code> new</p><p>3. In the search bar in the top right corner enter: <code>askly</code></p><p>4. Open <code>Settings</code> and click on <code>Askly chat</code></p><p>5. Paste this key <code>{{sitekey}}</code> to press save</p>'
                components={{ sitekey: siteDetails?.siteKey, code: <code></code> }}
            />
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_1.png" alt="Wordpress step 1" ></img>
                <figcaption>{t("'Askly Chat' plugin in wordpress plugin store")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_2.png" alt="Wordpress step 2" ></img>
                <figcaption>{t("'Askly Chat' activation after installation in wordpress plugin store")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_3.png" alt="Wordpress step 3" ></img>
                <figcaption>{t("Adding client key")}</figcaption>
            </figure>
        </div>
    )
}

function WooCommerceGuide(params) {
    const siteDetails = useSelector(state => state.user.siteDetails)
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/wordpress_logo.svg" alt="Wordpress" ></img>
            <h2>{t("Add Askly Chat to your Wordpress page")}</h2>
            <Trans t={t}
                defaults='<p>1. Open Wordpress dashboard</p><p>2. Choose Plugins on the left menu and <code>Add</code> new</p><p>3. In the search bar in the top right corner enter: <code>askly</code></p><p>4. Open <code>Settings</code> and click on <code>Askly chat</code></p><p>5. Paste this key <code>{{sitekey}}</code> to press save</p>'
                components={{ sitekey: siteDetails?.siteKey, code: <code></code> }} />
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_1.png" alt="Wordpress step 1" ></img>
                <figcaption>{t("'Askly Chat' plugin in wordpress plugin store")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_2.png" alt="Wordpress step 2" ></img>
                <figcaption>{t("'Askly Chat' activation after installation in wordpress plugin store")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wordpress_step_3.png" alt="Wordpress step 3" ></img>
                <figcaption>{t("Adding client key")}</figcaption>
            </figure>
        </div>
    )
}

function WixGuide({ siteScript }) {
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/wix_logo.svg" alt="Wix" ></img>
            <h2>{t("Add Askly Chat to your WIX page")}</h2>
            <Trans t={t}
                defaults='<p>1. Open WIX Dashboard and click on <code>Settings</code></p><p>2. Click on <code>Custom code</code> in Advanced section</p><p>3. Click on <code>Add custom code</code> and paste the script</p><p>4. Make sure you add code to all pages and Place Code in <code>Head</code></p><p>3. Click on Apply</p>'
                components={{ code: <code></code> }}
            />
            <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
            <figure>
                <img className="guide-step-img" src="/images/guides/wix_step_1.png" alt="Wix step 1" ></img>
                <figcaption>{t("Navigate to Wix settings")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wix_step_2.png" alt="Wix step 2" ></img>
                <figcaption>{t("Open custom code editor")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/wix_step_3.png" alt="Wix step 3" ></img>
                <figcaption>{t("Adding client key")}</figcaption>
            </figure>
        </div>
    )
}

function MagentoGuide({ siteScript }) {
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/magento2_logo.png" alt="Magento 2" ></img>
            <h2>{t("Add Askly Chat to your Magento 2 page")}</h2>
            <Trans t={t}
                defaults='<p>1. Go to Magento dashboard</p><p>2. Navigate to <code>Admin &gt; Content &gt; Configuration</code></p><p>3. Choose the store view you want the head tag to be changed on or select Global in order to change it on every store view.</p><p>4. Find the HTML Head section and add your code in the Scripts and Style Sheets field.</p>'
                components={{ code: <code></code> }} />
            <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
            <figure>
                <img className="guide-step-img" src="/images/guides/magento_step_1.png" alt="Magento step 1" ></img>
                <figcaption>{t("Navigate to content configuration")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/magento_step_2.png" alt="Magento step 2" ></img>
                <figcaption>{t("Store view")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/magento_step_3.png" alt="Magento step 3" ></img>
                <figcaption>{t("Add script")}</figcaption>
            </figure>
        </div>
    )
}

function VoogGuide({ siteScript }) {
    const { t } = useTranslation("modal_guides")

    return (
        <div className="mt-4 mb-2 mx-3">
            <img className="guide-logo" src="/images/guides/voog_logo.svg" alt="Voog" ></img>
            <h2>{t("Add Askly Chat to your Voog page")}</h2>
            <Trans t={t}
                defaults='<p>1. Go to your Voog dashboard</p><p>2. Navigate to <codeformat>General settings</codeformat></p><p>3. Find the <codeformat>Header code</codeformat> section and paste your script to that field.</p>'
                components={{ codeformat: <code></code> }} />
            <Form.Control rows="2" as="textarea" type="text" placeholder="" value={siteScript} disabled />
            <figure>
                <img className="guide-step-img" src="/images/guides/voog_step_1.png" alt="Voog step 1" ></img>
                <figcaption>{t("Open general settings")}</figcaption>
            </figure>
            <figure>
                <img className="guide-step-img" src="/images/guides/voog_step_2.png" alt="Voog step 2" ></img>
                <figcaption>{t("Add your script to Header code")}</figcaption>
            </figure>
        </div>
    )
}