import { format, formatDistanceToNow } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Letter } from 'react-letter';
import { useGetEmailMessageQuery } from '../../state/api';

import cn from 'classnames';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { humanFileSize } from '../../logic/util';
import MediaModal from '../modals/MediaModal';
import { addModal } from '../modals/ModalManager';
import './ChatEmailMessage.scss';

const ChatEmailMessage = forwardRef((params, forwardedRef) => {
    const fallbackRef = useRef(null)
    const ref = forwardedRef || fallbackRef

    const { t } = useTranslation('chat')
    const [messageOpen, setMessageOpen] = useState(params.defaultOpened || false)
    const { data: emailMessage, isLoading } = useGetEmailMessageQuery({ messageID: params?.data?.id }, { skip: !messageOpen })

    const openMessage = () => {
        if (!messageOpen) {
            setMessageOpen(true)
        }
    }

    function getUserNameByID(id) {
        if (id === 0)
            return t("Askly Assistant")

        return params?.users?.find(u => u.id === id)?.name || t("Unknown")
    }

    useEffect(() => {
        if (messageOpen && !isLoading) {
            var elementHeight = ref.current?.offsetHeight;
            var elementWidth = ref.current?.offsetWidth;
            var bounding = ref.current?.getBoundingClientRect();
            if (bounding && bounding.top >= -elementHeight
                && bounding.left >= -elementWidth
                && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + elementWidth
                && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + elementHeight) {
                // In the viewport!
            } else {
                ref?.current?.scrollIntoView()
            }
        }
    }, [messageOpen, isLoading])

    const findInlineURL = (url) => {
        if (url.startsWith("cid:")) {
            let attachment = params?.data?.attachments?.find(a => a.contentID?.replace(/^<|>$/g, '') === url.slice(4))

            if (attachment) {
                return "/api" + attachment.url
            }
        }

        return url
    }

    if (!messageOpen)
        return <EmailPreview {...params} openMessage={openMessage} />


    let from = params?.data?.mailMessage?.from
    let fromAddress = parseEmailAddressHeader(from)
    let subject = params?.data?.mailMessage?.subject
    let hasBody = !!emailMessage?.bodyHtml || !!emailMessage?.bodyPlain

    return (<div ref={ref} className={cn('chat-email-msg', { 'is-support-msg': params.data.isSupportResponse })}>
        <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
        <div className='chat-email-msg__header' onClick={() => { setMessageOpen(false) }}>
            <div className='header-left'>
                <div className='header-from'>
                    <span className='email-name mr-2'>{fromAddress.name}</span>
                    <span className='email-address'>{fromAddress.email}</span>
                    <div className='time-wrap d-flex'>
                        <div className='chat-email-msg__time'>{format(new Date(params.data?.createdAt), "MMM d kk:mm")}</div>
                        <div className='ml-1 chat-email-msg__time'>({formatDistanceToNow(new Date(params.data?.createdAt), { addSuffix: true })})</div>
                    </div>
                </div>
                <div className='header-content'>
                    <div className='chat-email-msg__subject'>{subject ? subject : "(No subject)"}</div>
                </div>
            </div>
        </div>
        <div className='chat-email-body'>
            {isLoading && <div className='loading-body-ph'>Loading...</div>}
            {!isLoading && emailMessage && hasBody && <Letter html={emailMessage?.bodyHtml} text={emailMessage?.bodyPlain} rewriteExternalResources={findInlineURL} />}
            {!isLoading && emailMessage && !hasBody && <div className='empty-body-ph'>{t("Empty message")}</div>}
        </div>
        {params?.data?.attachments?.length > 0 && <div className='body-attachments'>{params?.data?.attachments?.map(a => <Attachment key={a.url} {...a} />)}</div>}
    </div>)
})

export default ChatEmailMessage;

function EmailPreview(params) {
    const { t } = useTranslation('chat')
    let from = params?.data?.mailMessage?.from
    let fromAddress = parseEmailAddressHeader(from)
    let subject = params?.data?.mailMessage?.subject

    function getUserNameByID(id) {
        if (id === 0)
            return t("Askly Assistant")

        return params?.users?.find(u => u.id === id)?.name || t("Unknown")
    }

    return (
        <div className={cn('chat-email-msg msg-preview', { 'is-support-msg': params.data.isSupportResponse })} onClick={params.openMessage}>
            <span className='support-name'>{params.data.isSupportResponse === true && getUserNameByID(params.data.supportResponseBy)}</span>
            <div className='chat-email-msg__header'>
                <div className='header-left'>
                    <div className='header-from'>
                        <span className='email-name mr-2'>{fromAddress.name}</span>
                        <span className='email-address'>{fromAddress.email}</span>
                        <div className='time-wrap d-flex'>
                            <div className='chat-email-msg__time'>{format(new Date(params.data?.createdAt), "MMM d kk:mm")}</div>
                            <div className='ml-1 chat-email-msg__time'>({formatDistanceToNow(new Date(params.data?.createdAt), { addSuffix: true })})</div>
                        </div>
                    </div>
                    <div className='header-content'>
                        <div className='chat-email-msg__subject'>
                            <span>{subject ? subject : t("(No subject)")}</span>
                        </div>
                        <div className='chat-email-msg__body'>{params?.data?.mailMessage?.bodyPlain}</div>
                    </div>
                    {params?.data?.attachments?.length > 0 && <div className='header-attachments'>{params?.data?.attachments?.map(a => <Attachment key={a.url} {...a} />)}</div>}
                </div>
            </div>
        </div>)
}



function Attachment({ attachmentType, fileName, fileSize, mimeType, url }) {
    let isImage = mimeType?.includes("image")
    let attachmentProps = {
        href: "#",
    }
    if (isImage) {
        attachmentProps = {
            onClick: function () {
                addModal(<MediaModal />, {
                    data: {
                        mediaURL: ("/api" + url),
                        mimeType: mimeType,
                    }
                })
            }
        }
    } else {
        attachmentProps = {
            href: (("/api" + url)),
            download: true,
        }
    }

    return (<a className='email-attachment-wrap' {...attachmentProps}>
        <div className='email-attachment-icon'><i className={cn('fas', { 'fa-file': !isImage, 'fa-file-image': isImage })}></i></div>
        <div className='email-attachment-name'>{fileName}</div>
        <div className='email-attachment-size'>{fileSize ? humanFileSize(fileSize) : ""}</div>
    </a>)
}

function parseEmailAddressHeader(from) {
    var extract = { name: '', email: '' };
    if (!from) return extract;

    var emails = from.match(/[^@<\s]+@[^@\s>]+/g);

    if (emails) {
        extract.email = emails[0];
    }

    var names = from.split(/\s+/);

    if (names.length > 1) {
        names.pop();
        extract.name = names.join(' ').replace(/"/g, '');
    }

    return extract
}
