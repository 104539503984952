import { default as cn } from 'classnames';
import timezones from 'compact-timezone-list';
import i18next from "i18next";
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Collapsible from 'react-collapsible';
import { ChromePicker } from 'react-color';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import TimeField from 'react-simple-timefield';
import { getAllTranslations } from '../../../logic/api';
import { getBase64, getLanguage, getLanguages, getLanguagesIso, getLanguagesIsoToEng, getTimezone, isValidPhoneNumber, objectEquals } from '../../../logic/util';
import allLanguages from '../../../resources/languages.json';
import { fetchMyDetails, updatSiteSettings } from '../../../state/currentUser';
import ChatPreview from '../../base/ChatPreview';
import { addErrorToast, addInfoToast } from '../../base/ToastManager';
import BadgeDropdown from '../../base/minor/BadgeDropdown';
import { SeparatorDots } from '../../base/minor/Common';
import GlobalNavigationBlocker from '../../base/minor/GlobalNavigationBlocker';
import SearchDropdown from '../../base/minor/SearchDropdown';
import { InfoTooltip, WarningTooltip } from '../../base/minor/Tooltip';
import useScrollToLocation from '../../base/useScrollToLocation';
import ConfirmModal from '../../modals/ConfirmModal';
import { addModal } from '../../modals/ModalManager';
import { DefaultSettingsWrap } from './Base';
import './ChatWidget.scss';


export function ChatSettings({ afterSave, canSave }) {
    const dispatch = useDispatch()
    const { t } = useTranslation("settings_widget")
    const [activeTab, setActiveTab] = useState("chat")
    const siteDetails = useSelector(state => state.user.siteDetails)
    const [siteSettings, setSiteSettings] = useState({})
    const [faqLanguage, setFaqLanguage] = useState("en")
    const [faqList, setFaqList] = useState([])
    const [errorMsgs, setErroMsgs] = useState({})
    const [formRecipients, setFormRecipents] = useState([])
    const [tempSupImg, setTempSupImg] = useState(null)
    const prevDetails = useRef({});
    const optionMap = {
        "never": t("Never"),
        "away": t("When away"),
        "always": t("Always")
    }

    const optionMapType = {
        "email": t("Email"),
        "phone": t("Phone number"),
    }

    const defaultValues = {
        "bgPattern": "bg-1",
    }

    useEffect(() => {
        if (siteDetails !== prevDetails.current) {
            // Clone so we can compare
            let dcSettings = JSON.parse(JSON.stringify(siteDetails.siteSettingsWrap?.settings || {}));
            let dcRecipients = JSON.parse(JSON.stringify(siteDetails.siteSettingsWrap?.formRecipients || []));
            let dcFaqList = JSON.parse(JSON.stringify(siteDetails.siteSettingsWrap?.faqList || []));

            setSiteSettings(dcSettings);
            setFormRecipents(dcRecipients);
            setFaqList(dcFaqList);
            //setSiteName(siteDetails.siteSettingsWrap ? siteDetails.siteSettingsWrap.siteName : "")
            //setSiteDomain(siteDetails.siteSettingsWrap ? siteDetails.siteSettingsWrap.siteDomain : "")
        }

        prevDetails.current = siteDetails;
    }, [siteDetails]);

    function saveSiteSettings(e) {
        e.preventDefault()
        let errors = {};
        let validated = true;
        /*
        let correctedSiteDomain = siteDomain

        if (!siteName) {
                validated = false
            errors.siteName = "Site name required"
        }

            if (!siteDomain) {
                validated = false
            errors.siteDomain = "Website address required"
        } else {
            try {
                let fullURL = siteDomain
            if (!fullURL.startsWith("http://") && !fullURL.startsWith("https://")) {
                fullURL = "https://" + fullURL;
                }
            let domain = (new URL(fullURL));
            correctedSiteDomain = domain.hostname.replace('www.', '');
            setSiteDomain(correctedSiteDomain)
            } catch (error) {
                validated = false
                errors.siteDomain = "Valid domain required"
            }
        }
            */


        if (siteSettings?.callContacts?.whatsApp && !isValidPhoneNumber(siteSettings?.callContacts?.whatsApp)) {
            validated = false
            errors.callContactsWhatsApp = t("Invalid number")
            errors.callUsErrors = true
        }

        if (siteSettings?.callContacts?.viber && !isValidPhoneNumber(siteSettings?.callContacts?.viber)) {
            validated = false
            errors.callContactsViber = t("Invalid number")
            errors.callUsErrors = true
        }

        if (siteSettings?.callContacts?.skype?.length > 55) {
            validated = false
            errors.callContactsSkype = t("Invalid username")
            errors.callUsErrors = true
        }

        if (siteSettings?.callContacts?.phone && !isValidPhoneNumber(siteSettings?.callContacts?.phone)) {
            validated = false
            errors.callContactsPhone = t("Invalid number")
            errors.callUsErrors = true
        }

        if (siteSettings?.hasCallUs && (!siteSettings?.callContacts?.phone
            && !siteSettings?.callContacts?.skype
            && !siteSettings?.callContacts?.whatsApp
            && !siteSettings?.callContacts?.viber)) {
            validated = false
            errors.callContactsGeneral = t("Atleast one required")
            errors.callUsErrors = true
        }

        if (siteSettings?.hasFAQ && (!faqList || faqList.length === 0)) {
            validated = false
            errors.faqGeneral = t("Atleast one required")
            errors.faqErrors = true
        }

        const missingTranslations = faqList && faqList.some((faqElem) => {
            return Object.keys(faqElem.question || {})?.length <= 1
        })

        if (siteSettings?.hasFAQ && missingTranslations) {
            addModal(<ConfirmModal />, {
                data: {
                    title: t("Your FAQ has missing translations"),
                    body: t("Please check your FAQ section and apply translations for those still in progress."),
                    cancelText: t("Back", { ns: 'common' }),
                    hideConfirm: true,
                },
                onConfirm: () => { }
            })
            return
        }

        if (validated) {
            dispatch(updatSiteSettings(siteSettings, { formRecipients /*, siteName, siteDomain: correctedSiteDomain */, faqList }))
                .then(r => {
                    if (afterSave) {
                        afterSave()
                    } else {
                        addInfoToast(t("Website settings saved"))
                        return dispatch(fetchMyDetails())
                    }
                }).catch(e => {
                    addErrorToast(t("Could not save settings"))
                })

            setErroMsgs({})
        } else {
            setErroMsgs(errors)
            addErrorToast(t("Data validation failed"))
        }
    }

    function addLanguage(newLang) {
        if (siteSettings.lngs?.length && siteSettings.lngs?.length > 4) {
            return
        }
        let langFull = getLanguage(newLang, "eng")
        let currentLangs = siteSettings.lngs || [];
        setSiteSettings({ ...siteSettings, lngs: [...currentLangs.filter(lang => lang !== langFull.iso), langFull.iso] })
    }

    function removeLanguage(removedLanguage) {
        let langFull = getLanguage(removedLanguage, "eng")
        let currentLangs = siteSettings.lngs || []
        setSiteSettings({ ...siteSettings, lngs: [...currentLangs.filter(lang => lang !== langFull.iso)] })
    }

    const checkIfHasChanges = () => {
        return canSave &&
            (
                !objectEquals({ ...defaultValues, ...(siteDetails.siteSettingsWrap?.settings || {}) }, siteSettings) ||
                !objectEquals((siteDetails.siteSettingsWrap?.formRecipients || []), formRecipients) ||
                !objectEquals((siteDetails.siteSettingsWrap?.faqList || []), faqList)
            )
    }

    if (!siteSettings?.bgPattern)
        siteSettings.bgPattern = "bg-1"


    return (
        <DefaultSettingsWrap
            subPageTitle={t("Web chat design", { ns: 'settings_nav' })}
            canSave={canSave}
            optionClick={(val) => { setActiveTab(val) }}
            activeSubOption={activeTab}
            secondRow={[
                { name: <span><i className="fas fa-comments mr-2 my-auto"></i>{t("Chat")}</span>, value: "chat" },
                { name: <span><i className="fas fa-phone-alt mr-2 my-auto"></i>{t("Contact Us")}</span>, value: "contactus", hasError: errorMsgs.callUsErrors },
                { name: <span><i className="fas fa-question-circle mr-2 my-auto"></i>{t("FAQ")}</span>, value: "faq", hasError: errorMsgs.faqErrors },
            ]}
        >
            <div className="chat-settings-wrap">
                <GlobalNavigationBlocker check={checkIfHasChanges} message={t("Changes that you made may not be saved.")} />
                <Form id="current-settings-form" onSubmit={saveSiteSettings}>
                    {activeTab === "chat" && <ChatTab
                        optionMapType={optionMapType}
                        optionMap={optionMap}
                        removeLanguage={removeLanguage}
                        addLanguage={addLanguage}
                        setTempSupImg={setTempSupImg}
                        siteSettings={siteSettings}
                        setSiteSettings={setSiteSettings} />}
                    {activeTab === "contactform" && <ContactFormTab
                        siteSettings={siteSettings}
                        setSiteSettings={setSiteSettings}
                        errorMsgs={errorMsgs}
                        formRecipients={formRecipients}
                        setFormRecipents={setFormRecipents} />}
                    {activeTab === "contactus" && <CallUsTab
                        siteSettings={siteSettings}
                        setSiteSettings={setSiteSettings}
                        errorMsgs={errorMsgs} />}
                    {activeTab === "faq" && <FAQTab
                        siteSettings={siteSettings}
                        setSiteSettings={setSiteSettings}
                        errorMsgs={errorMsgs}
                        setFaqLanguage={setFaqLanguage}
                        setFaqList={setFaqList}
                        faqLanguage={faqLanguage}
                        faqList={faqList} />}
                </Form >
                <div className="preview-wrap d-none d-lg-flex justify-content-center">
                    <ChatPreview
                        className="cs-step-1"
                        themeColor={siteSettings.mainColor || "#27304E"}
                        logoURL={tempSupImg || (siteSettings.supImgURL ? siteSettings.supImgURL + "?" + (new Date().getTime()) : null)}
                        hideOrgMsg={siteSettings.hideOrgMsg}
                        pii_notification={siteSettings.pii_notification}
                        hasForm={siteSettings.hasForm}
                        hasFAQ={siteSettings.hasFAQ}
                        hasCallUs={siteSettings.hasCallUs}
                        openTimes={siteSettings.schedules}
                        bgPattern={siteSettings.bgPattern}
                    />
                </div>
            </div >
        </DefaultSettingsWrap>
    )
}

function FAQGoup({ faqs, onSwap, allowedSwapDir, remove, onChange, language }) {
    return (
        <div>
            {faqs.map((item, i) => {
                return <CollapsibleFAQ
                    key={i}
                    item={item}
                    remove={() => { remove(i) }}
                    sourceLang={language}
                    title={item.question?.[language] || ""}
                    onSwap={dir => onSwap(i, dir)}
                    isFirst={i === 0}
                    isLast={i + 1 === faqs.length}
                    setTitle={title => {
                        if (!item.question)
                            item.question = {
                                [language]: "",
                            }

                        item.question[language] = title
                        onChange(faqs)
                    }}
                    hasMissingTranslations={(Object.keys(item.question || {})?.length <= 1)}
                    answer={item.answer?.[language] || ""}
                    setAnswer={answer => {
                        if (!item.answer)
                            item.answer = {
                                [language]: "",
                            }

                        item.answer[language] = answer
                        onChange(faqs)

                    }}
                    setAll={(question, answer) => {
                        item.question = question
                        item.answer = answer
                        onChange(faqs)
                    }}
                />

            })}
        </div >
    )
}

export function TabInfo({ children, checked, onChange, noToggle, toggleName }) {
    const { t } = useTranslation("settings_widget")

    return (<>
        {!noToggle && <div style={{ display: 'flex', paddingBottom: 12, color: "#5d6c89", fontSize: 15, flexDirection: 'column', position: 'sticky', top: 0, zIndex: 2, background: 'linear-gradient(to bottom, #ffffff 0px, #ffffff 75%, #ffffff00 100%)' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 600, marginRight: 6 }}>{toggleName}</span>
                <Form.Check type="switch" className="" id="form-switch" checked={checked} label={t(checked ? "Active" : "Inactive")} onChange={onChange} />
            </div>
        </div>}
        <div>
            <div className={cn("d-flex mb-2", { 'mt-3': noToggle })} style={{ color: "#5d6c89", fontSize: 14, flexDirection: 'column' }}>
                {children}
            </div>
            <SeparatorDots className="full-width" />
        </div>
    </>)
}

function FAQTab({ siteSettings, setSiteSettings, errorMsgs, setFaqLanguage, setFaqList, faqLanguage, faqList }) {
    const { t } = useTranslation("settings_widget")

    return (
        <div className='faq-card'>
            <TabInfo checked={siteSettings.hasFAQ} toggleName={t("FAQ")} onChange={() => { setSiteSettings({ ...siteSettings, hasFAQ: !siteSettings.hasFAQ }) }}>
                <div className='d-flex flex-column'>
                    <Trans t={t}
                        defaults="Create a quick answers base for your customers.<br/>Add as many questions and answers as you need.<br/>You can use 'Translation' tool to automatically translate questions and answers from your current language to all languages.<br/><tourtip>Make sure you write your questions and answers in your chosen language to ensure accurate translations.</tourtip>"
                        components={{ tourtip: <i className="tour-tip"></i> }} />
                </div>
            </TabInfo>
            <div className="d-flex flex-column ml-auto mb-3">
                <div className="faq-lang-select-lable">{t("Choose the language of your FAQs:")} </div>
                <div className='d-flex flex-wrap'>
                    {allLanguages.map(l => <div
                        onClick={() => { if (l.iso !== faqLanguage) setFaqLanguage(l.iso) }}
                        className={cn("faq-selectable-lang", { "active": (faqLanguage === l.iso) })}
                        key={l.iso} >{l.nativeName}</div>)}
                </div>
            </div>
            <FAQGoup
                language={faqLanguage}
                faqs={faqList || []}
                onChange={(faqs) => { setFaqList([...(faqs || [])]) }}
                onSwap={(pos, leftDir) => {
                    let pos2 = pos + (leftDir ? - 1 : 1)
                    if (pos2 < 0 || pos2 >= faqList.length) {
                        return
                    }
                    const temp = faqList[pos];
                    faqList[pos] = faqList[pos2];
                    faqList[pos2] = temp;
                    setFaqList([...faqList])
                }}
                remove={i => {
                    faqList.splice(i, 1);
                    setFaqList([...faqList])
                }} />
            <div><Form.Label><span className="general-error ml-2">{errorMsgs.faqGeneral}</span></Form.Label></div>
            <PlusAdd className="ml-1" onClick={() => { setFaqList([...(faqList || []), {}]) }}>{t("Add new question")}</PlusAdd>
        </div>
    )
}

function CallUsTab({ siteSettings, setSiteSettings, errorMsgs }) {
    const { t } = useTranslation("settings_widget")
    return (
        <div>
            <TabInfo checked={siteSettings.hasCallUs} toggleName={t("Contact Us")} onChange={() => { setSiteSettings({ ...siteSettings, hasCallUs: !siteSettings.hasCallUs }) }}>
                {t("Add your contact number and make it easier for customers to reach you.")}
            </TabInfo>
            <Form.Group>
                <div><Form.Label><span className="general-error ml-2">{errorMsgs.callContactsGeneral}</span></Form.Label></div>
                <Form.Label><i className="fas fa-phone-alt mr-1"></i> {t("Phone number")} <span className="general-error ml-2">{errorMsgs.callContactsPhone}</span></Form.Label>
                <Form.Control type="text" placeholder={t("Enter number")}
                    onChange={e => { setSiteSettings({ ...siteSettings, callContacts: { ...siteSettings.callContacts, phone: e.target.value } }) }}
                    value={siteSettings?.callContacts?.phone || ""}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label><i className="fab fa-skype mr-1"></i> {t("Skype username")} <span className="general-error ml-2">{errorMsgs.callContactsSkype}</span></Form.Label>
                <Form.Control type="text" placeholder={t("Enter username")}
                    onChange={e => { setSiteSettings({ ...siteSettings, callContacts: { ...siteSettings.callContacts, skype: e.target.value } }) }}
                    value={siteSettings?.callContacts?.skype || ""}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label><i className="fab fa-whatsapp mr-1"></i> {t("WhatsApp number")} <span className="general-error ml-2">{errorMsgs.callContactsWhatsApp}</span></Form.Label>
                <Form.Control type="text" placeholder={t("Enter number")}
                    onChange={e => { setSiteSettings({ ...siteSettings, callContacts: { ...siteSettings.callContacts, whatsApp: e.target.value } }) }}
                    value={siteSettings?.callContacts?.whatsApp || ""}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label><i className="fab fa-viber mr-1"></i>  {t("Viber number")} <span className="general-error ml-2">{errorMsgs.callContactsViber}</span></Form.Label>
                <Form.Control type="text" placeholder={t("Enter number")}
                    onChange={e => { setSiteSettings({ ...siteSettings, callContacts: { ...siteSettings.callContacts, viber: e.target.value } }) }}
                    value={siteSettings?.callContacts?.viber || ""}
                />
            </Form.Group>
        </div>
    )
}

function ContactFormTab({ siteSettings, setSiteSettings, errorMsgs, formRecipients, setFormRecipents }) {
    const { t } = useTranslation("settings_widget")
    return <div>
        <TabInfo checked={siteSettings.hasForm} toggleName={t("Contact Form")} onChange={() => { setSiteSettings({ ...siteSettings, hasForm: !siteSettings.hasForm }) }}>
            {t("Form feature allows your customers to send emails to you directly from your website")}
        </TabInfo>
        <FormSettings missingError={errorMsgs.formRecipients} formRecipients={formRecipients} setFormRecipents={setFormRecipents} />
    </div>
}

function ChatTab({ optionMapType, optionMap, removeLanguage, addLanguage, setTempSupImg, siteSettings, setSiteSettings }) {
    const { t } = useTranslation("settings_widget")

    return (<div>
        <TabInfo noToggle>
            {t("Change chat styling to fit your brand. Add working hours so visitor know when to you are active.")}
        </TabInfo>
        <Form.Group className="bottom-separator cs-step-2">
            <h3>{t("Chat theme color")}</h3>
            <ColorPicker value={siteSettings.mainColor || "#27304E"} onChange={mainColor => { setSiteSettings({ ...siteSettings, mainColor }) }} />

            <h5>{t("Pattern")}</h5>
            <div className="patterns-wrap d-flex flex-row">
                <label htmlFor="pattern-bg-1" className="d-flex flex-column-reverse align-items-center">
                    <input id="pattern-bg-1" type="radio" value="bg-1" name="background-type"
                        checked={siteSettings?.bgPattern === "no-bg"}
                        onChange={e => { setSiteSettings({ ...siteSettings, bgPattern: "no-bg" }) }} />
                    <div className="pattern-select-wrap">
                        <div className="text-overlay">
                            <div className="m-auto">{t("Solid color")}</div>
                        </div>
                    </div>
                </label>
                <label htmlFor="pattern-bg-2" className="d-flex flex-column-reverse align-items-center">
                    <input id="pattern-bg-2" type="radio" value="bg-1" name="background-type"
                        checked={siteSettings?.bgPattern === "bg-1"}
                        onChange={e => { setSiteSettings({ ...siteSettings, bgPattern: "bg-1" }) }} />
                    <div className="pattern-select-wrap">
                        <img className="pattern-preview" src="/images/preview/background-pattern_1.svg" alt='Pattern 1' />
                        <div className="text-overlay">
                            <div className="m-auto">{t("Abstract")}</div>
                        </div>
                    </div>
                </label>
                <label htmlFor="pattern-bg-3" className="d-flex flex-column-reverse align-items-center">
                    <input id="pattern-bg-3" type="radio" value="bg-2" name="background-type"
                        checked={siteSettings?.bgPattern === "bg-2"}
                        onChange={e => { setSiteSettings({ ...siteSettings, bgPattern: "bg-2" }) }} />
                    <div className="pattern-select-wrap ">
                        <img className="pattern-preview" src="/images/preview/background-pattern_2.svg" alt='Pattern 2' />
                        <div className="text-overlay">
                            <div className="m-auto">{t("Line art")}</div>
                        </div>
                    </div>
                </label>
            </div>
        </Form.Group>
        <Form.Group className="bottom-separator cs-step-3">
            <h3>{t("Add image or logo")}</h3>
            <ImageUpload t={t} value={siteSettings.supImgURL} onChange={(supImgBlob, supImgExtension, tempSupImg) => { setSiteSettings({ ...siteSettings, supImgURL: null, supImgBlob, supImgExtension }); setTempSupImg(tempSupImg) }} />
        </Form.Group>
        <Form.Group className="bottom-separator cs-step-3">
            <h3>{t("Chat languages")} </h3>

            <BadgeDropdown
                infoPopover={
                    <InfoTooltip>
                        <div>
                            {t("Choose the languages widget should offer for visitors.")}
                        </div>
                    </InfoTooltip>}
                title={t("Choose recomended chat languages (up to 5)")}
                label={t("Choose the languages")}
                addBadge={addLanguage}
                removeBadge={removeLanguage}
                badges={siteSettings.lngs ? getLanguagesIsoToEng(siteSettings.lngs) : []}
                badgeSelection={getLanguages(siteSettings.lngs)}
            />
            <SearchDropdown className=" mb-2" menuWidth={220} label={t("Main chat language")} leftAlign currentSelect={getLanguage((siteSettings.defLng || "en"), "iso")?.eng} allSelection={getLanguages()} onSelect={defLng => { setSiteSettings({ ...siteSettings, defLng: getLanguage(defLng, "eng").iso }) }} />
        </Form.Group>
        <Form.Group className="bottom-separator cs-step-4">
            <h3>
                {t("Chat options")}
                <InfoTooltip>
                    <Trans t={t}
                        defaults="Chat can be further modified with position on site, email request and text message details<br /><tourtip>Position: Chat widget position is by default on the right side of your page. You can move it to the left side</tourtip><br/><tourtip>Visitor email request: New visitor vill be asked for email to start the chat</tourtip>"
                        components={{ tourtip: <i className="tour-tip"></i> }}
                    />
                </InfoTooltip>
            </h3>
            <Form.Check className="mb-1" type="switch" id="pos-switch" label={t("Left align chat")} checked={siteSettings.pos === "left"} onChange={e => { setSiteSettings({ ...siteSettings, pos: !e.target.checked ? "right" : "left" }) }} />
            <Form.Row className="mt-1 mb-1">
                <Dropdown onSelect={initialAskEmail => { setSiteSettings({ ...siteSettings, initialAskEmail }) }}>
                    <Dropdown.Toggle size="sm" >
                        {siteSettings.initialAskEmail ? optionMap[siteSettings.initialAskEmail] : optionMap["never"]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        <Dropdown.Item eventKey="never" >{optionMap["never"]}</Dropdown.Item>
                        <Dropdown.Item eventKey="away" >{optionMap["away"]}</Dropdown.Item>
                        <Dropdown.Item eventKey="always" >{optionMap["always"]}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <label className="dropdown-label ml-2">{t("Ask visitor info")}</label>
                <Dropdown className="ml-2" onSelect={initialAskType => { setSiteSettings({ ...siteSettings, initialAskType }) }}>
                    <Dropdown.Toggle size="sm" >
                        {siteSettings.initialAskType ? optionMapType[siteSettings.initialAskType] : optionMapType["email"]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        <Dropdown.Item eventKey="email" >{t("Email")}</Dropdown.Item>
                        <Dropdown.Item eventKey="phone" >{t("Phone number")}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form.Row>
        </Form.Group>
        <Form.Group className="bottom-separator">
            <h3>
                {t("Personal information notification")}
                <InfoTooltip>
                    <Trans t={t} defaults="Add notification for customers against inserting personal information into chat." />
                </InfoTooltip>
            </h3>
            <Form.Check className="mb-1" type="switch" id="pii-notification-switch" label={t("Show personal info usage notification")} checked={siteSettings.pii_notification} onChange={e => { setSiteSettings({ ...siteSettings, pii_notification: e.target.checked }) }} />
        </Form.Group>
        <Schedules siteSettings={siteSettings} setSiteSettings={setSiteSettings} />
    </div>)
}
function CollapsibleFAQ({ title, setTitle, answer, onSwap, isFirst, isLast, setAnswer, remove, sourceLang, setAll, hasMissingTranslations }) {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation("settings_widget")
    const translateQnA = () => {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Automatically translate to multiple languages?"),
                body: t("Your question and answer will be translated from your current language to all available languages. This enables your visitors to read your answer in their selected language. You can also check the translations manually once you have enabled the machine translation."),
                confirmText: t("Translate"),
                includeLoading: true,
            },
            onConfirm: () => (
                getAllTranslations({ sourceLang, answer, question: title, targetLangs: getLanguagesIso() }).then(r => {
                    setAll(r.question, r.answer)
                }).catch(e => {
                    addErrorToast(t("Could not translate"))
                })
            ),
        })
    }

    return (
        <Collapsible
            open={true}
            triggerDisabled={true}
            openedClassName="collapse-open"
            trigger={<TitleRow open={open} hasMissingTranslations={hasMissingTranslations} setOpen={setOpen} remove={remove} title={title} onTranslate={translateQnA} setTitle={title => { setTitle(title) }} />} >
            <>
                <textarea value={answer} onChange={e => setAnswer(e.target.value)} placeholder={t("Type your answer here in {{lang}}", { lang: getLanguage(sourceLang, "iso")?.nativeName })}></textarea>
                <div className='swap-wrap'>
                    {!isFirst && <div className='swap' onClick={() => onSwap(true)}><i className="far fa-arrow-up"></i></div>}
                    {!isLast && <div className='swap' onClick={() => onSwap(false)}><i className="far fa-arrow-down"></i></div>}
                </div>
            </>
            {/*<TWEditor noBorder />*/}
        </Collapsible >
    )
}

function TitleRow({ open, setOpen, onTranslate, remove, title, setTitle, hasMissingTranslations }) {
    const { t } = useTranslation("settings_widget")
    function RemoveRowModal(params) {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Remove question ?"),
                body: t("Are you sure you want to remove this question and all other translations of it ?"),
                includeLoading: false,
            },
            onConfirm: remove,
        })
    }
    return (
        <div className="trigger-row">
            <input className="ellipsis" type="text" placeholder={t("Type your question here...")} value={title} onChange={e => { setTitle(e.target.value) }} ></input>
            {hasMissingTranslations && < div className='missing-translations'>
                <WarningTooltip>
                    <Trans t={t}
                        defaults="You are missing translations on this question<br></br><tourtip>Click on 'Translate' to apply automatic translation</tourtip>"
                        components={{ tourtip: <i className="tour-tip"></i> }}
                    />
                </WarningTooltip>
            </div>}
            <button type="button" onClick={onTranslate}>{t("Translate")}</button>
            <button type="button" onClick={RemoveRowModal}><i className="far fa-trash-alt "></i></button>
        </div >)
}

function ColorPicker(params) {
    const [displayPicker, setDisplayPicker] = useState(false)
    const pickedColor = params.value ? params.value : "#27304E"

    const handleClick = () => {
        setDisplayPicker(!displayPicker)
    };

    const handleClose = () => {
        setDisplayPicker(false)
    };

    return (
        <div className="color-wrap mb-3" onKeyDown={e => { if (e.keyCode === 13) e.preventDefault() }}>
            <div className="swatch" onClick={handleClick}>
                <div className="swatch-color" style={{ background: pickedColor }} />
                <div className='swatch-overlay'>
                    <i className='fas fa-pen m-auto' ></i>
                </div>
            </div>
            {displayPicker ? <div className="color-popover">
                <div className="popover-cover" onClick={handleClose} />
                <ChromePicker color={pickedColor} onChange={(color) => { params.onChange(color.hex) }} />
            </div> : null}
        </div>
    )
}

function TimeInputWithIcon({ value, onChange }) {
    return (
        <div className="time-input form-control d-flex">
            <TimeField value={value} onChange={(e, value) => { onChange && onChange(value) }} input={<input type="text" />} />
            <i className="far fa-clock ml-auto my-auto"></i>
        </div>
    )
}

function Schedule({ value = { days: [] }, onChange, onRemove }) {
    const { t } = useTranslation("settings_widget")
    return (
        <div className="mb-3">
            <div className="row">
                <div className="col-12">
                    <BadgeDropdown
                        label={t("Choose schedule days")}
                        addBadge={(addedBadge) => { onChange && onChange({ ...value, days: [...(value.days ? value.days.filter(day => day !== addedBadge) : []), addedBadge] }) }}
                        removeBadge={(removedBadge) => { onChange && onChange({ ...value, days: [...(value.days ? value.days.filter(day => day !== removedBadge) : [])] }) }}
                        badgeSelection={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                        badges={value.days || []}
                        displayMapping={(badge) => {
                            const translationsMap = {
                                "Monday": t("Monday"),
                                "Tuesday": t("Tuesday"),
                                "Wednesday": t("Wednesday"),
                                "Thursday": t("Thursday"),
                                "Friday": t("Friday"),
                                "Saturday": t("Saturday"),
                                "Sunday": t("Sunday"),
                            }

                            return translationsMap[badge]
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Form.Group>
                        <Form.Label>{t("Start time")}</Form.Label>
                        <TimeInputWithIcon
                            value={value.startTime}
                            onChange={(newTime) => { onChange({ ...value, startTime: newTime }) }}
                        />
                    </Form.Group>
                </div>
                <div className="col-6">
                    <Form.Group>
                        <Form.Label>{t("End time")}</Form.Label>
                        <TimeInputWithIcon
                            value={value.endTime}
                            onChange={(newTime) => { onChange({ ...value, endTime: newTime }) }}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex">
                <div className="remove-schedule d-flex ml-auto" onClick={onRemove}>
                    <i className="far fa-trash-alt  ml-auto mr-2 my-auto"></i><span>{t("Remove", { ns: 'common' })}</span>
                </div>
            </div>
        </div>
    )
}

function Schedules({ siteSettings, setSiteSettings }) {
    const { t } = useTranslation("settings_widget")
    useScrollToLocation();
    let tempSchedules = { ...(siteSettings?.schedules || {}) } // Clone schedules object so unsaved logic hack works

    function onChange(newValue) {
        setSiteSettings({ ...siteSettings, schedules: newValue })
    }

    if (!tempSchedules.timezone) {
        tempSchedules.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (!tempSchedules.times) {
        tempSchedules.times = []
    }

    let schedules = tempSchedules.times.map((el, i) => (<Schedule key={i}
        value={el}
        onChange={(newVal) => {
            tempSchedules.times[i] = newVal
            onChange({ ...tempSchedules })
        }}
        onRemove={() => {
            tempSchedules.times.splice(i, 1)
            onChange({ ...tempSchedules })
        }} />))

    function addSchedule() {
        onChange({
            ...tempSchedules,
            times: [...tempSchedules.times, {
                startTime: "09:00",
                endTime: "17:00",
                days: [],
            }]
        })
    }
    let selectedTz = getTimezone(tempSchedules.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)

    return (
        <div className="cs-step-5" onKeyDown={e => { if (e.keyCode === 13) e.preventDefault() }}>
            <h3>
                {t("Chat online hours")}
                <InfoTooltip>
                    <Trans t={t}
                        defaults="Set your operating hours.<br/><tourtip>You can add all days you have the same operating hours in one option. And new rows for other days.</tourtip>"
                        components={{ tourtip: <i className="tour-tip"></i> }}
                    />
                </InfoTooltip>
            </h3>
            <div>
                {schedules.length > 0 && <SearchDropdown className="mt-2 mb-0" label={t("Timezone")} leftAlign currentSelect={selectedTz.label} valueKey="tzCode" labelKey="label" allSelection={timezones} onSelect={(tz) => { onChange && onChange({ ...siteSettings.schedules, timezone: tz }) }} />}
                {schedules}
            </div>
            <div className="add-schedule justify-start" onClick={addSchedule}>
                <i className="fas fa-plus-circle mr-2"></i>
                <span>{t("Add new")}</span>
            </div>
            <div id="set-schedule"></div>
        </div>
    )
}


function FormSettings({ missingError, formRecipients, setFormRecipents }) {
    const { t } = useTranslation("settings_widget")
    if (!formRecipients)
        formRecipients = []

    function addRecipient() {
        setFormRecipents([...formRecipients, ""])
    }

    function removeEmail(index) {
        formRecipients.splice(index, 1);
        setFormRecipents([...formRecipients])
    }

    let recipientInputs = formRecipients.map((r, i) => (
        <Form.Group className="form-email-input" key={i}>
            <Form.Label>{t("Email #{{count}}", { count: (i + 1) })}</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={r} onChange={e => { formRecipients[i] = e.target.value; setFormRecipents([...formRecipients]) }} />
            <div className="remove-email" onClick={() => { removeEmail(i) }} ><i className="fas fa-trash m-auto"></i></div>
        </Form.Group>
    ))
    return (
        <div onKeyDown={e => { if (e.keyCode === 13) e.preventDefault() }}>
            {recipientInputs}
            <div className="add-schedule justify-start" onClick={addRecipient}>
                <i className="fas fa-plus-circle mr-2"></i>
                <span>{t("Add new recipient")}</span><span className="general-error ml-2">{missingError}</span>
            </div>
        </div>
    )
}

class ImageUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null
        }

        this.logoUploader = React.createRef();
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        if (!event.target.files || !event.target.files[0]) {
            this.props.onChange(null, null, null)
            this.setState({
                file: null,
            })
        } else if (event.target.files[0].size / 1024 / 1024 > 6) {
            addErrorToast(i18next.t("Maximum logo sise is 6 mb", { ns: 'settings_widget' }))
            this.setState({
                file: null,
            })
        } else {
            getBase64(event.target.files[0]).then(base64 => {
                let extension = event.target.files[0].name.split('.').pop();
                let file = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null
                this.props.onChange(base64 || null, extension || null, file || null)
                this.setState({ file })
            }).catch(e => {
                addErrorToast(i18next.t("Could not load image", { ns: 'settings_widget' }))
            })
        }

    }
    render() {
        const url = this.state.file ? this.state.file : this.props.value ? this.props.value + "?" + (new Date().getTime()) : "/images/icon/image-ph.svg"
        return (
            <div className="logo-preview-wrap">
                {(this.state.file || this.props.value) && <div className="remove-image" onClick={this.handleChange}><i className="fas fa-trash m-auto"></i></div>}
                <input style={{ display: "none" }} ref={this.logoUploader} type="file" accept="image/*" onChange={this.handleChange} />
                <img
                    alt="Logo preview"
                    className="logo-preview"
                    src={url}
                    onClick={() => { this.logoUploader.current.click() }}
                />
            </div>
        );
    }
}

function PlusAdd({ children, className, ...params }) {
    return (
        <div className={"add-schedule justify-start " + (className ? className : "")} {...params}>
            <i className="fas fa-plus-circle mr-2"></i>
            <span>{children}</span>
        </div>
    )
}