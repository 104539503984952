import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { recover } from "../../logic/api";
import { checkPassword } from '../../logic/util';
import backgroundSide from '../../resources/askly-small-white.svg';
import { addInfoToast } from "../base/ToastManager";
import { AuthWrap } from "./SignIn";

export default function Recover(props) {
    let history = useHistory();
    const { t } = useTranslation("noauth")
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [errors, setErrors] = useState({});

    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: t("Password is required") })
            validated = false;
        } else {
            let errors = checkPassword(password) || [];
            if (errors.length > 0) {
                tmpErrors = Object.assign(tmpErrors, { password: t("Password must match requirements"), passwordReq: errors })
                validated = false;
            }
        }

        if (passwordAgain.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { passwordAgain: t("Password is required") })
            validated = false;
        } else if (password !== passwordAgain) {
            tmpErrors = Object.assign(tmpErrors, { passwordAgain: t("Password must match") })
            validated = false;
        }

        setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();
        let code = new URLSearchParams(props.location.search).get("code")

        if (validateForm()) {
            recover(password, passwordAgain, code).then(() => {
                addInfoToast(t("Password reset"))
                history.push("/sign-in");
            }).catch(err => {
                if (err.statusCode && err.statusCode === "NO_SUCH_TOKEN") {
                    setErrors({ general: t("Invalid password recover link") })
                } else if (err.statusCode && err.statusCode === "TOKEN_EXPIRED") {
                    setErrors({ general: t("Password recover link expired") })
                } else {
                    setErrors({ general: t("Something went wrong", { ns: 'common' }) })
                }
            })
        }
    }

    const leftContent = (
        <div className="left-body">
            <img className="left-bg" src={backgroundSide} alt="bg-askly" />
            <div className="text-wrap">
                <div className="left-txt-main">{t("Reset your password")}</div>
            </div>
        </div>)
    return <AuthWrap
        leftContent={leftContent}
        rightContent={<>
            <div style={{ height: "heigth:200px", margin: "32px 0px", float: "right" }}>
                <Link to="/sign-in" className="df-btn auth-switch">{t("Sign-in")}</Link>
            </div>
            <div className="form-wrap">
                <div className="auth-form" style={{ margin: "auto", paddingBottom: "65px" }}>
                    <div style={{ maxWidth: "460px" }}>
                        <form onSubmit={handleSubmit}>
                            <p className="login-details mb-0">{t("Enter your new password.")}</p>
                            <p className="general-error mb-5">{errors.general}</p>
                            <div className="mx-auto text-left" style={{ width: "75%" }}>
                                <Form.Group>
                                    <Form.Label>{t("Password")}</Form.Label>
                                    <Form.Control type="password" placeholder={t("Password")} onChange={e => setPassword(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("Password Again")}</Form.Label>
                                    <Form.Control type="password" placeholder="Password again" onChange={e => setPasswordAgain(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.passwordAgain}</Form.Text>
                                </Form.Group>
                            </div>
                            <div className="row password-requirements px-2 mt-3 mb-4">
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_lower") ? " error" : "")}>{t("One lowercase character")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_upper") ? " error" : "")}>{t("One uppercase character")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_digits") ? " error" : "")}>{t("One number")}</div>
                                <div className={"col-6" + (errors.passwordReq && errors.passwordReq.includes("err_len") ? " error" : "")}>{t("6 characters minimum")}</div>
                            </div>
                            <button className="df-btn" style={{ marginTop: "32px" }}>{t("Set password")}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
        }
    />
}