import React from 'react';
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Tour from "reactour";
import "./TourManager.scss";

export let openTour;

class TourManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }

        openTour = (text) => {
            this.setState({ isOpen: true });
        }

        const { t } = this.props
        this.generalTour = [
            {
                content: (<div>
                    <Trans t={t}
                        shouldUnescape={true}
                        defaults='1. Go to Settings &gt; Personal &gt; Choose here the languages YOU speak 🌍.<br/><tourtip>When it matches customer language, translation is not applied.</tourtip>'
                        components={{ tourtip: <i className="tour-tip"></i> }}
                    />
                </div>),
                selector: '.tour-step-personal-settings',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/settings/personal")
                        this.props.history.push("/settings/personal")
                },
            },
            {
                content: (
                    <div>
                        <Trans t={t}
                            defaults='2. Setup > Find how to add chat on your site, add Facebook and Instagram messages to Askly'
                        />
                    </div>),
                selector: '.tour-step-site-settings',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/settings/site")
                        this.props.history.push("/settings/site")
                }
            },
            {
                content: (<div>
                    <Trans t={t}
                        shouldUnescape={true}
                        defaults='3. Chat &gt; Choose the color of your chat, add opening hours, calls and fast replies.<br/><tourtip>👈 Add operating hours and discover more.</tourtip>'
                        components={{ tourtip: <i className="tour-tip"></i> }} />
                </div>),
                selector: '.tour-step-widget-settings',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/settings/site/web-widget" && this.props.history?.location?.search === "?block")
                        this.props.history.push("/settings/site/web-widget")
                }
            },
            {
                content: (<div>
                    <Trans t={t}
                        defaults='4. Team > Invite team members and reply to your custoomers together 🙌'
                    />
                </div>),
                selector: '.tour-step-users-settings',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/settings/site/users" && this.props.history?.location?.search === "?block")
                        this.props.history.push("/settings/site/users")
                }
            },
            {
                content: (<div>
                    <Trans t={t}
                        defaults='5. Here are all your new and archived conversations.<br/><tourtip>Enjoy using and take good care of your customers! ❤</tourtip>'
                        components={{ tourtip: <i className="tour-tip"></i> }} />
                </div>),
                selector: '.tour-step-chat-filter',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/chats")
                        this.props.history.push("/chats")
                }
            },
            {
                content: (<div>
                    <Trans t={t}
                        defaults='PS! You can connect your Facebook Messenger and manage all chats from Askly' />
                </div>),
                selector: '.tour-step-site-settings',
                action: elem => {
                    if (this.props.history?.location?.pathname !== "/settings/site")
                        this.props.history.push("/settings/site")
                }
            },
        ]
    }

    render() {
        const { t } = this.props;

        return (
            <Tour
                accentColor="#45c4a0"
                rounded={6}
                inViewThreshold={300}
                disableDotsNavigation
                showCloseButton={true}
                showNavigation={true}
                disableInteraction
                steps={this.generalTour}
                lastStepNextButton={<span>{t("Done!")}</span>}
                onRequestClose={() => { this.setState({ isOpen: false }) }}
                isOpen={this.state.isOpen}
                startAt={0}
            />
        )
    }
}

export default withTranslation("tour")(withRouter(TourManager))