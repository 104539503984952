import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import logo from '../../resources/askly-small.svg';
import HeaderBar from '../base/HeaderBar';
import MainSideBar from '../base/MainSideBar';
import './DefaultLayout.scss';

export default function DefaultLayou({ children, match, hideSideBar, noContentPad }) {
    return (
        <div className="default-pg" style={{ position: "relative" }}>
            <TopBarNotifications askToSubscribe={true} />
            <HeaderBar />
            <div className="pg-main-area">
                {!hideSideBar && <MainSideBar match={match} />}
                <div className={"pg-content-area " + (hideSideBar ? " no-sidebar" : "") + (noContentPad ? " no-pad" : "")}>
                    {children}
                </div>
            </div>
        </div>
    );
}

function TopBarNotifications(params) {
    const currentSiteRole = useSelector(state => state.user?.userDetails?.currentSiteRole)
    const site = useSelector(state => state.user?.siteDetails)
    const { t } = useTranslation("common")
    const res = [];

    function requestNotifications(params) {
        if (("Notification" in window)) {
            window.Notification.requestPermission().then(() => {
                window.location.reload(false);
            });
        }
    }

    if (site.trialPeriodEnd) {
        const trialEnd = new Date(site.trialPeriodEnd);
        const now = new Date();

        const daysInMs = 24 * 60 * 60 * 1000;
        const timeRemainingInMs = trialEnd.getTime() - now.getTime();

        if (site?.disabled === true) {
            return (<div className="notifcations-request error-notification">
                {t("This team has been disabled. Please contact customer support.")}
            </div>)
        }

        if (window.Notification?.permission !== "granted") {
            if (window.Notification?.permission === "default") {
                res.push(<div key="noti-req" className="notifcations-request">
                    {t("Please enable desktop notifications.")}
                    <span onClick={requestNotifications}>{t("Enable")}</span>
                </div>)
            } else {
                res.push(<div key="noti-req-2" className="notifcations-request">
                    {t("Desktop notifications disabled. Enable them in browser settings.")}
                </div>)
            }
        }

        if (timeRemainingInMs <= 0 && !site.currentPackage) {
            if (currentSiteRole === "OWNER" || currentSiteRole === "ADMIN") {
                res.push(<div key="trial-over" className="notifcations-request error-notification">
                    <Trans t={t}
                        defaults='Your trial is over. <sublink>Click here and choose your plan.</sublink>'
                        components={{ sublink: <Link to="/settings/subscription" ></Link> }}
                    />
                </div>)
            } else if (currentSiteRole === "MEMBER") {
                res.push(<div key="trial-over-2" className="notifcations-request error-notification">
                    {t("Your trial is over. Please contact team owner")}
                </div>)
            }
        } else if (!site.currentPackage) {
            const timeDiffInDays = Math.floor((timeRemainingInMs) / (daysInMs))
            res.push(<div key="trial-pending" className="notifcations-request">
                <Trans t={t}
                    defaults="You're currently on free trial with {{count}} day remaining. <sublink>See pricing</sublink>"
                    count={timeDiffInDays}
                    components={{ sublink: <Link to="/settings/subscription" ></Link> }}
                />
            </div>)
        }
    }

    if (res?.length > 0)
        return <>{res}</>

    return null
}

export function SwitchingSite() {
    const { t } = useTranslation("common")

    return <div className="default-pg">
        <div className="d-flex flex-column m-auto" >
            <img style={{ width: 64, margin: 'auto' }} src={logo} alt="Askly Logo" />
            <div style={{ marginTop: 12, width: 160, textAlign: 'center' }}>
                {t("Loading site data")}
            </div>
        </div>
    </div>
}
