import LockedModal from "../components/modals/LockedModal";
import { addModal } from "../components/modals/ModalManager";

export function login(data) {
    return _request("POST", "/api/sign-in", data);
}

export function adminConfig() {
    return _request("GET", "/api/app-config")
}

export function appLogin(data) {
    return _request("POST", "/api/app/sign-in", data)
}

export function signup(data) {
    return _request("POST", "/api/sign-up", data);
}

export function forgot(email) {
    return _request("POST", "/api/forgot", { email });
}

export function recover(password, password_again, code) {
    return _request("POST", "/api/recover", { password, password_again, code });
}

export function verify(token) {
    return _request("POST", "/api/verify?token=" + token)
}

export function removeDevice(deviceID) {
    return _request("POST", "/api/me/remove-device", { deviceID })
}

export function newOrg(org) {
    return _request("POST", "/api/new-org", org)
}

export function addContactTag(tag) {
    return _request("POST", "/api/contact/tag", tag)
}

export function deleteContactTag(tag) {
    return _request("DELETE", "/api/contact/tag", tag)
}

export function sendMessage(message) {
    return _request("POST", "/api/message", message, { rawData: true, contentTypeEmpty: true })
}

export function sendMessageNote(message) {
    return _request("POST", "/api/message/note", message)
}

export function sendMessageCustom(message) {
    return _request("POST", "/api/message/custom", message)
}

export function fetchChats(filter, signal) {
    return _request("GET", "/api/chats?" + asQueryString(filter), null, { signal })
}

export function switchSite(siteID) {
    return _request("GET", "/api/me/switch?no_redirect=1&site=" + siteID)
}

export function getSiteDetails() {
    return _request("GET", "/api/site")
}

export function updateSiteDetails(details) {
    return _request("POST", "/api/site/details", details)
}

export function getSiteAnalytics(filter) {
    return _request("GET", "/api/site/analytics?" + asQueryString(filter))
}

export function getMyDetails() {
    return _request("GET", "/api/me")
}

export function getAllTranslations(data) {
    return _request("POST", "/api/me/tr", data)
}

export function getManyTranslations(data) {
    return _request("POST", "/api/me/tr-many", data)
}

export function saveMySettings(settings) {
    return _request("POST", "/api/me/settings", settings)
}

export function saveMyPrefLang(prefLang) {
    return _request("POST", "/api/me/preferred-language", { prefLang })
}

export function saveSiteSettings(settings) {
    return _request("POST", "/api/site/settings", settings)
}

export function saveSiteSettingsFeatures(features) {
    return _request("POST", "/api/site/settings/features", features)
}

export function setSiteBreak(breakUntil) {
    return _request("POST", "/api/site/break", { breakUntil })
}

export function sendDeveloperGuide(email, text) {
    return _request("POST", "/api/site/send-to-developer", { email, text })
}

export function addPageFacebook(data) {
    return _request("POST", "/api/site/facebook/pages", data)
}

export function unsubscribeFacebook(pageID) {
    return _request("DELETE", "/api/site/facebook", { pageID })
}

export function reportTranslation(report) {
    return _request("POST", "/api/report/messsage", report)
}

export function saveSiteUser(user) {
    return _request("POST", "/api/site/users", user)
}

export function removeSiteUser(userID) {
    return _request("DELETE", "/api/site/users/" + userID)
}

export function leaveSite() {
    return _request("DELETE", "/api/site/users/leave")
}

export function quickReplies(quickReply) {
    return _request("GET", "/api/site/quick-replies")
}

export function newQuickReply(quickReply) {
    return _request("POST", "/api/site/quick-replies", quickReply)
}

export function updateQuickReply(quickReply) {
    return _request("POST", "/api/site/quick-replies/update", quickReply)
}

export function deleteQuickReply(quickReply) {
    return _request("DELETE", "/api/site/quick-replies", quickReply)
}

export function getSitePopups() {
    return _request("GET", "/api/site/popups")
}

export function saveSitePopup(popup) {
    return _request("POST", "/api/site/popups", popup, { rawData: true, contentTypeEmpty: true })
}

export function updateSitePopup(popup) {
    return _request("POST", "/api/site/popups/update", popup, { rawData: true, contentTypeEmpty: true })
}

export function deleteSitePopup(popup) {
    return _request("DELETE", "/api/site/popups", popup)
}

export function subscriptionPrices() {
    return _request("GET", "/api/subscriptions/prices")
}

export function startSubscription(data) {
    return _request("POST", "/api/subscriptions/start", data)
}

export function checkSubscription() {
    return _request("POST", "/api/subscriptions/check")
}

export function integrationsCreatePipedrive(data) {
    return _request("POST", "/api/integrations/pipedrive", data)
}

export function integrationsRemovePipedrive(data) {
    return _request("DELETE", "/api/integrations/pipedrive")
}

export function integrationsCreatePipedriveLead(data) {
    return _request("POST", "/api/integrations/pipedrive/lead", data)
}

export function _request(method, link, data, options) {
    let extra = {}
    if (!options) options = {}
    let headers = {}

    if (!options.contentTypeEmpty)
        headers["Content-Type"] = "application/json; charset=utf-8";

    if (options.signal)
        extra['signal'] = options.signal

    var body = (method !== "GET" && data && !options.rawData) ? JSON.stringify(data) : (options.rawData ? data : null);
    return fetch(link, { method, body, headers, ...extra })
        .then(res => {
            if (res.status === 401) {
                // No auth
                window.location.href = "/sign-in";
            } else if (res.status === 413) {
                throw { statusCode: "FILE_TO_LARGE" }
            } else if (res.status === 503) {
                // Maintenance. Reload page so nginx shows maintenance page (query param to force reload)
                addModal(<LockedModal />, {
                    locked: true,
                    data: {
                        title: "System offline",
                        body: "We'll be back shortly",
                        modalIcon: <i className="fas fa-tools"></i>,
                    },
                })
                throw { statusCode: "MAINTENANCE" }
            } else {
                return res
            }

            throw res
        })
        .then(res => res.json()).then(response => {
            if (response.status === "OK") {
                return response.data
            } else {
                throw response
            }
        })
}


export function asQueryString(params) {
    if (!params) return "";
    let entries = Object.entries(params)
    let urlParams = new URLSearchParams();

    entries.forEach(entrie => {
        if (Array.isArray(entrie[1])) {
            entrie[1].forEach(v => {
                if (v)
                    urlParams.append(entrie[0], v)
            })
        } else {
            if (entrie[1])
                urlParams.append(entrie[0], entrie[1])
        }
    })

    return urlParams.toString();
}

export function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        if (Array.isArray(object[key])) {
            object[key].forEach((v, i) => {
                formData.append(key, v)
            })
        }
        else formData.append(key, object[key])

    })
    return formData;
}