import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveSitePopup, updateSitePopup } from '../../logic/api';
import { genUUID } from '../../logic/util';
import defaultImg from '../../resources/default_booster_1.jpg';
import { ImageUploadWide } from '../base/ImageUpload';
import ColorPicker from '../base/minor/ColorPicker';
import { InfoTooltip } from '../base/minor/Tooltip';
import { addErrorToast, addInfoToast } from '../base/ToastManager';
import ConfirmModal from '../modals/ConfirmModal';
import { addModal } from '../modals/ModalManager';
import DefaultLayout from './DefaultLayout';
import './DefaultPage.scss';
import './PopupPage.scss';


const fbVals = {
    layoutType: "left",
    title: "A small gift, especially for you!",
    titleColor: "#2D2D2F",
    subText: "Complete your purchase today with the discount code:",
    subTextColor: "#2D2D2F",
    dcText: "TODAYOFF10",
    dcTextColor: "#fff",
    dcBackground: "#2a2a2a",
    backgroundColor: "#fff",
    backgroundColorSide: "#7845B4",
}

class PopupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isSaving: false,
            isMobilePreview: false,
            errors: {},
            popup: {},

            tmpPopImgObj: null,
        }

        this.setFieldValueFromEvent = this.setFieldValueFromEvent.bind(this)
        this.setPopupImage = this.setPopupImage.bind(this)
        this.setFieldValue = this.setFieldValue.bind(this)
        this.savePopup = this.savePopup.bind(this)
        this.getFieldValue = this.getFieldValue.bind(this)
        this.submitPopup = this.submitPopup.bind(this)
        this.getImgUrl = this.getImgUrl.bind(this)
        this.selectPopupType = this.selectPopupType.bind(this)
    }

    componentDidMount() {
        if (this.props.location?.pathname === "/popups/edit" &&
            (!this.props.location?.state?.popup?.id || (this.props.location?.state?.popup?.siteID !== this.props.user?.siteDetails?.id))) {
            this.props.history.push("/popups")
        } else {
            let popup = {
                template: "1",
                ...this.props.location?.state?.popup
            } || {}
            popup.settings = { ...fbVals, ...popup.settings }
            this.setState({ popup, loading: false })

            if (this.props.location?.pathname === "/popups/new")
                this.setDefaultImage()
        }
    }

    savePopup() {
        const { t } = this.props;
        if (!this.state.popup.name) {
            this.setState({
                errors: {
                    name: t("Required", { ns: 'common' }),
                }
            })

            return
        }

        const body = new FormData();
        if (this.state.tmpPopImgObj) {

            if ((this.state.tmpPopImgObj.size / 1024 / 1024) >= 1.1) {
                this.setState({
                    errors: {
                        file: t("Too large. Limit 1mb."),
                    }
                })

                return
            }
            body.append("img1", this.state.tmpPopImgObj);
        }

        if (this.state.popup.id) {
            body.append("popupData", JSON.stringify(this.state.popup))
            updateSitePopup(body).then(() => {
                addInfoToast(t("Popup saved"))
                this.props?.history?.push("/popups")
            }).catch(() => {
                addErrorToast(t("Popup save failed"))
            })
        } else {
            const save = (content) => {
                saveSitePopup(content).then(() => {
                    addInfoToast(t("Popup saved"))
                    this.props?.history?.push("/popups")
                }).catch(() => {
                    addErrorToast(t("Popup save failed"))
                })
            }

            addModal(<ConfirmModal />, {
                data: {
                    title: t("Publish"),
                    body: t("Would you like to activate this booster immediately on your site ?"),
                    cancelText: t("No", { ns: 'common' }),
                    confirmText: t("Yes", { ns: 'common' }),
                },
                onCancel: () => {
                    body.append("popupData", JSON.stringify(this.state.popup))
                    save(body)
                },
                onConfirm: () => {
                    body.append("popupData", JSON.stringify({ ...this.state.popup, isActive: true }))
                    save(body)
                }
            })
        }
    }

    submitPopup(e) {
        e.preventDefault();
        this.savePopup();
    }

    selectPopupType(intentType) {
        this.setState({ popup: { ...(this.state.popup), intentType } })
    }

    getFieldValue(field, fallback) {
        return this.state.popup?.settings?.[field]
    }

    setFieldValue(field) {
        return (newValue) => {
            this.setState({ popup: { ...(this.state.popup), settings: { ...(this.state.popup.settings), [field]: newValue } } })
        }
    }

    setFieldValueFromEvent(field) {
        return (event) => {
            this.setState({ popup: { ...(this.state.popup), settings: { ...(this.state.popup.settings), [field]: event.target.value } } })
        }
    }

    setPopupImage(tmpPopImgUrl, tmpPopImgObj) {
        if (!tmpPopImgUrl)
            this.setState({ tmpPopImgUrl, tmpPopImgObj, popup: { ...(this.state.popup), hasImg1: false } })

        this.setState({ tmpPopImgObj, tmpPopImgUrl })
    }

    setDefaultImage() {
        fetch(defaultImg)
            .then(res => res.blob())
            .then(blob => {
                let fileObj = new File([blob], "img.jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
                this.setState({ tmpPopImgObj: fileObj, tmpPopImgUrl: URL.createObjectURL(blob) })
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            // this.loadStats(this.state.filter)
        }
    }

    getImgUrl() {
        let popup = this.state.popup
        if (this.state.tmpPopImgUrl)
            return this.state.tmpPopImgUrl

        return popup?.hasImg1 ? "/api/site-popups/" + popup.siteID + "/" + popup.id + "_img1?rand=" + genUUID() : null
    }

    render() {
        const { t } = this.props;
        const typeNames = {
            page_abandon_1: t("Page abandonment recover type"),
            welcome_coupon_1: t("First time visitors coupon"),
        }

        if (this.state.loading)
            return (<DefaultLayout noContentPad match={this.props.match}> </DefaultLayout>)

        if (!this.state.popup.id && !this.state.popup.intentType)
            return <PopupTypeSelector match={this.props.match} onSelect={this.selectPopupType} />

        return (
            <DefaultLayout noContentPad match={this.props.match}>
                <div className="popup-page">
                    <div className="content-card main-card">
                        <div className='d-flex w-100'>
                            <div className='popup-toolbar'>
                                <h4 className='mt-3'>{t("Booster settings")}</h4>
                                <div className='type-description mb-3'>{typeNames[this.state.popup?.intentType]}</div>
                                <PopupEditorForm
                                    setFieldValue={this.setFieldValue}
                                    getFieldValue={this.getFieldValue}
                                    setFieldValueFromEvent={this.setFieldValueFromEvent}
                                    submitPopup={this.submitPopup}
                                    name={this.state.popup.name}
                                    setName={(name) => { this.setState({ popup: { ...this.state.popup, name } }) }}
                                    errors={this.state.errors}
                                    getImgUrl={this.getImgUrl}
                                    setPopupImage={this.setPopupImage}
                                />
                            </div>
                            <div className='preview-container'>
                                <div className='popup-horizontal-toolbar d-flex'>
                                    <div className=''></div>
                                    <div className=" d-flex mx-auto">
                                        <div className={classNames('ml-auto platform-switch p-2', { 'is-selected': this.state.isMobilePreview })} onClick={() => { this.setState({ isMobilePreview: true }) }}>
                                            <i className="fas fa-mobile"></i>
                                        </div>
                                        <div className={classNames('mr-auto platform-switch p-2', { 'is-selected': !this.state.isMobilePreview })} onClick={() => { this.setState({ isMobilePreview: false }) }}>
                                            <i className="fas fa-desktop"></i>
                                        </div>
                                    </div>
                                    <div className=' d-flex'>
                                        <Link to="/popups" className='ml-auto'>
                                            <Button variant="outline-secondary" form='current-popup-form' type='button' >{t("Back", { ns: 'common' })}</Button>
                                        </Link>
                                        <Button form='current-popup-form' type='submit' className='ml-3'>{t("Save", { ns: 'common' })}</Button>
                                    </div>
                                </div>
                                <div className='preview-popup'>
                                    <PopupType1 isMobileView={this.state.isMobilePreview} getFieldValue={this.getFieldValue} getImgUrl={this.getImgUrl} settings={this.state.popup.settings} tempPopImg1={this.state.tempPopImg1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultLayout >)
    }
}

const mapStateToProps = state => ({ ...state });
export default withTranslation("popup")(connect(mapStateToProps)(PopupPage));


function PopupEditorForm(params) {
    const { t } = useTranslation("popup")
    const defColors = ['#2a2a2a', '#fff', '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB']

    return (<Form id="current-popup-form" onSubmit={params.submitPopup} >
        <Form.Group className="bottom-separator cs-step-2">
            <Form.Label>{t("Name")}<span className="general-error ml-2">{params.errors?.name}</span></Form.Label>
            <div className='d-flex align-items-center'>
                <Form.Control type="text" placeholder={t("Name of your booster")} value={params.name || ""} maxLength={55} onChange={(e) => { params.setName(e.target.value) }} />
            </div>
        </Form.Group>
        <Form.Group className='d-flex'>
            <div className='w-50'>
                <Form.Label>{t("Background color")}<span className="general-error ml-2">{ }</span></Form.Label>
                <ColorPicker type="block" colors={defColors} isSmall value={params.getFieldValue("backgroundColor")} onChange={params.setFieldValue("backgroundColor")} />
            </div>
            <div className='w-50'>
                <Form.Label>{t("Side background color")}<span className="general-error ml-2">{ }</span></Form.Label>
                <ColorPicker type="block" colors={defColors} alignRight isSmall value={params.getFieldValue("backgroundColorSide")} onChange={params.setFieldValue("backgroundColorSide")} />
            </div>
        </Form.Group>
        <Form.Group>
            <Form.Label>{t("Title")}<span className="general-error ml-2">{ }</span></Form.Label>
            <div className='d-flex align-items-center'>
                <Form.Control className='mr-2' type="text" placeholder="Title" value={params.getFieldValue("title")} maxLength={55} onChange={params.setFieldValueFromEvent("title")} />
                <ColorPicker type="block" colors={["#fff", "#000"]} alignRight isSmall value={params.getFieldValue("titleColor")} onChange={params.setFieldValue("titleColor")} />
            </div>
        </Form.Group>
        <Form.Group>
            <Form.Label>{t("Sub text")}<span className="general-error ml-2">{ }</span></Form.Label>
            <div className='d-flex align-items-center'>
                <Form.Control className='mr-2' as="textarea" type="text" placeholder="Sub text" value={params.getFieldValue("subText")} maxLength={155} onChange={params.setFieldValueFromEvent("subText")} />
                <ColorPicker type="block" colors={["#fff", "#000"]} alignRight isSmall value={params.getFieldValue("subTextColor")} onChange={params.setFieldValue("subTextColor")} />
            </div>
        </Form.Group>
        <Form.Group>
            <Form.Label>{t("Discount code")}<span className="general-error ml-2">{ }</span></Form.Label>
            <div className='d-flex align-items-center'>
                <Form.Control className='mr-2' type="text" placeholder="Discount code" value={params.getFieldValue("dcText")} maxLength={55} onChange={params.setFieldValueFromEvent("dcText")} />
                <ColorPicker type="block" colors={["#fff", "#000"]} className="mr-2" alignRight isSmall value={params.getFieldValue("dcTextColor")} onChange={params.setFieldValue("dcTextColor")} />
                <ColorPicker type="block" colors={defColors} alignRight isSmall value={params.getFieldValue("dcBackground")} onChange={params.setFieldValue("dcBackground")} />
            </div>
        </Form.Group>
        <Form.Group>
            <Form.Label className="d-flex align-items-center">
                {t("Your checkout page URL after /")}
                <InfoTooltip>{t("Add coma seprated list of all url paths you wish to enable this in. You can use * as wildcard.")}</InfoTooltip>
                <span className="general-error ml-2">{ }</span>
            </Form.Label>
            <div className='d-flex align-items-center'>
                <Form.Control className='mr-2' as="textarea" type="text" placeholder="/checkout" value={params.getFieldValue("pagesFilter")} maxLength={555} onChange={params.setFieldValueFromEvent("pagesFilter")} />
            </div>
        </Form.Group>
        <Form.Group>
            <Form.Group className="bottom-separator cs-step-3 d-flex flex-column">
                <Form.Label>{t("Change your image")}<span className="general-error ml-2">{params.errors?.file}</span></Form.Label>
                <ImageUploadWide value={params.getImgUrl()} onChange={(supImgBlob, supImgExtension, tempPopImgUrl, tempImg) => { params.setPopupImage(tempPopImgUrl, tempImg) }} />
            </Form.Group>
        </Form.Group>
    </Form>)
}


function IntentGroup({ ico, title, subText, comingSoon, onSelect, selected }) {
    const { t } = useTranslation("popup")
    return (
        <div
            className={classNames('intent-group-card content-card flex-column', { selected })}
            onClick={() => {
                if (!comingSoon && onSelect)
                    onSelect()
            }}>
            <div className='d-flex mb-3'>
                <div className='ico-wrap'>{ico}</div>
                <div className='descriptions'>
                    <div className='title'>{title}</div>
                    <div className='sub-text'>{subText}</div>
                </div>
            </div>
            {comingSoon && <div className='coming-soon'>
                <div className='ph-text'>{t("Coming soon")}</div>
            </div>}
        </div >)
}


function IntentSelector({ ico, title, subText, comingSoon, onSelect, selected }) {
    const { t } = useTranslation("popup")
    return (
        <div
            className={classNames('intent-card content-card flex-column')}
            onClick={() => {
                if (!comingSoon && onSelect)
                    onSelect()
            }}>
            <div className='descriptions'>
                <div className='title'>{ico}{title}</div>
                <div className='sub-text'>{subText}</div>
            </div>
            {comingSoon && <div className='coming-soon'>
                <div className='ph-text'>{t("Coming soon")}</div>
            </div>}
        </div >
    )
}
function PopupTypeSelector(params) {
    const { t } = useTranslation('popup')
    const [group, setGroup] = useState("")

    return (
        <DefaultLayout noContentPad match={params.match}>
            <div className="popup-page d-flex">
                <div className="flex-column mx-auto mb-auto">
                    <div className=' mb-4'>
                        <div className='title-row'>
                            <span className="popup-page-title ">
                                {t("Select goal")}
                            </span>
                            <Link to="/popups" className="ml-auto" >
                                <Button type='button' variant="outline-secondary">
                                    {t("Back", { ns: 'common' })}
                                </Button>
                            </Link>
                        </div>

                        <div className='intents-wrap'>
                            <IntentGroup
                                ico={<i className="far fa-usd-circle"></i>}
                                title={t("Boost sales")}
                                subText={t("Offer a small discount to help more customers finish the checkout.")}
                                selected={group === "boost_sales"}
                                onSelect={() => { setGroup("boost_sales") }}
                            />
                            <IntentGroup
                                ico={<i className="far fa-bullhorn"></i>}
                                title={t("Announcements")}
                                comingSoon
                                subText={t("Let visitors know of upcoming updates or events")}
                            />
                            <IntentGroup
                                ico={<i className="far fa-thumbs-up"></i>}
                                title={t("Collect feedback")}
                                comingSoon
                                subText={t("Get to know your customers with simple questionnaires")}
                            />
                        </div>
                        {group === "boost_sales" && <>
                            <div className=' sub-title-row'>
                                <div>{t("Select the way to boost sales")}</div>
                            </div>
                            <div className='intents-wrap'>
                                <IntentSelector
                                    ico={<i className="far fa-shopping-cart"></i>}
                                    title={t("Cart saver")}
                                    subText={t("Show a smart popup only when the exit intent is detected.")}
                                    onSelect={() => params.onSelect("page_abandon_1")}
                                />
                                <IntentSelector
                                    ico={<i className="far fa-chart-line"></i>}
                                    title={t("Promote product")}
                                    subText={t("Highlight key products for visitors")}
                                    comingSoon
                                />
                            </div>
                        </>}
                    </div>
                </div>

            </div>
        </DefaultLayout>
    )
}
function PopupType1({ getFieldValue, getImgUrl, isMobileView }) {
    const { t } = useTranslation('popup')
    const styles = {
        mobile: {
            wrap: { width: 320, height: 460, display: 'flex', background: getFieldValue("backgroundColor"), flexDirection: 'column-reverse' },
            image: {
                flex: "0 0 auto",
                maxWidth: "100%",
                height: "auto",
                position: "relative",
                left: 0,
                top: 0,
            }
        },
        desktop: {
            wrap: { width: 520, height: 320, display: 'flex', background: (getFieldValue("backgroundColor")) },
            image: {
                flex: "0 0 auto",
                height: "100%",
                minWidth: "100%",
                position: "relative",
                left: 0,
                top: 0,
            }
        }
    }
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if (copied) {
            const clickedTimeout = setTimeout(() => {
                setCopied(false)
            }, 2500)

            return () => {
                clearTimeout(clickedTimeout)
            }
        }
    }, [copied])


    function codeClick() {
        navigator?.clipboard?.writeText(getFieldValue("dcText")).then(() => {
            setCopied(true)
        });
    }

    let currentStyle = isMobileView === true ? styles.mobile : styles.desktop
    return (
        <div className='popup-template' style={currentStyle.wrap}>
            <div style={{ flex: 3, textAlign: 'center', padding: '24px 34px', display: 'flex', flexDirection: 'column' }}>
                {getFieldValue("title") && <div style={{ color: getFieldValue("titleColor"), fontSize: 26, fontWeight: 600, margin: "24px 0px" }}>
                    {getFieldValue("title")}
                </div>}
                {getFieldValue("subText") && <div style={{ color: getFieldValue("subTextColor"), fontSize: 14, fontWeight: 400 }}>
                    {getFieldValue("subText")}
                </div>}
                {getFieldValue("dcText") && <div style={{
                    cursor: 'pointer',
                    background: getFieldValue("dcBackground"),
                    color: getFieldValue("dcTextColor"),
                    borderRadius: 4,
                    padding: "4px 22px",
                    margin: "auto",
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',

                }} onClick={codeClick}>
                    {getFieldValue("dcText")}
                    {copied && <div className="" style={{
                        position: 'absolute',
                        bottom: -22,
                        left: "calc(50% - 23px)",
                        background: "#2222222e",
                        padding: " 2px 4px",
                        borderRadius: 3,
                        fontSize: 12,
                        color: "#fff",
                    }}>
                        {t("Copied")}
                    </div>}
                </div>}
            </div>
            <div style={{ flex: 2, position: 'relative', overflow: 'hidden', background: getFieldValue("backgroundColorSide") }}>
                <img src={getImgUrl()} style={currentStyle.image} alt=""></img>
            </div>
        </div>)
}
