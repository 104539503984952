import { createApi } from '@reduxjs/toolkit/query/react';
import { _request, asQueryString, getFormData } from '../logic/api';

function customBaseQuery({ method, url, data, body, options }, reduxs, extraOptions) {
    return _request(method, url, body, options).then(data => ({ data: data || null })).catch((error) => ({ error }))
}

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded 
 */
export const baseAPI = createApi({
    reducerPath: 'coreApi',
    refetchOnMountOrArgChange: true,
    baseQuery: customBaseQuery,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     */
    tagTypes: ['Assistant', 'AssistantDocuments', 'SettingsEmail'],
    endpoints: (build) => ({
        featureRequest: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/request/feature', body: data }),
        }),
        getAssistant: build.query({
            query: () => ({ method: "GET", url: '/api/site/assistant/settings' }),
            providesTags: (_result, _err, id) => [{ type: 'Assistant', site_id: _result.site_id }],
        }),
        updateAssistant: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/assistant/settings', body: data }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Assistant' }],
        }),
        updateAssistantDocument: build.mutation({
            query: ({ id, data }) => ({ method: "POST", url: '/api/site/assistant/document/' + id, body: data }),
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        deleteAssistantDocuments: build.mutation({
            query: (id) => ({ method: "DELETE", url: '/api/site/assistant/document/' + id }),
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        uploadAssistantDocuments: build.mutation({
            query: (selectedFiles) => {
                const body = new FormData();

                if (selectedFiles && selectedFiles.length > 0) {
                    for (var i = 0; i < selectedFiles.length; i++) {
                        body.append("files", selectedFiles[i]);
                    }
                }

                return { method: "POST", url: "/api/site/assistant/document", body, options: { rawData: true, contentTypeEmpty: true } }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'AssistantDocuments' }],
        }),
        getAssistantDocuments: build.query({
            query: () => ({ method: "GET", url: '/api/site/assistant/document/list' }),
            providesTags: (_result, _err, id) => [{ type: 'AssistantDocuments', site_id: _result.site_id }],
        }),
        sendContactNote: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/message/note', body: data }),
        }),
        // ////////////// //
        // Email settings //
        // ////////////// //
        getEmailSettings: build.query({
            query: () => ({ method: "GET", url: '/api/site/settings/email' }),
            providesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        addEmailDomain: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/domain', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        deleteEmailDomain: build.mutation({
            query: (data) => ({ method: "DELETE", url: '/api/site/settings/email/domain', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        getEmailAddresses: build.query({
            query: () => ({ method: "GET", url: '/api/site/settings/email/addresses' }),
            providesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        addEmailAddress: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/address', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        deleteEmailAddress: build.mutation({
            query: (data) => ({ method: "DELETE", url: '/api/site/settings/email/address', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        updateEmailAddressSignature: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/site/settings/email/address/signature', body: data }),
            invalidatesTags: (_result, _err, id) => [{ type: 'SettingsEmail' }],
        }),
        sendMessageEmail: build.mutation({
            query: (data) => ({ method: "POST", url: '/api/message/email', body: getFormData(data), options: { rawData: true, contentTypeEmpty: true } }),
        }),
        getEmailMessage: build.query({
            query: (data) => ({ method: "GET", url: '/api/message/email?' + asQueryString(data) }),
        }),
    }),
})

export const {
    useFeatureRequestMutation,

    useGetAssistantQuery,
    useGetAssistantDocumentsQuery,
    useUpdateAssistantMutation,
    useUploadAssistantDocumentsMutation,
    useUpdateAssistantDocumentMutation,
    useDeleteAssistantDocumentsMutation,
    // Send email
    useSendMessageEmailMutation,
    useSendContactNoteMutation,
    // Email settings
    useAddEmailDomainMutation,
    useDeleteEmailDomainMutation,
    useAddEmailAddressMutation,
    useDeleteEmailAddressMutation,
    useUpdateEmailAddressSignatureMutation,
    useGetEmailSettingsQuery,
    useGetEmailAddressesQuery,
    useGetEmailMessageQuery,
} = baseAPI
