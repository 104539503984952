import React from 'react';
import { connect } from 'react-redux';
import { fetchChatList } from '../../state/chatList';
import ChatBox, { NoActiveChatPH } from '../base/ChatBox';
import ChatsList from '../base/ChatsList';
import { openTour } from '../base/TourManager';
import './ChatPage.scss';
import DefaultLayout from './DefaultLayout';
import './DefaultPage.scss';
class ChatPage extends React.Component {

    componentDidMount() {
        if (this.props.location?.state?.runTour) {
            openTour()
        }

        this.props.dispatch(fetchChatList())
    }

    render() {
        let haveActiveChat = this.props.activeChat.activeContactID !== 0;

        return (
            <DefaultLayout match={this.props.match}>
                <div className="content-card main-card chat-page position-relative">
                    <ChatsList />
                    {!haveActiveChat && <NoActiveChatPH />}
                    {haveActiveChat && <ChatBox />}
                </div>
            </DefaultLayout>)
    }
}

const mapStateToProps = state => ({ ...state });
export default connect(mapStateToProps)(ChatPage);
