import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from "react-i18next";
import { useDeleteEmailAddressMutation, useDeleteEmailDomainMutation, useGetEmailSettingsQuery } from '../../../state/api';
import { addErrorToast, addInfoToast } from '../../base/ToastManager';
import { ThinButton } from '../../base/minor/Common';
import { CustomPlainToggle } from '../../base/minor/CustomDropdown';
import FieldWithCopy from '../../base/minor/FieldWithCopy';
import RefreshButton from '../../base/minor/RefreshButton';
import { InfoTooltip } from '../../base/minor/Tooltip';
import AddEmailAddress from '../../modals/AddEmailAddress';
import AddEmailDomain from '../../modals/AddEmailDomain';
import ConfirmModal from '../../modals/ConfirmModal';
import EmailSignatureModal from '../../modals/EmailSignatureModal';
import { addModal } from '../../modals/ModalManager';
import { DefaultSettingsWrap } from './Base';
import { TabInfo } from './ChatWidget';


export default function Email() {
    const [tab, setTab] = useState("general")
    const { data: emailSettings, error: settingsError, isLoading: emailSettingsIsLoading, refetch } = useGetEmailSettingsQuery()
    const { t } = useTranslation("settings_email")

    const openAddDomainModal = () => {
        addModal(<AddEmailDomain />, {})
    }

    return (
        <DefaultSettingsWrap
            subPageTitle={t("Email settings", { ns: 'settings_nav' })}
            optionClick={(val) => { setTab(val) }}
            activeSubOption={tab}
            buttons={<div>
                <Button variant="success" className="float-right" onClick={openAddDomainModal}><i className="far fa-plus mr-2 my-auto"></i>{t("Add domain")}</Button>
            </div>}>
            <div className="chat-settings-wrap">
                <GeneralTab isLoading={emailSettingsIsLoading} refetch={refetch} emailSettings={emailSettings} openAddDomainModal={openAddDomainModal} />
            </div>
        </DefaultSettingsWrap >
    )
}

function GeneralTab({ refetch, isLoading, openAddDomainModal, emailSettings }) {
    const { t } = useTranslation("settings_email")
    const [deleteEmailDomain] = useDeleteEmailDomainMutation()


    const openAddEmailAddressModal = ({ strictDomain }) => {
        addModal(<AddEmailAddress />, { emailSettings, strictDomain })
    }

    const deleteEmailDomainWrap = (domainID) => {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Remove domain ?"),
                body: t("Are you sure you want to remove this domain?"),
                confirmText: t("Delete", { ns: 'common' }),
            },
            onConfirm: () => {
                deleteEmailDomain({ id: domainID }).unwrap().then(() => {
                    addInfoToast(t("Domain deleted"))
                }).catch(() => {
                    addErrorToast(t("Error deleting domain"))
                })
            }
        })
    }

    const copyForwardingAddress = () => {
        copy(emailSettings?.forwardEmail)
        addInfoToast(t("Copied to clipboard"))
    }

    let hasDomains = emailSettings?.domains?.length > 0
    return (
        <Form id="current-settings-form" onSubmit={() => { }}>
            <TabInfo noToggle >
                <div className='d-flex flex-column'>
                    {t("Get started with email integration. Forward incoming emails to forwarding address and add your email domain and sender addresses to start using the email features.")}
                </div>
            </TabInfo>
            <Form.Group className="bottom-separator cs-step-4">
                <h3>
                    {t("Incoming emails forwarding")}
                </h3>
                <Form.Group>
                    <Form.Row className='mb-2 align-items-center'>
                        <Form.Label className="mb-0 mr-2">{t("Forwarding address")} </Form.Label>
                        <ThinButton className="flex-shrink-0 txt-small" onClick={copyForwardingAddress}><i className="mx-1 far fa-clipboard"></i>{t("Copy")}</ThinButton>
                    </Form.Row>
                    <Form.Control type="text" placeholder="123-support-example@customer-support.askly.me" value={emailSettings?.forwardEmail} maxLength={55} onChange={e => { }} disabled={true} />
                    {false && <Form.Label className="mb-0 mr-2 text-blue">{t("More forwarding addresses")} </Form.Label>}

                </Form.Group>
            </Form.Group>
            <Form.Group>
                <h3>
                    {t("Domains")}
                </h3>
                <Form.Group className="">
                    {false && <AsklyDomainWrap addAddressPopup={openAddEmailAddressModal} emailAddresses={emailSettings?.emailAddresses?.filter?.(e => !e.externalEmailDomainID)} />}
                    {!isLoading && hasDomains && emailSettings?.domains?.map((domain, i) => <DomainWrap refetch={refetch} key={i} data={domain} addAddressPopup={() => { openAddEmailAddressModal({ strictDomain: domain.id }) }} emailAddresses={emailSettings?.emailAddresses?.filter?.(e => e.externalEmailDomainID === domain.id)} delete={() => { deleteEmailDomainWrap(domain.id) }} />)}
                    {!isLoading && !hasDomains && <div>
                        <Button className='w-100' variant='outline-secondary' onClick={openAddDomainModal}>{t("Connect your email domain")}</Button>
                    </div>}
                </Form.Group>
            </Form.Group>
        </Form>)
}


function AsklyDomainWrap(props) {
    const { t } = useTranslation("settings_email")

    return (
        <div className="domain-wrap d-flex align-items-center mb-3 flex-column">
            <div className='d-flex w-100'>
                <div className="author d-flex align-items-center">
                    <div>
                        <div className="domain-name"><span>@</span>{t("supports.askly.me")}</div>
                    </div>
                    <div className="actions ml-auto"></div>
                </div>
                <Button size='sm' variant="outline-success" className="ml-auto" onClick={props.addAddressPopup}><i className="fas fa-paper-plane mr-2 my-auto"></i>{t("Add address")}</Button>
            </div>
            {props.emailAddresses?.length > 0 && <div className='w-100 mt-3'>
                <h3>{t("Sender addresses")}</h3>
                <SendersTable emailAddresses={props.emailAddresses} />
            </div>}
        </div>
    )
}
function DomainWrap(props) {
    const { t } = useTranslation("settings_email")

    const verifiedDomain = props.data.validationDkimStatus === "success" && props.data.validationSpfStatus === "success"
    return (
        <div className="domain-wrap d-flex align-items-center mb-3 flex-column">
            <div className='d-flex w-100'>
                <div className="author d-flex align-items-center">
                    <div>
                        <div className="domain-name"><span>@</span>{props.data.domain}</div>
                    </div>
                    <div className="actions ml-auto"></div>
                </div>
                <Button size='sm' variant="outline-success" className="ml-auto" onClick={props.addAddressPopup}><i className="fas fa-paper-plane mr-2 my-auto"></i>{t("Add address")}</Button>
                <div className="dropdown add-new-team-member ml-3 d-flex align-items-center" style={{ height: 32 }}>
                    <DropdownButton title={<i className="far fa-ellipsis-v"></i>} menuAlign="right">
                        <Dropdown.Item onClick={props.delete}>{t("Remove")}</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
            <div className='w-100 mt-3'>
                <h3>{t("Domain verification records")} <RefreshButton onClick={props.refetch} /> </h3>
                <table className='domain-ver-table w-100'>
                    <thead>
                        <tr>
                            <th>{t("Type")}</th>
                            <th>{t("Name")}<InfoTooltip></InfoTooltip></th>
                            <th>{t("Value")}<InfoTooltip></InfoTooltip></th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>TXT</td>
                            <td>{props.data.dkim_selector_1 + "._domainkey." + props.data.domain}</td>
                            <td><FieldWithCopy value={props.data.dkimRecord} /></td>
                            <td><Badge className='text-white' variant={props.data.validationDkimStatus === "success" ? "success" : "warning"}>{props.data.validationDkimStatus === "success" ? t("Success") : t("Invalid")}</Badge></td>
                        </tr>
                        <tr>
                            <td>TXT</td>
                            <td>{props.data.domain}</td>
                            <td><FieldWithCopy value={props.data.spfRecord} /></td>
                            <td><Badge className='text-white' variant={props.data.validationSpfStatus === "success" ? "success" : "warning"}>{props.data.validationSpfStatus === "success" ? t("Success") : t("Invalid")}</Badge></td>
                        </tr>
                        {false && <tr>
                            <td>CNAME</td>
                            <td>{props.data.domain}</td>
                            <td><FieldWithCopy value={"placeholder"} /></td>
                            <td><Badge className='text-white' variant={props.data.validationSpfStatus === "success" ? "success" : "warning"}>{props.data.validationSpfStatus === "success" ? t("Success") : t("Invalid")}</Badge></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            {props.emailAddresses?.length > 0 && <div className='w-100 mt-3'>
                <h3>{t("Sender addresses")} {!verifiedDomain && <span className='ml-2 warning-msg'>{t("Sending is disabled until verification is complete")} <i class="far fa-exclamation-square"></i></span>}</h3>
                <SendersTable emailAddresses={props.emailAddresses} />
            </div>}
        </div>
    )
}

function SendersTable({ emailAddresses }) {
    const { t } = useTranslation("settings_email")
    const [deleteEmailAddress] = useDeleteEmailAddressMutation()


    const deleteEmailAddressClick = (address) => {
        addModal(<ConfirmModal />, {
            data: {
                title: t("Remove address ?"),
                body: t("Are you sure you want to remove this sending address?"),
                confirmText: t("Delete", { ns: 'common' }),
            },
            onConfirm: () => {
                deleteEmailAddress({ id: address.id }).unwrap().then(() => {
                    addInfoToast("Address deleted")
                }).catch(() => {
                    addErrorToast("Error deleting address")
                })
            }
        })
    }


    const openSignatureModal = (address) => {
        addModal(<EmailSignatureModal />, {
            data: {
                address,
            },
        })
    }

    return (
        <table className='domain-ver-table w-100'>
            <thead>
                <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Email")}</th>
                    {/*<th>Signature</th>*/}
                    <th>{t("More")}</th>
                </tr>
            </thead>
            <tbody>
                {emailAddresses?.map((address, i) => <tr key={i} >
                    <td>{address.name}</td>
                    <td>{address.email}</td>
                    {/*<td>
                        <Form.Check size="sm" type="switch" className="mr-3" id={"doc-switch-" + address.id} checked={false} label={t(address.signatureActive ? "Active" : "Inactive")} onChange={() => { }} />
                    </td>*/}
                    <td>
                        <Dropdown className="mx-2 my-auto" >
                            <Dropdown.Toggle className="" as={CustomPlainToggle}>
                                <i className="px-2 far fa-ellipsis-v"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align={"left"} style={{ fontSize: 12 }}>
                                <Dropdown.Item className="option-item" onSelect={() => { openSignatureModal(address) }}>
                                    <span>{t("Signatures")}</span>
                                </Dropdown.Item>
                                <Dropdown.Item className="option-item" onSelect={() => { deleteEmailAddressClick(address) }}>
                                    <span style={{ color: 'red' }}>{t("Delete")}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>)}
            </tbody>
        </table>
    )
}