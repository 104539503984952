import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { hexToHSL, scheduleToOpenTimes, useWhiteTextForColor } from '../../logic/util';
import './ChatPreview.scss';


export default function ChatPreview({ className, themeColor, logoURL, hideOrgMsg, hasForm, hasFAQ, hasCallUs, bgPattern, openTimes, pii_notification }) {
    let [currentTab, setCurrentTab] = useState("chat")
    let isChatActive = (currentTab === "chat")
    let isFAQActive = (currentTab === "FAQ")
    let isCallUsActive = (currentTab === "callUs")
    let useWhiteColor = useWhiteTextForColor(themeColor || "#27304E")
    let gradientOne = hexToHSL(themeColor, 0, 0, 15)
    let gradientTwo = themeColor
    let backgroundStyle = `linear-gradient(300deg,  ${gradientOne || "#27304E"} -10%,  ${gradientTwo || "#27304E"} 100%)`

    if (bgPattern === "bg-1") {
        backgroundStyle = `url('/images/preview/background-pattern_1.svg') no-repeat,
        linear-gradient(300deg,  ${gradientOne || "#27304E"} -10%,  ${gradientTwo || "#27304E"} 100%)`
    } else if (bgPattern === "bg-2") {
        backgroundStyle = `url('/images/preview/background-pattern_2.svg') no-repeat,
        linear-gradient(300deg,  ${gradientOne || "#27304E"} -10%,  ${gradientTwo || "#27304E"} 100%)`
    } else if (bgPattern === "bg-3") {
        backgroundStyle = `url('/images/preview/background-pattern_3.svg') no-repeat,
    linear-gradient(300deg,  ${gradientOne || "#27304E"} -10%,  ${gradientTwo || "#27304E"} 100%)`
    }

    return (
        <div className={"chat-full-preview " + (useWhiteColor ? "" : "v-dark ") + (className ? className : "")}>
            <div className="widget-top-area">
                <div className="widget-navs" style={{ background: backgroundStyle }}>
                    <div className={"widget-tab-switch " + (isChatActive ? "selected" : "")} style={{ color: isChatActive ? themeColor : null }} onClick={() => { setCurrentTab("chat") }}><i className="fas fa-comment-dots "></i></div>
                    {hasCallUs && <div className={"widget-tab-switch " + (isCallUsActive ? " selected" : "")} style={{ color: isCallUsActive ? themeColor : null }} onClick={() => { setCurrentTab("callUs") }}><i className="fas fa-phone-alt "></i></div>}
                    {hasFAQ && <div className={"widget-tab-switch " + (isFAQActive ? " selected" : "")} style={{ color: isFAQActive ? themeColor : null }} onClick={() => { setCurrentTab("FAQ") }}><i className="fak fa-faq "></i></div>}
                </div>
                <div className="widget-content">
                    {isChatActive && <ChatTab logoURL={logoURL} themeColor={themeColor} hideOrgMsg={hideOrgMsg} openTimes={openTimes} pii_notification={pii_notification} />}
                    {isFAQActive && <FAQTab themeColor={themeColor} />}
                    {isCallUsActive && <CallUsTab themeColor={themeColor} openTimes={openTimes} />}
                </div>
            </div>
        </div>
    )
}


function ChatTab({ logoURL, themeColor, hideOrgMsg, openTimes, pii_notification }) {
    const { t } = useTranslation("settings_widget")
    let openTimesData = scheduleToOpenTimes(openTimes)
    const [showOriginal, setShowOrigina] = useState(false)

    return (
        <div className="chat-tab">
            <div className="chat-header">
                <SupportIcon themeColor={themeColor} pictureUrl={logoURL} />
                <div className="support-details">
                    <span>Support Assistant</span>
                    {openTimesData && openTimesData.openHoursText && <span className="open-hours">{openTimesData.openHoursText}</span>}
                </div>
            </div>
            <div className="chat-area">
                {pii_notification && <div className='pii-notification'><span>{t("Please avoid entering sensitive information in the chat.")}</span></div>}
                <div className="visitor-bubble" style={{ background: themeColor }}>
                    Tere !
                    <div className="written-at ml-auto">15:04</div>
                </div>
                <div className="support-message">
                    <div className="support-bubble">
                        <div className="message">
                            Tere kuidas saan teid aidata?
                            <div className="written-at d-flex">
                                <div className="mr-auto" style={{ cursor: 'pointer', userSelect: 'none', marginRight: 6 }} onClick={() => { setShowOrigina(!showOriginal) }}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.01299 13.4999C8.08446 13.4999 7.22002 13.2598 6.40357 12.8436C4.85068 12.0592 3.60201 10.9066 2.64142 9.44986C2.5454 9.30582 2.4653 9.14574 2.38532 8.98554C2.27325 8.7614 2.27325 8.52122 2.38532 8.29721C2.64142 7.76897 2.99367 7.30465 3.36185 6.85639C4.2263 5.84779 5.21886 4.99938 6.40348 4.40707C7.23596 3.99088 8.11647 3.73465 9.0609 3.75071C9.89338 3.76676 10.6938 3.97485 11.4302 4.32696C13.0311 5.0954 14.2958 6.24804 15.2885 7.70487C15.4166 7.88099 15.5127 8.08908 15.6087 8.28111C15.7368 8.52129 15.7207 8.77738 15.6087 9.01757C15.3845 9.51384 15.0804 9.94609 14.7282 10.3463C13.8637 11.3709 12.8551 12.2034 11.6545 12.8117C10.8381 13.244 9.9576 13.5001 9.01305 13.5001L9.01299 13.4999ZM9.01299 12.1553C10.966 12.1713 12.599 10.5384 12.5351 8.52118C12.4871 6.6322 10.9022 5.04745 8.90103 5.11134C6.99601 5.17539 5.44311 6.76025 5.49118 8.7454C5.53919 10.6024 7.14009 12.1714 9.01299 12.1553Z" fill="#9db6cb" />
                                        <path d="M7.02783 8.63356C7.04388 7.49697 7.92438 6.64844 9.06096 6.64844C10.1335 6.66448 10.9981 7.57696 10.966 8.68157C10.9499 9.75411 10.0214 10.6507 8.88484 10.6026C7.89228 10.5707 7.02783 9.67413 7.02783 8.63356Z" fill="#9db6cb" />
                                    </svg>
                                </div>
                                <span>15:05</span>
                            </div>
                        </div>
                        {showOriginal && <div className="translation">
                            Hello how can i help you ?
                        </div>}
                    </div>
                </div>
                <div className="input-area ">
                    <input type="text" placeholder="Type your text here"></input>
                    <button type="button" className="send-input"></button>
                </div>
            </div>
            <div className="gray-header">
                <span className="powered-by m-auto">Powered by<img src="/images/askly.svg" alt="Askly" ></img></span>
            </div>
        </div>
    )
}


function FormTab({ themeColor }) {
    return (
        <div className="form-tab">
            <div className="inner-container" onSubmit={(e) => { e.preventDefault() }}>
                <div className="d-flex mb-3">
                    <div className="tab-body-icon-wrap" style={{ borderColor: themeColor }}>
                        <i className="fas fa-envelope "></i>
                    </div>
                    <div className="ml-3">
                        <div className="title">Contact Form</div>
                        <div className="sub-title">We will reply to your email</div>
                    </div>
                </div>


                <div className="form-row-container">
                    <div className="form-row">
                        <div className="input-title">Name</div>
                        <input maxLength="200" type="text" />
                    </div>
                    <div className="form-row">
                        <div className="input-title">Email</div>
                        <input maxLength="200" type="email" />
                    </div>
                    <div className="form-row">
                        <div className="input-title">Your Message</div>
                        <textarea maxLength="2500" rows="5" />
                    </div>
                </div>
                <div className="send-btn" style={{ background: themeColor }}>
                    Send
                </div>
            </div>
        </div>
    )
}

function SupportIcon({ themeColor, pictureUrl }) {
    return (
        <div className="support-ico" style={{ color: themeColor }}>
            {!pictureUrl && <i className="fas fa-comment-dots m-auto"></i>}
            {pictureUrl && <img className="support-image" src={pictureUrl} alt="Support" />}
        </div>
    )
}



function FAQTab({ themeColor }) {
    return (
        <div className="faq-tab">
            <div className="inner-container" onSubmit={(e) => { e.preventDefault() }}>
                <div className="d-flex mb-4">
                    <div className="tab-body-icon-wrap" style={{ borderColor: themeColor }}>
                        <i className="fas fa-question "></i>
                    </div>
                    <div className="ml-3">
                        <div className="title">FAQ</div>
                        <div className="sub-title">Still need help? Ask us in live chat</div>
                    </div>
                </div>


                <div className="form-row-container">
                    <Collapsible transitionTime={200} trigger={<QuestionTriggerWrap style={{ color: themeColor }}>Example 1: How accurate is the automatic translation?</QuestionTriggerWrap>} contentInnerClassName="collapse-body-wrap" openedClassName="collapse-open">
                        Our chat is integrated with leading neural machine translation technologies. We only use paid technologies. The translations are highly accurate and conversations with customers can easily be done with the multilingual support.
                    </Collapsible>
                    <Collapsible transitionTime={200} trigger={<QuestionTriggerWrap style={{ color: themeColor }}>Example 2: How long is the free-trial period? What happens then after?</QuestionTriggerWrap>} contentInnerClassName="collapse-body-wrap" openedClassName="collapse-open">
                        Free trial is for 30 days. You can use all the features and serve your customers to evaluate if you like our chat services and it has made your customer experience better. If you decide to continue, please choose a suitable option from our packages. Prices start from 19€ a month.
                    </Collapsible>
                    <Collapsible transitionTime={200} trigger={<QuestionTriggerWrap style={{ color: themeColor }}>Example 3: I did not find a suitable package from your list. Do you offer personalised solutions?</QuestionTriggerWrap>} contentInnerClassName="collapse-body-wrap" openedClassName="collapse-open">
                        Yes, of course. If you did not find a good match from our existing packages, please directly contact our support at support@askly.me and we will find a solution for you.
                    </Collapsible>
                    <Collapsible transitionTime={200} trigger={<QuestionTriggerWrap style={{ color: themeColor }}>Example 4: Can I add more team members to Askly chat?</QuestionTriggerWrap>} contentInnerClassName="collapse-body-wrap" openedClassName="collapse-open">
                        Yes, our chat platform offers all the tools you need to support your customers with your team. You can add as many people you need to your Askly chat platform.
                    </Collapsible>
                </div>
            </div>
        </div>
    )
}

function QuestionTriggerWrap({ children, style }) {
    return (<div className="question-trigger">
        <div>{children}</div><div style={style} className="side-indicator"></div>
    </div>)
}



function CallUsTab({ themeColor, openTimes }) {
    let openTimesData = scheduleToOpenTimes(openTimes)



    return (
        <div className="call-us-tab">
            <div className="inner-container" onSubmit={(e) => { e.preventDefault() }}>
                <div className="d-flex mb-4">
                    <div className="tab-body-icon-wrap" style={{ borderColor: themeColor }}>
                        <i className="fas fa-phone-alt "></i>
                    </div>
                    <div className="ml-3">
                        <div className="title">Contacts</div>
                        {openTimesData && openTimesData.openHoursText && <div className="sub-title">{openTimesData.openHoursText}</div>}
                    </div>
                </div>
                <div className="main-contact-container">
                    <div className="form-row">
                        <i className="fas fa-phone-alt "></i>
                        <div className="contact-container">
                            <div className="contact-link"> +(375) 555 5555</div>
                        </div>
                    </div>
                </div>
                <div className="form-row-container">
                    <div className="form-row">
                        <i className="fab fa-skype "></i>
                        <div className="contact-container">
                            <div className="contact-link">Skype call</div>
                        </div>
                    </div>

                    <div className="form-row">
                        <i className="fab fa-whatsapp "></i>
                        <div className="contact-container">
                            <div className="contact-link">WhatsApp call</div>
                        </div>
                    </div>

                    <div className="form-row">
                        <i className="fab fa-viber "></i>
                        <div className="contact-container">
                            <div className="contact-link">Viber call</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}