import cn from 'classnames';
import { useState } from "react";
import { executePromiseMinTime } from "../../../logic/util";
import { ThinButton } from './Common';
import './RefreshButton.scss';

export default function RefreshButton(props) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = () => {
        setIsLoading(true);
        executePromiseMinTime(Promise.resolve(props.onClick()), 1500).finally(() => {
            setIsLoading(false);
        });

    }

    return (
        <ThinButton className={cn("refresh-btn", { 'loading': isLoading })} onClick={onClick}>
            <i className="icon far fa-sync-alt"></i>
        </ThinButton>
    )
}