import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    Editor,
    Node,
    Element as SlateElement,
    Transforms
} from 'slate';
import { useSlate } from 'slate-react';
import { LinksPopout } from '../EmailEditorLinks';
import useOutsideClick from '../useOutsideClick';
import { Element, Leaf } from './EditorHTML';
import './EditorHTMLToolbar.scss';

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

export const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}


const BlockButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    return (
        <span
            className={className}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <i className={cn("html-editor-area-action", icon, { 'is-open': isActive })}></i>
        </span>
    )
}

const MarkButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)

    return (
        <span
            className={className}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <i className={cn("html-editor-area-action", icon, { 'is-open': isActive })}></i>
        </span>
    )
}



const LinkButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)
    const [show, setShow] = useState(false)
    const [initialText, setInitialText] = useState("")


    const onClick = () => {
        const getText = (node) => { return node.text ? node.text : node.children?.map(child => getText(child)).join("") }

        let initialText = Node.fragment(editor, editor.selection).map(node => getText(node)).join("")
        setInitialText(initialText)
        if (!show) setShow(true)
    }

    return (
        <span
            id="editor-link-button"
            className={className}
            onClick={onClick}
        >
            <i className={cn("html-editor-area-action", icon, { 'is-open': isActive })}></i>
            {show && <LinksPopout editor={editor} initialText={initialText} close={() => { setShow(false) }} />}
        </span>
    )
}

const ImageButton = ({ format, className, icon }) => {
    const editor = useSlate()
    const isActive = isMarkActive(editor, format)
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, () => {
        if (show)
            setShow(false)
    })

    return (
        <span
            ref={wrapperRef}
            className={className}
            onClick={event => { if (!show) setShow(true) }}
        >
            <i className={cn("html-editor-area-action", icon, { 'is-open': isActive })}></i>
            {/*show && <ImagesPopup editor={editor} close={() => { setShow(false) }} />*/}
        </span>
    )
}

export function EditorButtons({ canSendEmail, notesClick, submitClick, files = [], filesChanged }) {

    return (
        <div className='menu-area'>
            {/*<BlockButton format="heading-one" icon="far fa-h1" />*/}
            {/*<BlockButton format="heading-two" icon="far fa-h2" />*/}
            {/*<BlockButton className="mr-3" format="block-quote" icon="far fa-quote-right" />*/}

            <MarkButton format="bold" icon="far fa-bold" />
            <MarkButton format="italic" icon="far fa-italic" />
            <MarkButton format="underline" icon="far fa-underline" />
            {/*<ImageButton format="image" icon="far fa-image" />*/}
            <LinkButton className="ml-3" format="link" icon="far fa-link" />

            {/*<BlockButton format="numbered-list" icon="far fa-list-ol" />*/}
            {/*<BlockButton className="mr-3" format="bulleted-list" icon="far fa-list-ul" />*/}

            {/*<AttachmentManager filesChanged={filesChanged} files={files} /> */}
        </div>
    )
}

export function convertValueToHtml(elems) {
    const asReactObject = (elem) => {
        if (elem.type) {
            return <Element renderEmail={true} element={elem} children={<>{elem.children.map(child => asReactObject(child))}</>} />
        } else {
            return <Leaf renderEmail={true} leaf={elem} children={elem.text} />
        }
    }
    const objs = elems.map(element => (asReactObject(element)));
    return renderToStaticMarkup(objs)
}
