import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { login as apiLogin } from '../../logic/api';
import logo from '../../resources/askly-long.svg';
import backgroundSide from '../../resources/illustration-10.png';
import { onAppLoaded } from "../../state/store";
import { LanguageSwitch } from "../base/minor/LanguageSwitch";
import './SignIn.scss';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

export default function SignIn(props) {
    const { t } = useTranslation("noauth")
    let e = new URLSearchParams(props.location.search).get("e")
    let urlError = ""
    if (e) {
        switch (atob(e)) {
            case "ACCOUNT_EMAIL_TAKEN":
                urlError = t("Email is taken");
                break;
            default:
                urlError = t("Something went wrong", { ns: 'common' });
                break;
        }
    }

    useEffect(() => {
        if (window.fbq)
            window.fbq('track', 'ViewContent');

        // LinkedIn insight tag
        window._linkedin_partner_id = "1482812";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
        (function (l) {
            if (!l) {
                window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                window.lintrk.q = []
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript"; b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
        })(window.lintrk);
    }, [])

    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        general: urlError,
    });



    function validateForm() {
        var validated = true;
        let tmpErrors = {}

        if (email.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { email: t("Email is required") })
            validated = false;
        }

        if (password.length < 1) {
            tmpErrors = Object.assign(tmpErrors, { password: t("Password is required") })
            validated = false;
        }

        setErrors(tmpErrors)
        return validated
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (validateForm()) {
            apiLogin({ email, password }).then(response => {
                onAppLoaded()
                history.push("/chats");
            }).catch(err => {
                if (err.statusCode) {
                    if (err.statusCode === "PWD_WRONG") {
                        setErrors({ password: t("Wrong password") })
                    } else if (err.statusCode === "NO_USER") {
                        setErrors({ email: t("No such user") })
                    } else if (err.statusCode === "EMAIL_UNVERIFIED") {
                        setErrors({ email: t("Email unverified") })
                    } else {
                        setErrors({ general: t("Something went wrong. Try again later.") })
                    }
                } else {
                    setErrors({ general: t("Something went wrong. Try again later.") })
                }
            });
        }
    }

    const rightContent = (<div>
        <div style={{ display: 'flex', alignItems: 'center', height: "heigth:200px", margin: "20px -24px 20px 0px", float: "right" }}>
            <LanguageSwitch className='mr-3' />
            <Link to="/register" className="df-btn auth-switch">{t("Register")}</Link>
        </div>
        <div className="form-wrap">
            <div className="auth-form" style={{ margin: "auto" }}>
                {!isIE &&
                    <div style={{ maxWidth: "485px" }}>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <p className="login-details my-4">{t("Log In")}</p>
                            <p className="general-error mb-3">{errors.general}</p>

                            <div className="mx-auto text-left" style={{ width: "75%" }}>
                                <Form.Group>
                                    <Form.Label>{t("Email address")}</Form.Label>
                                    <Form.Control type="email" placeholder={t("Enter email")} onChange={e => setEmail(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.email}</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{t("Password")}</Form.Label>
                                    <Form.Control type="password" placeholder={t("Password")} onChange={e => setPassword(e.target.value)} />
                                    <Form.Text className="error-text-sm">{errors.password}</Form.Text>
                                </Form.Group>
                            </div>
                            <p><Link to="/forgot" className="forgot-password" style={{ marginTop: 6 }}>{t("Forgot password ?")}</Link></p>
                            <button className="df-btn" style={{ marginTop: "22px" }}>{t("Login")}</button>
                        </form>
                        <div className="sidelines"><span>{t("OR")}</span></div>
                        <div className="social-grid">
                            <a href="/api/login/google" className="df-btn df-btn-inverse google">{t("Google")}</a>
                            <a href="/api/login/facebook" className="df-btn df-btn-inverse facebook">{t("Facebook")}</a>
                            <a href="/api/login/apple" className="df-btn df-btn-inverse apple ">{t("Apple")}</a>
                        </div>
                        <p className="tos-agree-text">
                            <Trans t={t} defaults="By clicking Log In, I confirm that I have read and agree to the <terms>Askly Terms of Service</terms>, <privacy>Privacy Policy</privacy>, and to receive emails and updates."
                                components={{
                                    terms: <a href="https://askly.me/en/terms-of-service" />,
                                    privacy: <a href="https://askly.me/en/privacy-policy" />,
                                }} />
                        </p>
                    </div>}
                {isIE &&
                    <div style={{ maxWidth: "380px" }}>
                        <h1 className="login-title">{t("Sorry !")}</h1>
                        <p className="login-details mb-5">{t("This browser is not supported.")}</p>
                    </div>
                }
            </div>
        </div>
    </div>)

    const leftContent = (
        <div className="left-body">
            <div className="text-wrap">
                <div className="left-txt-main">
                    <Trans t={t}
                        defaults="<b>NEW!</b><br/> AI Assistant and<br/> E-mail integration"
                        components={{ b: <b /> }}
                    />
                </div>
                <img className="left-illustration mt-auto" src={backgroundSide} alt="bg-askly" />
                <div className="left-txt-sub mt-0 mb-5 pb-0">
                    <Trans t={t}
                        defaults="Let’s make it happen!<br></br>Save hours, win more customers."
                    />
                </div>
            </div>
        </div>
    )

    return <AuthWrap
        leftContent={leftContent}
        rightContent={rightContent}
    />
}


export function AuthWrap({ leftContent, rightContent }) {
    return (
        <div className="auth-wrap">
            <div className="auth-view row" style={{ width: 980, minHeight: 710, margin: 'auto', background: '#fff', boxShadow: '0 4px 14px rgba(136,151,183,0.35)', borderRadius: 4, overflow: 'hidden' }}>
                <div className="col d-flex flex-column" style={{ background: "linear-gradient(135deg, rgba(242,226,246,1) 0%, rgba(248,228,214,1) 100%)" }}>
                    <div className="left-content">
                        <div className="top-logo" ><a href="https://askly.me"><img src={logo} alt="Askly" /></a></div>
                        {leftContent}
                    </div>
                </div>
                <div className="col-6 px-5">
                    {rightContent}
                </div>
            </div >
        </div>);
}