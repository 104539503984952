var focused = true;

const tabNotifications = {
    Vars: {
        OriginalIcon: document.getElementById('favicon').href,
        NotificationAudio: new Audio("notification.mp3"),
        ActiveAudio: null,
        UserPref: {
            ncVol: "0.6",
            ncX: "1",
            ncAudio: null,
            nmVol: "0.6",
            nmX: "1",
            nmAudio: new Audio("/notifications/sound_1.mp3"),
        },
        Active: false,
        OriginalTitle: document.title,
        Interval: null,
        Counter: 0,
    },

    SetUserPref: function (settings) {
        if (settings && settings.notificationsPref) {
            this.Vars.UserPref = { ...this.Vars.UserPref, ...settings.notificationsPref }
            if (settings.notificationsPref.ncS) {
                if (settings.notificationsPref.ncS === 'none') {
                    this.Vars.UserPref.ncAudio = null
                } else {
                    this.Vars.UserPref.ncAudio = new Audio("/notifications/" + settings.notificationsPref.ncS + ".mp3")
                }
            }

            if (settings.notificationsPref.nmS) {
                if (settings.notificationsPref.nmS === 'none') {
                    this.Vars.UserPref.nmAudio = null
                } else {
                    this.Vars.UserPref.nmAudio = new Audio("/notifications/" + settings.notificationsPref.nmS + ".mp3")
                }
            }
        }
    },

    NewMsgNotification: function (message, onclick) {
        if (!focused) {
            try {
                this.UpdateTabTitle();
                this.DesktopNotification(message, onclick);

                if (this.Vars.UserPref.nmAudio) {
                    this.LoopAudio(this.Vars.UserPref.nmAudio, this.Vars.UserPref.nmX, this.Vars.UserPref.nmVol)
                }
            } catch (error) {
                console.log("Unable to play notification", error)
            }
        }
    },

    AssistantUnassign: function (onclick) {
        try {
            this.UpdateTabTitle();
            this.DesktopNotification("Askly assistant needs help", onclick);

            if (this.Vars.UserPref.ncAudio) {
                this.LoopAudio(this.Vars.UserPref.ncAudio, this.Vars.UserPref.ncX, this.Vars.UserPref.ncVol)
            }
        } catch (error) {
            console.log("Unable to play notification", error)
        }
    },

    NewUser: function () {
        if (!focused) {
            if (this.Vars.UserPref.ncAudio) {
                this.LoopAudio(this.Vars.UserPref.ncAudio, this.Vars.UserPref.ncX, this.Vars.UserPref.ncVol)
            }
        }
    },

    DesktopNotification: function (message, onclick) {
        if (message && ("Notification" in window) && Notification.permission === "granted") {
            let notification = new Notification("New message", {
                body: message,
                dir: "ltr"
            });
            notification.onclick = (function () {
                if (onclick) onclick();
                window.focus();
                this.close();
            });
        }
    },

    UpdateTabTitle: function () {
        document.getElementById('favicon').href = "/logo_notification.png";
        this.Vars.Active = true;
        if (this.Vars.Counter === 0)
            this.Vars.OriginalTitle = document.title
        this.Vars.Counter += 1;
        document.title = "(" + this.Vars.Counter + ") " + this.Vars.OriginalTitle;
    },

    Off: function () {
        this.StopAudio();
        clearInterval(this.Vars.Interval);
        document.title = this.Vars.OriginalTitle;
        this.Vars.Active = false;
        this.Vars.Counter = 0;
        document.getElementById('favicon').href = this.Vars.OriginalIcon;
    },

    LoopAudio: function (audio, times, vol) {
        if (this.Vars.ActiveAudio != null)
            return

        if (times === 'forever')
            times = "99999"

        let remaining = parseInt(times) || 1
        let volume = parseFloat(vol) || 0.6
        this.Vars.ActiveAudio = audio;
        audio.volume = volume

        audio.play().catch(e => { console.error(e) });
        audio.onended = (function () {
            remaining--
            if (remaining > 0) {
                audio.currentTime = 0;
                audio.play().catch(e => { console.error(e) });
            } else {
                audio.onended = function () { }
                this.Vars.ActiveAudio = null
            }
        }).bind(this)
    },

    StopAudio: function () {
        if (this.Vars.ActiveAudio) {
            this.Vars.ActiveAudio.pause();
            this.Vars.ActiveAudio.currentTime = 0;
            this.Vars.ActiveAudio.onended = function () { }
            this.Vars.ActiveAudio = null;
        }
    },
}

window.addEventListener("focus", function () {
    focused = true;
    tabNotifications.Off();
});

window.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
        focused = false;
    } else if (document.visibilityState === 'visible') {
        tabNotifications.Off();
    }
});

window.onblur = function () {
    focused = false;
};

export default tabNotifications;