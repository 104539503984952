import { OverlayTrigger } from 'react-bootstrap'
import Popover from 'react-bootstrap/Popover'

export function InfoTooltip({ children, iconClass, className }) {

    return (
        <OverlayTrigger
            transition={false}
            placement="auto"
            overlay={<Popover><div className="px-3 py-2 tw-popover-body" >{children}</div></Popover>}
        >
            {(props) => (
                <div {...props} className={"tw-popover-trigger " + (className ? className : "")} style={{ display: "inline" }}><i className={"far fa-info-circle " + (iconClass ? iconClass : "ml-2")}></i></div>)}
        </OverlayTrigger>
    )
}

export function WarningTooltip({ children, iconClass, className }) {
    return (
        <OverlayTrigger
            transition={false}
            placement="auto"
            overlay={<Popover><div className="px-3 py-2 tw-popover-body" >{children}</div></Popover>}
        >
            {(props) => (
                <div {...props} className={"tw-popover-trigger " + (className ? className : "")} style={{ display: "inline" }}><i className={"fas fa-exclamation-triangle " + (iconClass ? iconClass : "ml-2")}></i></div>)}
        </OverlayTrigger>
    )
}