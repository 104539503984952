import { default as classNames, default as cn } from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch } from 'react-redux';
import { integrationsCreatePipedriveLead, sendMessageCustom } from '../../logic/api';
import { b64ToPastelColor, debounce, getLanguage } from '../../logic/util';
import wsEventManager from '../../logic/wsEventmanager';
import emailIcon from '../../resources/email_icon.svg';
import instagramIcon from '../../resources/insta_icon_240x240.png';
import pipedriveLogo from '../../resources/integrations/pipedrive-icon.svg';
import messengerIcon from '../../resources/messenger.svg';
import { archiveChat, blockChat, openChat, updateChatLanguages, updateCurrentVisitorData } from '../../state/activeChat';
import { addModal } from '../modals/ModalManager';
import VisitedPagesModal from '../modals/VisitedPagesModal';
import "./ChatDetails.scss";
import { addErrorToast, addInfoToast } from './ToastManager';
import { Separator, ThinButton } from './minor/Common';
import EditableDetail from './minor/EditableDetail';
import EllipsisLoading from './minor/EllipsisLoading';
import Tag, { AddTag } from './minor/Tag';

class ChatDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visitorNotesPending: false,
            visitorNotes: "",
            isSendingEmailMessage: false,
            isSendingPhoneMessage: false,
        }

        this.openVisitedPages = this.openVisitedPages.bind(this);
        this.reopenChat = this.reopenChat.bind(this);
        this.archiveChat = this.archiveChat.bind(this);
        this.blockChat = this.blockChat.bind(this);
        this.nameChanger = this.nameChanger.bind(this)
        this.sendRequestEmailMessage = this.sendRequestEmailMessage.bind(this);
        this.sendRequestPhoneMessage = this.sendRequestPhoneMessage.bind(this);
        this.changeChatLanguages = this.changeChatLanguages.bind(this);
        this.changeSupportLanguages = this.changeSupportLanguages.bind(this);
        this.saveVisitorNotes = debounce(((e) => {
            const { t } = this.props
            wsEventManager.sendEventWithCB({ eventType: "VISITOR:NOTES", notes: { contactID: this.props.activeChat.activeContactID, notes: e.target.value } }).then(result => {
                // TODO
            }).catch((err) => {
                addErrorToast(t("Unable to save notes"))
            }).finally(() => {
                this.setState({ visitorNotesPending: false })
            })
        }), 800);
    }

    componentDidMount() {
        this.setState({ visitorNotes: (this.props.activeChat.contact && this.props.activeChat.contact.notes) || "" })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({ visitorNotes: (this.props.activeChat.contact && this.props.activeChat.contact.notes) || "" })
        }
    }

    archiveChat() {
        this.props.dispatch(archiveChat(this.props.activeChat.activeContactID))
    }

    nameChanger(newValue) {
        if (this.props.activeChat.contact?.name === newValue) {
            return
        }

        const { t } = this.props
        let event = {
            eventType: "VISITOR:NAME",
            visitorEvent: {
                contactID: this.props.activeChat.activeContactID,
                name: newValue
            }
        }

        wsEventManager.sendEventWithCB(event).then(() => {
            this.props.dispatch({ type: event.eventType, payload: event.visitorEvent })
            addInfoToast(t("Name saved"))
        }).catch((err) => {
            console.log(err)
            addErrorToast(t("Unable to save name"))
        })
    }

    blockChat() {
        this.props.dispatch(blockChat(this.props.activeChat.activeContactID, this.props.activeChat.contact?.isBlocked))
    }

    reopenChat() {
        this.props.dispatch(openChat(this.props.activeChat.activeContactID))
    }

    sendRequestEmailMessage() {
        const { t } = this.props

        this.setState({ isSendingEmailMessage: true }, () => {
            sendMessageCustom({ contactID: this.props.activeChat.activeContactID, type: "INPUT:EMAIL" }).catch(e => {
                addErrorToast(t("Unable to send the message"))
            }).finally(() => {
                this.setState({ isSendingEmailMessage: false })
            })
        })
    }

    sendRequestPhoneMessage() {
        const { t } = this.props

        this.setState({ isSendingPhoneMessage: true }, () => {
            sendMessageCustom({ contactID: this.props.activeChat.activeContactID, type: "INPUT:PHONE_NR" }).catch(e => {
                addErrorToast(t("Unable to send the message"))
            }).finally(() => {
                this.setState({ isSendingPhoneMessage: false })
            })
        })
    }

    changeChatLanguages(newLang) {
        let langFull = getLanguage(newLang, "eng")
        this.props.dispatch(updateChatLanguages({
            contactID: this.props.activeChat.activeContactID,
            visitorLang: langFull.iso,
        }))
    }

    changeSupportLanguages(newLang) {
        let langFull = getLanguage(newLang, "eng")
        this.props.dispatch(updateChatLanguages({
            contactID: this.props.activeChat.activeContactID,
            supportLang: langFull.iso,
        }))
    }

    openVisitedPages() {
        addModal(<VisitedPagesModal />, {
            data: {
                locations: this.props.activeChat?.contact?.locations,
            },
            onConfirm: () => { },
            onCancel: () => { }
        })
    }

    render() {
        const { t } = this.props
        let visitor = this.props.activeChat.contact;
        let isFB = this.props.activeChat.contact.sourceType === "FB"
        let isINSTA = this.props.activeChat.contact.sourceType === "INSTAGRAM"
        let isEmail = this.props.activeChat.contact.sourceType === "EMAIL"
        let isWeb = this.props.activeChat.contact.sourceType === "WEB"
        let isFBorInsta = isFB || isINSTA
        let fbPage;
        if (isFBorInsta) {
            fbPage = this.props.user.siteDetails.facebookChannel?.find(p => p.pageID === visitor.channelID)
        }

        return (
            <div style={{ flexShrink: 0, transition: "width 2s", width: 300, height: "100%", overflow: "auto", borderLeft: "1px solid #DAE1EF", display: 'flex', flexDirection: 'column' }} >
                <ChatIntegrationSwitch user={this.props.user} visitor={visitor} contactID={this.props.activeChat.activeContactID}>
                    <div className="chat-details d-flex" >
                        <div className="user-details">
                            <div style={{ display: "flex" }}>
                                <div className="visitor-icon" style={{ background: b64ToPastelColor(visitor.searchID) }}>
                                    <span className="visitor-letter">{visitor.searchID ? visitor.searchID[0] : ""}</span>
                                    {this.props.activeChat.contact.isConnected && <div className="is-online"></div>}
                                    {isFB && <img className="fb-icon" src={messengerIcon} alt="" />}
                                    {isINSTA && <img className="insta-icon" src={instagramIcon} alt="" />}
                                    {isEmail && <img className="insta-icon" src={emailIcon} alt="" />}
                                </div>
                                <div className='d-flex flex-grow-1 flex-column overflow-hidden'>
                                    {visitor?.name && <div>
                                        <div className="visitor-name">{visitor?.name}</div>
                                    </div>}
                                    <div className='d-flex'>
                                        <div className={classNames("visitor-name flex-grow-1", { 'secondary': visitor?.name })}>#{visitor.searchID}</div>
                                        {isWeb && this.props.activeChat.contact.isConnected && <span className="ml-auto connection-status status-live">{("Online")}</span>}
                                        {isWeb && !this.props.activeChat.contact.isConnected && <span className="ml-auto connection-status">{t("Offline")}</span>}
                                    </div>
                                </div>

                            </div>
                            {isWeb && <div className="location d-flex">
                                {visitor?.additionalInfo?.ipLocation && <div> <i className="far fa-map-marker-alt"></i> {visitor.additionalInfo.ipLocation}</div>}
                                <div className={classNames('ml-auto block-visitor', { "is-blocked": visitor?.isBlocked })} onClick={this.blockChat}><i className="fas fa-ban"></i></div>
                            </div>}
                        </div>
                        <Separator className='full-width'>{t("Customer information")}</Separator>
                        <div className="visitor-info mb-2">
                            <p className="title">{t("Customer tags")}</p>
                            <div className="mt-1">
                                {
                                    visitor?.tags?.map((tagging, i) => (<Tag key={i} allowDelete={true} tag={tagging.tag} contactID={tagging.taggedContactID} />))
                                }
                                <AddTag className="mt-1" contactID={this.props.activeChat.activeContactID} allTags={this.props.user.siteDetails.allTags || []} />
                            </div>
                        </div>
                        {isFB && <div className="visitor-info mb-2">
                            <p className="title">{t("Facebook page")}</p>
                            {fbPage ? <p className="details">{fbPage.pageName}</p> : <p className="details text-danger">{t("Page unaccessable")}</p>}
                        </div>}
                        {isINSTA && <div className="visitor-info mb-2">
                            <p className="title">{t("Instagram page")}</p>
                            {fbPage ? <p className="details">{fbPage.instaPageName}</p> : <p className="details text-danger">{t("Page unaccessable")}</p>}
                        </div>}
                        <div className="visitor-info mb-2">
                            <p className="title">
                                {t("Customer name")}
                            </p>
                            <EditableDetail key={visitor?.name} placeHolder={t("Add name")} baseValue={visitor?.name} onChange={this.nameChanger} />
                        </div>
                        {visitor?.email &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Customer email")}</p>
                                <p className="details">{visitor.email}</p>
                            </div>}
                        {!visitor?.email && isWeb &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Customer email")}</p>
                                <div className="details">
                                    <ThinButton
                                        disabled={this.state.isSendingEmailMessage}
                                        onClick={this.sendRequestEmailMessage}>{t("Request email")}</ThinButton>
                                </div>
                            </div>}
                        {!visitor?.phoneNumber && isWeb &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Customer phone")}</p>
                                <div className="details">
                                    <ThinButton
                                        disabled={this.state.isSendingPhoneMessage}
                                        onClick={this.sendRequestPhoneMessage}>{t("Request phone number")}</ThinButton></div>
                            </div>}
                        {visitor?.phoneNumber &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Customer phone number")}</p>
                                <p className="details">{visitor.phoneNumber}</p>
                            </div>}
                        {visitor?.locations?.length > 0 &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Visited pages")}</p>
                                <p className="details visited-url">
                                    <a href={visitor?.locations[0]?.pageURL} target="_blank" rel="noreferrer">{visitor?.locations[0]?.pageURL}</a>
                                </p>
                                <p className="more-details" onClick={this.openVisitedPages}>{t("See more ...")}</p>
                            </div>}

                        {visitor?.additionalInfo?.os &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Operating system")}</p>
                                <p className="details">{visitor.additionalInfo.os}</p>
                            </div>}
                        {visitor?.additionalInfo?.browser &&
                            <div className="visitor-info mb-2">
                                <p className="title">{t("Browser")}</p>
                                <p className="details">{visitor.additionalInfo.browser}</p>
                            </div>}
                        <Separator className='full-width'>{t("Extra")}</Separator>
                        <div className="visitor-info mb-2">
                            <p className="title">{t("Notes")}{this.state.visitorNotesPending && <div className='saving ml-auto'>{t("Saving")}<EllipsisLoading /></div>}</p>
                            <textarea value={this.state.visitorNotes} onChange={(e) => { this.saveVisitorNotes(e); this.setState({ visitorNotes: e.target.value, visitorNotesPending: true }) }} maxLength="1000">
                            </textarea>
                        </div>
                    </div>
                </ChatIntegrationSwitch >
            </div >
        )
    }
}

const mapStateToProps = state => ({ user: state.user, activeChat: state.activeChat });
export default withTranslation("chat")(connect(mapStateToProps)(ChatDetails));

function IntegrationSwitch({ setActivePage, activePage, integration }) {
    return (
        <div className={cn("int-switch", { 'active': activePage === integration })} onClick={() => { setActivePage((activePage === integration) ? '' : integration) }}>
            <img style={{ height: '100%', width: '100%' }} src={pipedriveLogo} alt="Pipedriv Logo" />
        </div>)
}

function ChatIntegrationSwitch(params) {
    const { t } = useTranslation("integrations")
    const [activePage, setActivePage] = useState("")
    const hasAnyIntegrations = params.user?.siteDetails?.integrationNames?.length || params.visitor?.integrations;

    return (<div className='int-wrap'>
        {hasAnyIntegrations && <div className='int-switch-wrap'>
            <div className='mr-auto'>
                {activePage !== "" && <div className='int-switch-back' onClick={() => { setActivePage("") }}> {t("Back", { ns: 'common' })}</div>}
            </div>
            <IntegrationSwitch setActivePage={setActivePage} activePage={activePage} integration="pd" />
        </div>}
        {activePage === "" && params.children}
        {activePage === "pd" && <PipeDrive visitor={params.visitor} contactID={params.contactID} />}
    </div >)
}

function PipeDrive({ visitor, contactID }) {
    const { t } = useTranslation("integrations")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        personName: visitor?.name || "",
        personEmail: visitor?.email || "",
        personPhone: visitor?.phoneNumber || "",
        leadName: (visitor?.name ? "Askly | " + visitor?.name : ""),
    })

    useEffect(() => {
        setValues({
            personName: visitor?.name || "",
            personEmail: visitor?.email || "",
            personPhone: visitor?.phoneNumber || "",
            leadName: (visitor?.name ? "Askly | " + visitor?.name : ""),
        })
    }, [visitor])

    const createLead = () => {
        setLoading(true)
        integrationsCreatePipedriveLead({
            ...values,
            contactID,
        }).then((results) => {
            dispatch(updateCurrentVisitorData(contactID, { integrations: { pipedrive: results } }))
        }).catch(() => {
            addErrorToast(t("Could not create a lead"))
        }).finally(() => {
            setLoading(false)
        })
    }

    const setVal = (fieldName) => (newVal) => {
        setValues({ ...values, [fieldName]: newVal })
    }

    const leadURL = (leadID) => {
        return `https://app.pipedrive.com/leads/inbox/${leadID}`
    }

    const personURL = (personID) => {
        return `https://app.pipedrive.com/person/${personID}`
    }

    // Check for existing matches
    // Check created lead

    if (visitor?.integrations?.pipedrive) {
        const pd = visitor?.integrations?.pipedrive
        return <div className='chat-details d-flex' style={{ padding: 16, flexDirection: 'column' }}>
            <Separator className='full-width'>{t("Person information")}</Separator>
            <div className="visitor-info mb-2">
                <p className="title">{t("Person name")}</p>
                <a className="details"
                    href={personURL(pd.personID)}
                    target="_blank" rel="noreferrer">{pd.personName}</a>
            </div>
            <div className="visitor-info mb-2">
                <p className="title">{t("Lead name")}</p>
                <a className="details"
                    href={leadURL(pd.leadID)}
                    target="_blank" rel="noreferrer">{pd.leadName}</a>
            </div>
        </div>
    }

    return <div className='d-flex' style={{ padding: 16, flexDirection: 'column' }}>
        <Separator className='full-width'>{t("Person information")}</Separator>
        <Form.Group className={" search-dropdown-wrap mb-1"}>
            <Form.Label className='mb-0'>{t("Person name") + "*"}</Form.Label>
            <EditableDetail placeHolder={t("Add name")} baseValue={values?.personName} onChange={setVal("personName")} />
        </Form.Group>
        <Form.Group className={" search-dropdown-wrap mb-1"}>
            <Form.Label className='mb-0'>{t("Person email")}</Form.Label>
            <EditableDetail placeHolder={t("Add email")} baseValue={values?.personEmail} onChange={setVal("personEmail")} />
        </Form.Group>
        <Form.Group className={" search-dropdown-wrap mb-1"}>
            <Form.Label className='mb-0'>{t("Person phone")}</Form.Label>
            <EditableDetail placeHolder={t("Add phone")} baseValue={values?.personPhone} onChange={setVal("personPhone")} />
        </Form.Group>
        <Separator className='full-width'>{t("Lead")}</Separator>
        <Form.Group className={" search-dropdown-wrap mb-1"}>
            <Form.Label className='mb-0'>{t("Lead name") + "*"}</Form.Label>
            <EditableDetail placeHolder={t("Add name")} baseValue={values?.leadName} onChange={setVal("leadName")} />
        </Form.Group>
        <Button className='mt-4 ml-auto' disabled={(!values?.personName || !values?.leadName) || loading} variant='success' type="button" onClick={createLead}>
            {!loading && <span className="confirm-text">{t("Create new lead")}</span>}
            {loading && <div className="loader">{t("Loading...", { ns: 'common' })}</div>}
        </Button>
    </div >
}