import cn from "classnames";
import {
    addDays, addMonths, endOfDay, endOfMonth, endOfWeek, isSameDay, startOfDay, startOfMonth, startOfWeek
} from 'date-fns';
import { enUS, et, pl } from 'date-fns/locale';
import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from "react-i18next";
import spacetime from "spacetime";
import './PeriodPicker.scss';

export const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfAllTime: null,
    endOfAllTime: addDays(new Date(), 1),
};

const localization = {
    'et': et,
    'en': enUS,
    'pl': pl,
}

export default function PeriodPicker({ className, onSelect, disabled }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const { t, i18n } = useTranslation('analytics');
    const defaultStaticRanges = createStaticRanges([
        {
            label: t('This Week'),
            range: () => ({
                startDate: defineds.startOfWeek,
                endDate: defineds.endOfWeek,
            }),

        },
        {
            label: t('Last Week'),
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek,
            }),
        },
        {
            label: t('This Month'),
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth,
            }),
        },
        {
            label: t('Last Month'),
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth,
            }),
        },
        {
            label: t('All time'),
            range: () => ({
                startDate: defineds.startOfAllTime,
                endDate: defineds.endOfAllTime,
            }),
            isSelected(range) {
                return (
                    range.startDate === null &&
                    isSameDay(range.endDate, defineds.endOfAllTime)
                );
            },
        },
    ])

    const [state, setState] = useState({
        selection: {
            ...defaultStaticRanges[3].range(),
            key: 'selection',
        },
    });



    function getSelectionLabel(params) {
        const knownRange = defaultStaticRanges.find((x) => x.isSelected(state.selection));

        if (knownRange) {
            return knownRange.label
        } else {
            return t("Custom range")
        }
    }

    return (<div className={cn('periodpicker', className)}>
        <Dropdown className="ml-auto"
            alignRight
            onSelect={() => { }}
            show={showDropdown}
            onToggle={setShowDropdown}>
            <Dropdown.Toggle disabled={disabled}  >
                {getSelectionLabel()}
            </Dropdown.Toggle>
            <Dropdown.Menu   >
                <DateRangePicker
                    months={2}
                    color="#9686f2"
                    rangeColors={["#9686f2"]}
                    maxDate={new Date()}
                    editableDateInputs={true}
                    dateDisplayFormat="MMM d yyyy"
                    weekdayDisplayFormat="EEEEE"
                    direction="horizontal"
                    locale={localization[i18n.language]}
                    onChange={(item) => {
                        const knownRange = defaultStaticRanges.find((x) => x.isSelected(item.selection));
                        if (knownRange) {
                            onSelect(item.selection.startDate, item.selection.endDate)
                            setShowDropdown(false)
                        }

                        if (spacetime(item.selection.endDate).isSame(spacetime(item.selection.startDate), 'day')) {
                            item.selection.endDate = spacetime(item.selection.endDate).endOf('day').toLocalDate()
                        }

                        setState({ ...state, ...item })
                    }}
                    staticRanges={defaultStaticRanges}
                    inputRanges={[]}
                    scroll={{ enabled: false }}
                    ranges={[state.selection]}
                />
                <Button
                    className="apply-range"
                    onClick={() => {
                        setShowDropdown(false);
                        onSelect(state.selection.startDate, state.selection.endDate)
                    }}>{t("Apply")}</Button>
            </Dropdown.Menu>
        </Dropdown>
    </div>)
} 