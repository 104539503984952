import React from 'react'

export let addModal;
export let clearModals;


export default class ModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPopup: null,
            secondaryPopup: null,
        }

        this.removeCurrentPopup = this.removeCurrentPopup.bind(this)
        this.removeSecondaryPopup = this.removeSecondaryPopup.bind(this)

        addModal = (modal, modalConfig, isSecondary) => {
            let config = { show: true, isSecondary, ...modalConfig }
            if (!modalConfig?.locked) {
                if (isSecondary) {
                    config.onClose = this.removeSecondaryPopup
                } else {
                    config.onClose = this.removeCurrentPopup
                }
            } else {
                config.onClose = () => { }
            }

            if (isSecondary) {
                this.setState({
                    secondaryPopup: React.cloneElement(modal, config)
                });
            } else {
                this.setState({
                    currentPopup: React.cloneElement(modal, config)
                });
            }

        }

        clearModals = () => {
            this.setState({ currentPopup: null, secondaryPopup: null })
        }
    }

    removeCurrentPopup() {
        this.setState({ currentPopup: null, secondaryPopup: null })
    }

    removeSecondaryPopup() {
        this.setState({ secondaryPopup: null })
    }

    render() {
        return <>
            {this.state.currentPopup}
            {this.state.secondaryPopup}
        </>
    }
}