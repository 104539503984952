
import React from 'react';
import { withTranslation } from "react-i18next";
import { openTour } from '../base/TourManager';
import DefaultLayout from './DefaultLayout';
import './DefaultPage.scss';
import './GuidePage.scss';

class GuidePage extends React.Component {

    render() {
        const { t, i18n } = this.props;
        let url = "https://www.askly.me/en/tutorials-embed"

        if (i18n.language === "et")
            url = "https://www.askly.me/et/juhendid-embed"
        if (i18n.language === "pl")
            url = "https://www.askly.me/pl/tutorial-embed"

        return (
            <DefaultLayout match={this.props.match}>
                <div className="content-card main-card guide-page flex-column">
                    <div className="tour-btn" >{t("How chat admin panel works?")}<button className="ml-2" onClick={openTour}>{t("Take a tour")}</button></div>
                    <iframe title="Askly Tutorials" src={url}></iframe>
                </div>
            </DefaultLayout>)
    }
}

export default withTranslation("common")(GuidePage);
