import { useForm } from '@mantine/form'
import cn from 'classnames'
import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useUpdateEmailAddressSignatureMutation } from '../../state/api'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import EditorHTML, { HTMLParser, getEmptyInitialValue } from '../base/minor/EditorHTML'
import { convertValueToHtml } from '../base/minor/EditorHTMLToolbar'
import './EmailSignatureModal.scss'
import './Modal.scss'


export default function EmailSignatureModal(props) {
    const [updateSignature, { isLoading: isUpdating }] = useUpdateEmailAddressSignatureMutation()
    const { t } = useTranslation('chat')
    const [initialValue, setInitialValue] = React.useState(getEmptyInitialValue())
    const form = useForm({
        initialValues: {
            editorState: [],
        },
    });

    useEffect(() => {
        if (props.data.address.signature) {
            setInitialValue(HTMLParser(props.data.address.signature))
        }
    }, [])

    const confirmClick = () => {
        updateSignature({ emailAddressID: props.data.address.id, signature: convertValueToHtml(form.values.editorState) }).unwrap().then(() => {
            addInfoToast(t("Email signature updated"))
            props.onClose()
        }).catch(() => {
            addErrorToast(t("Failed to update email signature"))
        })
    }

    const cancelClick = () => {
        props.onClose()
    }

    let data = props.data || {}

    return (
        <Modal className={cn("tw-modal email-signature-modal", { 'secondary': props.isSecondary })} centered show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title mt-2">Signature</h5>
            <p className="footnote">
                <span style={{ fontWeight: 'bold' }}>{data.address.name}</span> <span>{data.address.email}</span>
            </p>
            <div className='px-3'>
                <EditorHTML initialValue={initialValue} onChange={value => { form.setFieldValue('editorState', value) }} />
            </div>
            <div className="mt-4 mb-2 mx-3 d-flex">
                <div className="ml-auto"></div>
                <Button disabled={isUpdating} className={cn({ "mr-3": !data.hideConfirm })} variant="outline-primary" onClick={cancelClick}>
                    {data.cancelText ? data.cancelText : t("Cancel")}
                </Button>
                <Button disabled={isUpdating} variant="primary" onClick={confirmClick}>
                    {!isUpdating && <span className="confirm-text">{data.confirmText ? data.confirmText : t("Save")}</span>}
                    {isUpdating && <div className="loader">{t("Saveing...")}</div>}
                </Button>
            </div>
        </Modal>
    );
}

