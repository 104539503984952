
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './Base.scss'

export function DefaultSettingsWrap(params) {
    const { t } = useTranslation("common")
    return (<div className="flex-grow-1 settings-container">
        <div className='title-row'>
            <div className='d-flex'>
                <div className='title'>{params.subPageTitle}</div>
                <div className='title-actions'>
                    {params.buttons}
                    {params.canSave && <Button variant='success' type="submit" form="current-settings-form" className="float-right">{t("Save")}</Button>}
                </div>
            </div>
            <div className='sub-option-wrap d-flex'>
                {params.secondRow?.map?.(opt => <div key={opt?.value} onClick={() => { params.optionClick?.(opt?.value) }} className={classNames('sub-option', { errors: opt?.hasError, active: (params.activeSubOption === opt?.value) })}>{opt?.name}</div>)}
            </div>
        </div>
        <div className='settings-box'>
            {params.children}
        </div>


    </div>)
}