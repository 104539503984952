import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { sendDeveloperGuide } from '../../logic/api';
import { isEmail } from '../../logic/util';
import { addErrorToast, addInfoToast } from '../base/ToastManager';
import './GuideModal.scss';
import './Modal.scss';


export default function SendToDeveloperModal(props) {
    const { t } = useTranslation("modal_send_to_dev")
    const [isSending, setIsSending] = useState(false);
    const [email, setEmail] = useState("");
    const [text, setText] = useState(t("Hey. We are starting to use Askly chat on our website. Please help me with adding the chat script to our websites <head> tag."));

    function sendEmail() {
        if (!email) {
            addErrorToast(t("Valid email required"))
            return
        }

        const emails = email.split(",");

        for (let index = 0; index < emails.length; index++) {
            let singleEmail = emails[index];
            singleEmail = singleEmail.trim()

            if (!singleEmail || !isEmail(singleEmail)) {
                addErrorToast(t("Valid email required"))
                return
            }
        }


        if (text && text.length > 500) {
            addErrorToast(t("Text too long"))
            return
        }

        setIsSending(true)
        sendDeveloperGuide(email, text).then(() => {
            addInfoToast(t("Email sent"))
            props.onClose()
        }).catch(e => {
            addErrorToast(t("Something went wrong", { ns: 'common' }))
            setIsSending(false)
        })
    }

    return (
        <Modal className="tw-modal guide-modal" centered show={props.show} onHide={props.onClose} animation={false}>

            <h5 className="modal-title my-2">{t("Send email to developer")}</h5>
            <p className="footnote">{t("Developer will be sent your site script with instructions how to set it up on your site")}</p>
            <div className="mt-3">
                <div className="col-12">
                    <Form.Group>
                        <Form.Label>{t("Developer email")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="john@example.com"
                            value={email}
                            onChange={e => { setEmail(e.target.value) }} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("Message")}</Form.Label>
                        <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            maxLength="500"
                            value={text}
                            onChange={e => { setText(e.target.value) }} ></textarea>
                    </Form.Group>
                </div>
            </div>
            <div className="mt-4 mb-2 mx-3 d-flex">
                <Button className="ml-auto mr-3" disabled={isSending} variant="outline-primary" onClick={props.onClose}>{t("Cancel", { ns: 'common' })}</Button>
                <Button variant="primary" disabled={isSending} onClick={sendEmail}>{t("Send")}</Button>
            </div>
        </Modal >
    );
}