import React from 'react'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Modal from 'react-bootstrap/Modal'
import { withTranslation } from "react-i18next"
import { withRouter } from 'react-router-dom'
import { saveSiteUser } from '../../logic/api'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import { isEmail } from './../../logic/util'
import './InviteTeamUserModal.scss'
import './Modal.scss'

class InviteTeamUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: "MEMBER",
            email: "",
        }

        this.shareDocument = this.shareDocument.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    changeSelectedToMember = () => this.setState({ selectedRole: "MEMBER" })
    changeSelectedToAdmin = () => this.setState({ selectedRole: "ADMIN" })

    shareDocument() {
        let email = this.state.email;
        let role = this.state.selectedRole;
        const { t } = this.props

        if (email === "") {
            addErrorToast(t("Email is required"))
            return
        }

        if (!isEmail(email)) {
            addErrorToast(t("A valid email is required"))
            return
        }

        saveSiteUser({ email, role }).then(r => {
            addInfoToast(t("User added"))
            this.props.onInvited()
            this.props.onClose()
        }).catch(e => {
            addErrorToast(t("Something went wrong. Could not invite user"))
        })
    }

    render() {
        const { t } = this.props
        return (
            <Modal className="tw-modal invite-user-modal" size="lg" centered onShow={() => this.setState({ email: "" })} show={this.props.show} onHide={this.props.onClose} animation={false}>
                <h5 className="modal-title my-2">{t("Invite to team")}</h5>
                <p className="footnote">{t("Invite a new member to your team")}</p>
                <div className="input-container d-flex mb-3">
                    <input id="share-email" type="email" placeholder="work@email.com" value={this.state.email} onChange={this.handleEmailChange} />
                    <DropdownButton title={this.state.selectedRole === "MEMBER" ? "Member" : "Admin"} menuAlign="right">
                        <Dropdown.Item onClick={this.changeSelectedToMember}>{t("Member")}</Dropdown.Item>
                        <Dropdown.Item onClick={this.changeSelectedToAdmin}>{t("Admin")}</Dropdown.Item>
                    </DropdownButton>
                    <Button className="ml-4 w-25" variant="primary" onClick={this.shareDocument}>
                        {t("Send invite")}
                    </Button>
                    <small className="hint">
                        {t("Email")}
                    </small>
                </div>
            </Modal>
        );
    }
}

export default withTranslation("modal_members")(withRouter(InviteTeamUserModal));