import React from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { addModal } from '../../modals/ModalManager'
import ConfirmModal from '../../modals/ConfirmModal'
import { useTranslation } from "react-i18next";


const GlobalNavigationBlocker = (props) => {
    let history = useHistory();
    const { t } = useTranslation("settings")

    if (props.navigationBlocked) {
        window.onbeforeunload = () => true
    } else {
        window.onbeforeunload = null
    }

    return (
        <Prompt
            message={(location, action) => {
                if (location?.state?.skipBlock)
                    return true

                if (props.check) {
                    if (props.check()) {
                        addModal(<ConfirmModal />, {
                            data: {
                                title: t("You didn’t save your settings."),
                                body: t("We just want to make sure you have saved all your settings and changes before you leave."),
                                confirmText: t("Go back and save"),
                                cancelText: t("Continue without saving")
                            },
                            onConfirm: () => { },
                            onCancel: () => {
                                history.push(location.pathname, { skipBlock: true });
                            }

                        })

                        return false
                    }
                }

                return true
            }}
        />
    )
}

export default (GlobalNavigationBlocker)