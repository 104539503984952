import './VolumeSlider.scss'

export default function VolumeSlider({ value, setValue, onMouseUp }) {
    return (
        <div className='volume-slider ml-2' style={{ display: 'flex', fontSize: 18 }}>
            <i className="fas fa-volume-off" onClick={() => { setValue("0") }}></i>
            <input
                type="range"
                min={0}
                max={1}
                step={0.02}
                value={value === undefined ? 0.6 : value}
                onChange={event => { setValue(event.target.value) }}
                onMouseUp={onMouseUp}
            />
            <i className="fas fa-volume" onClick={() => { setValue("1") }}></i>
        </div>
    )
}