import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import './CustomDropdown.scss';


export const CustomPlainToggle = React.forwardRef(({ className, disabled, children, onClick }, ref) => (
    <button className="cst-dropdown-btn" ref={ref} onClick={(e) => { e.preventDefault(); if (!disabled) onClick(e); }} >
        {children}
    </button>
));

export const CustomToggle = React.forwardRef(({ children, disabled, onClick, hideArrow }, ref) => (
    <a href="" ref={ref} className="form-control" onClick={(e) => { e.preventDefault(); if (!disabled) onClick(e); }} >
        <span className="mr-auto">{children}</span>
        {(!hideArrow && !disabled) && <i className="ml-1 far fa-chevron-down float-right"></i>}
    </a>
));


export const CustomToggleWithPrefix = React.forwardRef(({ children, disabled, prefix, suffix, onClick, hideArrow }, ref) => (
    <div ref={ref} className={cn("form-toggle-with-prefix", { disabled })} onClick={(e) => { e.preventDefault(); if (!disabled) onClick(e); }} >
        {prefix && <div className='prefix-label'>{prefix}</div>}
        <div className={cn('form-control', { disabled })}>
            <span className="mr-auto">{children}</span>
            {(!hideArrow && !disabled) && <i className="ml-1 far fa-chevron-down float-right"></i>}
        </div>
        {suffix && <div className='prefix-label'>{suffix}</div>}
    </div>
));


export const CustomToggleWithQuickpicks = React.forwardRef(({ children, disabled, prefix, quickPicks, onClick, hideArrow }, ref) => (
    <div ref={ref} className="form-toggle-with-prefix-qp" onClick={(e) => { e.preventDefault(); if (!disabled) onClick(e); }} >
        {prefix && <div className='prefix-label'>{prefix}</div>}
        <div className={cn('form-control', { disabled })}>
            {quickPicks}
            <div className='fc-sub-btn'>
                <span className="mr-auto">{children}</span>
                {(!hideArrow && !disabled) && <i className="ml-1 far fa-chevron-down float-right"></i>}
            </div>
        </div>
    </div>
));

export const CustomMenu = React.forwardRef(
    ({ topAlign, menuWidth, leftAlign, children, style, className, 'aria-labelledby': labeledBy, close }, ref) => {
        const [value, setValue] = useState('');
        const { t } = useTranslation("common");

        style = {
            right: 'auto',
            left: '50%',
            WebkitTransform: 'translate(-50%, 0)',
            OTransform: 'translate(-50%, 0)',
            transform: 'translate(-50%, 0)',
        }

        if (topAlign) {
            style["bottom"] = "calc(100% + 6px)"
            style["top"] = "auto"
        }

        if (leftAlign) {
            style["left"] = "0px"
            delete style["WebkitTransform"]
            delete style["OTransform"]
            delete style["transform"]
        }

        if (menuWidth) {
            style['width'] = menuWidth
        }

        let filteredChildren = React.Children.toArray(children).filter((child) => {
            if (!value)
                return true

            if (child.props.searchkey)
                return child.props.searchkey.toLowerCase().includes(value.toLowerCase())

            if (child.props.children)
                return child.props.children.toLowerCase().includes(value.toLowerCase())

            return true
        })

        useEffect(() => {
            ref?.current?.focus()
        }, [])

        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy} >
                <div className="d-flex mx-3">
                    <FormControl
                        autoFocus
                        placeholder={t("Type to filter...")}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                event.preventDefault();
                                return false;
                            }
                        }}
                        value={value}
                    />
                    {isMobile && <div className="ml-3 cancel-dd" onClick={close}>
                        {t("Cancel")}
                    </div>}
                </div>

                <ul className="list-unstyled py-2">
                    {filteredChildren.length > 0 && filteredChildren}
                    {filteredChildren.length === 0 && <div className="text-center mt-2 text-muted">{t("No matches")}</div>}
                </ul>
            </div>
        );
    },
);
