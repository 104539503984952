import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import './BadgeDropdown.scss';
import { CustomMenu, CustomToggle } from './CustomDropdown';

export default function BadgeDropdown({ label = "Select", title, infoPopover, badges = [], badgeSelection = [], displayMapping = null, removeBadge, addBadge }) {

    const handleSelect = (eventKey) => {
        addBadge(eventKey)
    }

    let selectedBadgesElements = badges.map((element, i) => <SingleBadge key={i} element={element} text={displayMapping ? displayMapping(element) || element : element} onRemove={removeBadge} />)
    let allowedBadgesElements = badgeSelection.map((element, i) => <Dropdown.Item key={i} onSelect={handleSelect} eventKey={element}>{displayMapping ? displayMapping(element) || element : element}</Dropdown.Item>)

    return (
        <Form.Group>
            <Form.Label>{title || label}{infoPopover}</Form.Label>
            <div className="badge-dropdown">
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        {label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} >
                        {allowedBadgesElements}
                    </Dropdown.Menu>
                </Dropdown>
                <div className="badges-list">
                    {selectedBadgesElements}
                </div>
            </div>
        </Form.Group>
    )
}

function SingleBadge(params) {
    return (
        <span className="bi-badge">
            <span>{params.text}</span>
            <span className="bi-badge-close" onClick={() => params.onRemove(params.element)}><i className="fas fa-times-circle"></i></span>
        </span>
    )
}