import cn from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Trans, withTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { integrationsCreatePipedrive, integrationsRemovePipedrive } from '../../logic/api'
import { executePromiseMinTime } from '../../logic/util'
import pipedriveLogo from '../../resources/integrations/pipedrive-icon.svg'
import { integrationAdd, integrationDelete } from '../../state/currentUser'
import { InfoTooltip } from '../base/minor/Tooltip'
import { addErrorToast, addInfoToast } from '../base/ToastManager'
import './Modal.scss'

class PipedriveManage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: false, key: "" }
        this.removePDIntegration = this.removePDIntegration.bind(this)
        this.createPDIntegration = this.createPDIntegration.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
    }


    cancelClick() {
        if (this.props.onCancel) {
            this.props.onCancel()
            this.props.onClose();
        } else {
            this.props.onClose()
        }
    }

    removePDIntegration() {
        const { t } = this.props;

        executePromiseMinTime(Promise.resolve(integrationsRemovePipedrive().then(r => {
            this.props.onClose();
            addInfoToast(t("Pipedrive removed"))
            this.props.dispatch(integrationDelete({ integrationType: "PIPEDRIVE" }))
        }).catch(e => {
            addErrorToast(t("Could not remove Pipedrive"))
        })), 1500).then(() => {
            this.setState({ isLoading: false });
        });
    }

    createPDIntegration() {
        const { t } = this.props;

        executePromiseMinTime(Promise.resolve(integrationsCreatePipedrive({ apiKey: this.state.key }).then(r => {
            this.props.onClose();
            addInfoToast(t("Key saved"))
            this.props.dispatch(integrationAdd({ integrationType: "PIPEDRIVE" }))
        }).catch(e => {
            addErrorToast(t("Could not save the key"))
        })), 1500).then(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        let data = this.props.data || {}
        const { t } = this.props;


        const hasIntegration = data?.siteDetails?.integrationNames?.some(integration => {
            return integration.integrationType === "PIPEDRIVE"
        });

        return (
            <Modal className={cn("tw-modal confirm-modal", { 'secondary': this.props.isSecondary })} centered show={this.props.show} onHide={this.props.onClose} animation={false}>
                <div className="modal-icon">
                    <img style={{ height: '100%', width: '100%', padding: 4 }} src={pipedriveLogo} alt="Pipedriv Logo" />
                </div>
                <p className="footnote"></p>
                {hasIntegration && <div className=" mx-3">
                    <Form.Group>
                        <Form.Label>
                            {t("API Key")}
                            <InfoTooltip>
                                <Trans t={t}
                                    shouldUnescape={false}
                                    defaults="API key, it can be found under Pipedrive <t> Settings > Personal preferences > API</t>"
                                    components={{ t: <i className="tour-tip"></i> }} />
                            </InfoTooltip>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("API key")}
                            disabled
                            value="***********************************"
                            onChange={e => { }} />
                    </Form.Group>
                </div>}
                {!hasIntegration && <div className=" mx-3">
                    <Form.Group>
                        <Form.Label>
                            {t("API Key")}
                            <InfoTooltip>
                                <Trans t={t}
                                    shouldUnescape={false}
                                    defaults="API key, it can be found under Pipedrive <t> Settings > Personal preferences > API</t>"
                                    components={{ t: <i className="tour-tip"></i> }} />
                            </InfoTooltip>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("API Key")}
                            value={this.state?.key || ""}
                            onChange={e => { this.setState({ key: e.target.value }) }} />
                    </Form.Group>
                </div>}
                <div className="mt-4 mb-2 mx-3 d-flex">
                    <div className="ml-auto">

                    </div>
                    <Button disabled={this.state.isLoading} className="mr-3" variant="outline-primary" onClick={this.cancelClick}>
                        {data.cancelText ? data.cancelText : t("Cancel", { ns: 'common' })}
                    </Button>
                    {!hasIntegration && <Button disabled={this.state.isLoading} variant="primary" onClick={this.createPDIntegration}>
                        <span className="confirm-text">{t("Save", { ns: 'common' })}</span>
                        <div className="loader">{t("Loading...", { ns: 'common' })}</div>
                    </Button>}
                    {hasIntegration && <Button disabled={this.state.isLoading} variant="primary" onClick={this.removePDIntegration}>
                        <span className="confirm-text">{t("Delete", { ns: 'common' })}</span>
                        <div className="loader">{t("Loading...", { ns: 'common' })}</div>
                    </Button>}
                </div>
            </Modal >
        );
    }
}

export default withTranslation("integrations")(connect()(PipedriveManage));