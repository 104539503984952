
import classNames from 'classnames'
import './Common.scss'


export function Separator(params) {
    return <div className={classNames('cmn-separator', params.className)}>{params.children}</div>
}

export function SeparatorVertical(params) {
    return <div className={classNames('cmn-vert-separator', params.className)}>{params.children}</div>
}

export function SeparatorDots(params) {
    return <div className={classNames('cmn-separator-dots', params.className)}><i className='fas fa-ellipsis-h' /></div>
}

export function ThinButton({ className, style, type, onClick, ...params }) {
    return (
        <div className={classNames('cmn-thin-btn-wrap', className)}>
            <div style={style || {}} className={classNames("cmn-thin-btn", type)} onClick={onClick || (() => { })}>
                {params.children}
            </div >
        </div>
    )
}