
import React from 'react';
import { withTranslation } from "react-i18next";
import { getBase64 } from '../../logic/util';
import { addErrorToast } from '../base/ToastManager';
import './ImageUpload.scss';

class ImageUploadInt extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null
        }

        this.logoUploader = React.createRef();
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const { t } = this.props
        if (!event.target.files || !event.target.files[0]) {
            this.props.onChange()
            this.setState({
                file: null,
            })
        } else if (event.target.files[0].size / 1024 / 1024 > 6) {
            addErrorToast(t("Maximum logo sise is 6 mb"))
            this.setState({
                file: null,
            })
        } else {
            getBase64(event.target.files[0]).then(base64 => {
                let extension = event.target.files[0].name.split('.').pop();
                let file = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null
                this.props.onChange(base64, extension, file)
                this.setState({ file })
            }).catch(e => {
                addErrorToast(t("Could not load image"))
            })
        }

    }
    render() {
        const url = this.state.file ? this.state.file : this.props.value ? this.props.value : "/images/icon/image-ph.svg"
        return (
            <div className="image-preview-wrap">
                {(this.state.file || this.props.value) && <div className="remove-image" onClick={this.handleChange}><i className="fas fa-trash m-auto"></i></div>}
                <input style={{ display: "none" }} ref={this.logoUploader} type="file" accept="image/*" onChange={this.handleChange} />
                <img
                    alt="Logo preview"
                    className="logo-preview"
                    src={url}
                    onClick={() => { this.logoUploader.current.click() }}
                />
            </div>
        );
    }
}

export const ImageUpload = withTranslation('common')(ImageUploadInt)

class ImageUploadWideInt extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null
        }

        this.logoUploader = React.createRef();
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const { t } = this.props

        if (!event.target.files || !event.target.files[0]) {
            this.props.onChange()
            this.setState({
                file: null,
            })
        } else if (event.target.files[0].size / 1024 / 1024 > 6) {
            addErrorToast(t("Maximum logo sise is 6 mb"))
            this.setState({
                file: null,
            })
        } else {
            getBase64(event.target.files[0]).then(base64 => {
                let extension = event.target.files[0].name.split('.').pop();
                let file = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null
                this.props.onChange(base64, extension, file, event.target.files[0])
                this.setState({ file })
            }).catch(e => {
                addErrorToast(t("Could not load image"))
            })
        }

    }
    render() {
        const url = this.state.file ? this.state.file : this.props.value ? this.props.value : "/images/icon/image-ph.svg"
        return (
            <div className="image-preview-wrap image-wide">
                {(this.state.file || this.props.value) && <div className="remove-image" onClick={this.handleChange}><i className="fas fa-trash m-auto"></i></div>}
                <input style={{ display: "none" }} ref={this.logoUploader} type="file" accept="image/*" onChange={this.handleChange} />
                <img
                    alt="Logo preview"
                    className="logo-preview"
                    src={url}
                    onClick={() => { this.logoUploader.current.click() }}
                />
            </div>
        );
    }
}

export const ImageUploadWide = withTranslation('common')(ImageUploadWideInt)
