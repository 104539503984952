import React from 'react'
import { Dropdown, FormControl } from 'react-bootstrap';
import './SearchAndTagDropdown.scss'
import { CustomPlainToggle } from './CustomDropdown';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import Tag from './Tag';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

export default function SearchAndTagDropdown({ label = "Select", tags = [], tagSelection = [], removeTag, addTag, searchChange, searchTerm }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const { t } = useTranslation("common")
    const dispatch = useDispatch();

    let selectedBadgesElements = tags.map((tag, i) => <Tag key={i} tag={tag} contactID={tag.taggedContactID} onClick={() => { removeTag(tag) }} />)
    let allowedBadgesElements = tagSelection.map((tag, i) => (<Tag key={i} tag={tag} contactID={tag.taggedContactID} hideX={true} onClick={() => { setShowDropdown(false); addTag(tag) }} />))

    function inputChangeAndSearch(e) {
        searchChange(e.target.value)
        dispatch({ type: "CHAT_LIST:SEARCH_TERM", payload: e.target.value })
    }

    return (
        <div className="tag-dropdown d-flex flex-column">
            <Dropdown className="d-flex mx-auto" show={showDropdown} onToggle={(open) => { setShowDropdown(open) }}  >
                <div className="my-0 mr-2 input-search">
                    <input
                        placeholder={t("Search customers")}
                        value={searchTerm || ""}
                        onChange={inputChangeAndSearch}
                        maxLength="120" ></input >
                </div>
                <Dropdown.Toggle hideArrow as={CustomPlainToggle} >
                    <i className="fas fa-filter"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu placeholder={t("Type to filter tags")} as={TagMenu}>
                    {allowedBadgesElements}
                </Dropdown.Menu>
            </Dropdown>
            <div className="badges-list">
                {selectedBadgesElements}
            </div>
        </div >
    )
}

export const TagMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, close, externalFilter, externalFilterValue, noMatchesText, placeholder }, ref) => {
        const [value, setValue] = useState('');
        const { t } = useTranslation("common")

        if (isMobile) {
            style = {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "0px",
                zIndex: 3000,
            }
        }

        let filteredChildren = externalFilter ?
            React.Children.toArray(children).reverse().filter((child) => !externalFilterValue || (child.props.tag.name.toLowerCase().includes(externalFilterValue.toLowerCase()))) :
            React.Children.toArray(children).reverse().filter((child) => !value || (child.props.tag.name.toLowerCase().includes(value.toLowerCase())))

        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy} >
                <div className="d-flex mx-3">
                    {!externalFilter && <FormControl
                        autoFocus
                        placeholder={placeholder || t("Type to filter...")}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                event.preventDefault();
                                return false;
                            }
                        }}
                        value={value}
                    />}
                    {isMobile && <div className="ml-3 cancel-dd" onClick={close}>
                        {t("Cancel")}
                    </div>}
                </div>

                <div className="dropdown-main-menu py-3 px-2">
                    {filteredChildren.length > 0 && filteredChildren}
                    {filteredChildren.length === 0 && <div className="text-center mt-2 text-muted">{noMatchesText ? noMatchesText : t("No matches")}</div>}
                </div>
            </div>
        );
    },
);
