import cn from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { asQueryString, getSiteAnalytics } from '../../logic/api';
import { executePromiseMinTime, toDurationString } from '../../logic/util';
import PeriodPicker, { defineds } from '../base/PeriodPicker';
import { addErrorToast } from '../base/ToastManager';
import { CustomPlainToggle } from '../base/minor/CustomDropdown';
import { InfoTooltip } from '../base/minor/Tooltip';
import './AnalyticsPage.scss';
import DefaultLayout from './DefaultLayout';
import './DefaultPage.scss';
const COLORS = [
    '#F4D03F',
    '#9B59B6',
    '#3498DB',
    '#2ECC71',
    '#F39C12',
    '#E74C3C',
    '#2980B9',
    '#1ABC9C',
    '#F1C40F',
    '#D35400',
];

class AnalyticsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "lastMonth",
            sourceFilter: "",
            newFBVisitors: 0,
            newInstaVisitors: 0,
            newWebVisitors: 0,
            newVisitors: 0,
            newEmailVisitors: 0,
            perDOW: [],
            perHOD: [],
            messages: [],
            locations: [],
            browsers: [],
            devices: [],
            supports: [],
            tags: [],
            webURL: "",
            loading: true,
            startDate: null,
            endDate: null,
        }

        this.changeSourceFilter = this.changeSourceFilter.bind(this)
    }

    loadStats(startDate, endDate) {
        const { t } = this.props
        const weekDays = [
            { name: t("Mon") },
            { name: t("Tue") },
            { name: t("Wed") },
            { name: t("Thu") },
            { name: t("Fri") },
            { name: t("Sat") },
            { name: t("Sun") },
        ]

        this.setState({ loading: true, startDate, endDate }, () => {
            executePromiseMinTime(getSiteAnalytics({ sourceFilter: this.state.sourceFilter, startDate: startDate?.getTime(), endDate: endDate?.getTime() }).then(r => {
                let date = new Date();
                let offset = (date.getTimezoneOffset() / 60) * -1;
                let perDOW = r.msgsPerDow
                weekDays.forEach((el, i) => {
                    let dow = perDOW.find(dowEl => dowEl.dayOfWeek === i + 1)
                    if (!dow) {
                        perDOW.push({ dayOfWeek: i + 1, name: el.name, visitorCount: 0, supportCount: 0 })
                    } else {
                        dow.name = el.name
                    }
                });


                let perHOD = r.msgsPerHod
                for (let i = 0; i < 24; i++) {
                    let utcHour = ((24 + i - offset) % 24)
                    let hod = perHOD.find(hodEl => hodEl.hourOfDay === utcHour)

                    if (!hod) {
                        perHOD.push({ localHourOfDay: i, visitorCount: 0, supportCount: 0 })
                    } else {
                        hod.localHourOfDay = i
                    }
                }

                perDOW?.sort((a, b) => (a.dayOfWeek > b.dayOfWeek) ? 1 : ((b.dayOfWeek > a.dayOfWeek) ? -1 : 0))
                perHOD?.sort((a, b) => (a.localHourOfDay > b.localHourOfDay) ? 1 : ((b.localHourOfDay > a.localHourOfDay) ? -1 : 0))
                r.messages = r.messages?.sort((a, b) => new Date(a.time) - new Date(b.time));

                if (r.devices?.length > 9) {
                    let others = r.devices.splice(9)
                    let total = others.reduce((partialSum, device) => partialSum + (device.count || 0), 0)
                    r.devices.push({
                        device: t('Other'),
                        count: total,
                    })
                }

                return {
                    perDOW,
                    perHOD,
                    supports: r.supports || [],
                    devices: r.devices || [],
                    browsers: r.browsers || [],
                    locations: r.locations || [],
                    messages: r.messages || [],
                    tags: r.tags || [],
                    newFBVisitors: r.visitorCounts?.newFbVisitors || 0,
                    newInstaVisitors: r.visitorCounts?.newInstaVisitors || 0,
                    newWebVisitors: r.visitorCounts?.newWebVisitors || 0,
                    newEmailVisitors: r.visitorCounts?.newEmailVisitors || 0,
                    missedWebVisitors: r.visitorCounts?.missedWebVisitors || 0,
                    newVisitors: r.visitorCounts?.newVisitors || 0,
                    loading: false,
                    startDate,
                    endDate,
                }
            }), 320).then(r => {
                this.setState(r)
            }).catch(e => {
                addErrorToast(t("Something went wrong", { ns: 'common' }))
            });
        })
    }

    changeSourceFilter(sourceFilter) {
        if (sourceFilter === this.state.sourceFilter)
            return

        this.setState({ sourceFilter }, () => {
            this.loadStats(this.state.startDate, this.state.endDate)
        })
    }

    componentDidMount() {
        this.loadStats(defineds.startOfLastMonth, defineds.endOfLastMonth)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.user?.siteDetails?.id !== this.props?.user?.siteDetails?.id) {
            this.loadStats(defineds.startOfLastMonth, defineds.endOfLastMonth)
        }
    }

    getCsvUrl() {
        let startDate = defineds.startOfAllTime
        let endDate = defineds.endOfAllTime

        return "/api/site/analytics/contacts_data?" + asQueryString({ startDate: startDate?.getTime(), endDate: endDate?.getTime() })
    }

    render() {
        const { t } = this.props

        return (
            <DefaultLayout noContentPad match={this.props.match}>
                <div className="analytics-page">
                    <div className="content-card title-row">
                        <span className="analytics-title ">
                            {t("Chat analytics")}
                        </span>
                        <SourceFilter sourceFilter={this.state.sourceFilter} changeSourceFilter={this.changeSourceFilter} />
                        <Dropdown className="ml-auto" onToggle={isOpen => { }}>
                            <Dropdown.Toggle className="m-auto options" as={CustomPlainToggle}>
                                <div style={{ fontSize: 16, color: "#42526e", margin: "auto", fontWeight: 400 }}>
                                    {t("Export")}
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align={"right"}>
                                <Dropdown.Item className="option-item" href={this.getCsvUrl()} >{t("Contacts")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <PeriodPicker
                            className="ml-4"
                            disabled={this.state.loading}
                            onSelect={(startDate, endDate) => {
                                this.loadStats(startDate, endDate)
                            }}
                        />

                    </div>
                    <div className="analytics-page-data" >
                        <div className="content-card " style={{ maxWidth: 950 }}>
                            <span className="analytics-title ">{t("New visitors")}</span>
                            <div className="d-flex multi-part">
                                <LoadableStats isLoading={this.state.loading}>
                                    <span className="analytics-minor-title">{t("Total")}</span>
                                    <div className="single-metric">{this.state.newVisitors}</div>
                                </LoadableStats >
                                <LoadableStats isLoading={this.state.loading}>
                                    <span className="analytics-minor-title">{t("Facebook")}</span>
                                    <div className="single-metric">{this.state.newFBVisitors}</div>
                                </LoadableStats>
                                <LoadableStats isLoading={this.state.loading}>
                                    <span className="analytics-minor-title">{t("Instagram")}</span>
                                    <div className="single-metric">{this.state.newInstaVisitors}</div>
                                </LoadableStats>
                                <LoadableStats isLoading={this.state.loading}>
                                    <span className="analytics-minor-title">{t("Web")}</span>
                                    <div className="single-metric">{this.state.newWebVisitors}</div>
                                </LoadableStats>
                                <LoadableStats isLoading={this.state.loading}>
                                    <span className="analytics-minor-title">{t("Email")}</span>
                                    <div className="single-metric">{this.state.newEmailVisitors}</div>
                                </LoadableStats>
                            </div>
                        </div>
                        <div className="multi-part-row">
                            <div className="content-card" style={{ minWidth: 550 }}>
                                <span className="analytics-title ">
                                    {t("Total hourly messages")}
                                    <InfoTooltip> {t("Sum of total hourly messages sent and received")}</InfoTooltip>
                                </span>
                                <LoadableStats isLoading={this.state.loading} hasData={this.state.perHOD?.length > 0} >
                                    <ResponsiveContainer width="100%">
                                        <BarChart data={this.state.perHOD} layout="horizontal" margin={{ left: 15 }}  >
                                            <Legend align="center" />
                                            <Tooltip content={<CustomTooltip />} cursor={{ fill: '#eeedf9' }} />
                                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                                            <XAxis type="category" dataKey="localHourOfDay" tickLine={false} interval={0} />
                                            <YAxis type="number" tickLine={false} width={35} />
                                            <Bar dataKey="supportCount" name={t("Support")} fill="#8884d8" stackId="a" isAnimationActive={false} />
                                            <Bar dataKey="visitorCount" name={t("Visitor")} fill="#82ca9d" stackId="a" isAnimationActive={false} />
                                        </BarChart>
                                    </ ResponsiveContainer >
                                </LoadableStats>
                            </div>
                            <div className="content-card" style={{ minWidth: 550 }}>
                                <span className="analytics-title ">
                                    {t("Total weekday messages")}
                                    <InfoTooltip>{t("Overview of total number of messages sent and received in a weekday.")}</InfoTooltip>
                                </span>
                                <LoadableStats isLoading={this.state.loading}>
                                    <ResponsiveContainer width="100%" minHeight={240}>
                                        <BarChart data={this.state.perDOW} layout="horizontal" margin={{ left: 15 }}>
                                            <Legend align="center" />
                                            <Tooltip content={<CustomTooltip />} cursor={{ fill: '#eeedf9' }} />
                                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                                            <XAxis type="category" dataKey="name" tickLine={false} interval={0} />
                                            <YAxis type="number" tickLine={false} width={35} />
                                            <Bar dataKey="supportCount" name={t("Support")} fill="#8884d8" stackId="a" isAnimationActive={false} />
                                            <Bar dataKey="visitorCount" name={t("Visitor")} fill="#82ca9d" stackId="a" isAnimationActive={false} />
                                        </BarChart>
                                    </ ResponsiveContainer >
                                </LoadableStats >
                            </div>
                        </div>
                        <div className="multi-part-row">

                            <div className="content-card " style={{ minWidth: 450 }}>
                                <span className="analytics-title ">{t("Web visitor browsers")}</span>
                                <LoadableStats isLoading={this.state.loading} hideMissing hasData={this.state.browsers?.length > 0}>
                                    <ResponsiveContainer width="100%" minHeight={240}>
                                        <PieChart margin={{ top: 15 }} >
                                            <Legend verticalAlign="top" layout="vertical" align="left" />
                                            <Tooltip content={<CustomTooltip />} cursor={{ fill: '#eeedf9' }} />s
                                            <Pie
                                                data={this.state.browsers}
                                                dataKey="count"
                                                nameKey="browser"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={"60%"}

                                                label
                                                isAnimationActive={false} >
                                                {this.state.browsers.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ ResponsiveContainer >
                                </LoadableStats>
                            </div>
                            <div className="content-card " style={{ minWidth: 450 }}>
                                <span className="analytics-title ">{t("Web visitor devices")}</span>
                                <LoadableStats isLoading={this.state.loading} hideMissing hasData={this.state.devices?.length > 0}>
                                    <ResponsiveContainer width="100%" minHeight={240}>
                                        <PieChart margin={{ top: 15 }} >
                                            <Legend verticalAlign="top" layout="vertical" align="left" />
                                            <Tooltip content={<CustomTooltip />} cursor={{ fill: '#eeedf9' }} />s
                                            <Pie
                                                data={this.state.devices}
                                                dataKey="count"
                                                nameKey="device"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={"60%"}
                                                label
                                                isAnimationActive={false} >
                                                {this.state.devices.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ ResponsiveContainer >
                                </LoadableStats>
                            </div>
                            <div className="content-card " style={{ minWidth: 210 }}>
                                <span className="analytics-title ">{t("Top locations")}</span>
                                <LoadableStats isLoading={this.state.loading} hideMissing hasData={this.state.locations?.length > 0}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{t("Location")}</th>
                                                <th className="text-right">{t("Count")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.locations?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.location}</td>
                                                        <td className="text-right">{item.count}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </LoadableStats>
                            </div>
                        </div>

                        <div className='multi-part-row'>
                            <div className="content-card " style={{ minWidth: 210 }}>
                                <span className="analytics-title ">{t("Tags")}</span>
                                <LoadableStats isLoading={this.state.loading} hideMissing hasData={this.state.tags?.length > 0}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>{t("Tag")}</th>
                                                <th className="text-right">{t("Count")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tags?.map((item, i) => {
                                                if (i >= 10) {
                                                    return null
                                                }

                                                return (
                                                    <tr key={i}>
                                                        <td colSpan={3}>{item.tag}</td>
                                                        <td className="text-right">{item.count}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </LoadableStats>
                            </div>
                            <div className="content-card large">
                                <span className="analytics-title ">{t("Messages by support")}</span>
                                <LoadableStats isLoading={this.state.loading} hideMissing hasData={this.state.supports?.length > 0} >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{t("Support")}</th>
                                                <th className="text-right">{t("Avg messages per visitor")}</th>
                                                <th className="text-right">{t("Total messages")}</th>
                                                <th className="text-right">{t("Visitors")}</th>
                                                <th className="text-right">{t("Avg initial response time")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.supports?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{item.name}</td>
                                                        <td className="text-right">{item.averageMsgs.toFixed(2)}</td>
                                                        <td className="text-right">{item.totalMsgs}</td>
                                                        <td className="text-right">{item.visitorsCount}</td>
                                                        <td className="text-right">{item.averageResponseTime ? toDurationString(item.averageResponseTime) : "N/A"}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </LoadableStats>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultLayout >)
    }
}

const mapStateToProps = state => ({ ...state });
export default withTranslation("analytics")(connect(mapStateToProps)(AnalyticsPage));



const SourceFilter = ({ sourceFilter, changeSourceFilter }) => {

    return (
        <div className='d-flex source-filter-wrap'>
            <div className={cn('source-filter', { active: sourceFilter === "" })} onClick={() => { changeSourceFilter("") }} >{("All")}</div>
            <div className={cn('source-filter', { active: sourceFilter === "WEB" })} onClick={() => { changeSourceFilter("WEB") }}  >{("Web")}</div>
            <div className={cn('source-filter', { active: sourceFilter === "FB" })} onClick={() => { changeSourceFilter("FB") }}>{("FB")}</div>
            <div className={cn('source-filter', { active: sourceFilter === "INSTAGRAM" })} onClick={() => { changeSourceFilter("INSTAGRAM") }}>{("Instagram")}</div>
            <div className={cn('source-filter', { active: sourceFilter === "EMAIL" })} onClick={() => { changeSourceFilter("EMAIL") }}>{("Email")}</div>
        </div>
    )
}

const LoadableStats = ({ children, isLoading, hasData, hideMissing, className, ...props }) => {
    const { t } = useTranslation('analytics');
    return (<div {...props} className={"position-relative w-100 h-100 " + (className ? className : "")}>
        {children}
        {isLoading && (
            <div className="loading-stats">
                <div className="m-auto">{t("Loading ...")}</div>
            </div>)}
        {(hideMissing && !isLoading && !hasData) && (
            <div className="loading-stats">
                <div className="m-auto">{t("No data")}</div>
            </div>)}
    </div>)
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="tw-chart-tooltip">
                <p className="intro">{label}</p>
                {payload.map(item => {
                    return (
                        <p key={item.name} className="label"><span className="label-item" style={{ color: item.color }}>{item.name}: </span><span></span>{item.value}</p>
                    );
                })}
            </div>
        );
    }

    return null;
};