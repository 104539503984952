import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: 'refDetect',
    lookup(options) {
        if (document.referrer) {
            const url = new URL(document.referrer);
            if (url.pathname) {
                if (url.pathname.startsWith("/et/")) {
                    return "et"
                }
                if (url.pathname.startsWith("/pl/")) {
                    return "pl"
                }
            }
        }
        return 'en';
    },
    cacheUserLanguage: function (lng) { }
});

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(backend)
    .use(languageDetector)
    .init({
        react: {
            useSuspense: true,
        },
        ns: [
            'analytics',
            'chat',
            'common',
            'integrations',
            'modal_guides',
            'modal_members',
            'modal_send_to_dev',
            'modal_sub_over',
            'modal_sub',
            'modal_qrm',
            'modals',
            'noauth',
            'popup',
            'quick_reply',
            'settings_members',
            'settings_nav',
            'settings_personal',
            'settings_subscription',
            'settings_team',
            'settings_widget',
            'settings',
            'tags',
            'tour',
        ],
        defaultNS: 'common',
        debug: false,
        // lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        nsSeparator: false,
        keySeparator: false, // Keys in form messages.welcome
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + encodeURIComponent(process.env.REACT_APP_VERSION),
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            order: ['querystring', 'cookie', 'refDetect'],
            caches: ['cookie'],
            cookieMinutes: 160,
            lookupQuerystring: 'lang',
        }
    });

