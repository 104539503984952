import cn from 'classnames';
import { useState } from 'react';
import './EmailChips.scss';

export default function EmailChips(params) {
    const [state, setState] = useState({
        items: [],
        value: "",
        error: null
    });


    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = state.value.trim();

            if (value && isValid(value)) {
                setState({
                    ...state,
                    items: [...state.items, state.value],
                    value: ""
                });
            }
        }
    };

    const handleChange = evt => {
        setState({
            ...state,
            value: evt.target.value,
            error: null
        });
    };

    const handleDelete = item => {
        setState({
            ...state,
            items: state.items.filter(i => i !== item)
        });
    };

    const handlePaste = evt => {
        evt.preventDefault();

        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

        if (emails) {
            var toBeAdded = emails.filter(email => !isInList(email));

            setState({
                ...state,
                items: [...state.items, ...toBeAdded]
            });
        }
    };

    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setState({ ...state, error });

            return false;
        }

        return true;
    }

    const isInList = (email) => {
        return state.items.includes(email);
    }

    const isEmail = (email) => {
        return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    }

    return (<div className='email-chips-wrap'>
        {state.items.map(item => (
            <div className="tag-item" key={item}>
                {item}
                <button
                    type="button"
                    className="button"
                    onClick={() => handleDelete(item)}
                >
                    &times;
                </button>
            </div>
        ))}

        <input
            className={cn("input")}
            value={state.value}
            placeholder="Type or paste email addresses and press `Enter`..."
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
        />

        {state.error && <p className="error">{state.error}</p>}
    </div>)
}