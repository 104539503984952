import timezones from 'compact-timezone-list';
import { setDefaultOptions } from 'date-fns';
import { enUS, et, pl } from 'date-fns/locale';
import i18next from 'i18next';
import jwt_decode from "jwt-decode";
import spacetime from 'spacetime';
import languages from './../resources/languages.json';

const localization = {
    'et': et,
    'en': enUS,
    'pl': pl,
}

export function getWSSessionDomain() {
    let host = window.location.hostname
    if (host === "192.168.50.210" || host === "localhost")
        return "ws://" + host + "/wsbroker"

    if (host === "chat.staging.askly.me")
        return "wss://chat.staging.askly.me"

    return "wss://chat.askly.me"
}

export const b64ToPastelColor = (b64) => {
    const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    if (!b64 || b64.length < 4)
        return ""


    let newH = 0;
    newH = ((ALPHA.indexOf(b64[0]) * 64) + ALPHA.indexOf(b64[1])) % 360;
    let newL = (ALPHA.indexOf(b64[2]) % 16) + 75;

    return `hsl(${newH}, 50%, ${newL}%)`;
}

export const hlToPastel = (hColor, LColor, opacity) => {
    return `hsla(${hColor}, 100%, ${LColor}%,   ${opacity ? opacity : "1"})`;
}

export const genUUID = (prefix) => {
    return (prefix ? prefix : "") + ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // eslint-disable-line
    );
}

export const isValidURL = function (str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const isEmail = function (str) {
    var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(str);
}

export const isValidPhoneNumber = function (str) {
    var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/i;
    return re.test(str);
}

export const checkPassword = function (str) {
    let errors = []

    if (!(/(?=.*\d)/).test(str))
        errors.push("err_digits")

    if (!(/(?=.*[a-z])/).test(str))
        errors.push("err_lower")

    if (!(/(?=.*[A-Z])/).test(str))
        errors.push("err_upper")

    if (str?.length < 6)
        errors.push("err_len")

    return errors;
}

export function timeDifference(elapsed) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    if (elapsed < msPerMinute) {
        return 'seconds ago';
    } else if (elapsed < msPerHour) {
        let minutes = Math.round(elapsed / msPerMinute)
        return minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';
    } else if (elapsed < msPerDay) {
        let hours = Math.round(elapsed / msPerHour)
        return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    } else if (elapsed < msPerMonth) {
        let days = Math.round(elapsed / msPerDay)
        return days === 1 ? 'approximately 1 day ago' : 'approximately ' + days + ' days ago';
    } else if (elapsed < msPerYear) {
        let months = Math.round(elapsed / msPerMonth)
        return months === 1 ? 'approximately 1 month ago' : 'approximately ' + months + ' months ago';
    } else {
        let years = Math.round(elapsed / msPerYear)
        return years === 1 ? 'approximately 1 year ago' : 'approximately ' + years + ' years ago';
    }
}

export function toDurationString(sec_num) {
    sec_num = Math.floor(sec_num)

    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    let text = ""
    if (hours > 1) {
        text += hours + " hour";
    }

    if (minutes > 1) {
        if (text.length > 0)
            text += " "

        text += minutes + " min";
    }

    if (seconds > 1) {
        if (text.length > 0)
            text += " "

        text += seconds + " s";
    }

    return text
}

export function toFormatedDateString(dateString, excludeTime, exludeDate, shortDate) {
    let date = dateString ? new Date(dateString) : new Date();

    //const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var result = (exludeDate ? "" : monthNames[date.getMonth()] + " " + date.getDate() + (!shortDate ? (" " + + date.getFullYear()) : " ")) +
        ((shortDate || excludeTime || exludeDate) ? "" : " | ") +
        (excludeTime ? "" : (date.getHours() + ":" + ("00" + date.getMinutes()).slice(-2) + " "));

    return result;
}

// use local storage for messaging. Set message in local storage and clear it right away
// This is a safe way how to communicate with other tabs while not leaving any traces
export function message_broadcast(message) {
    localStorage.setItem('message', JSON.stringify(message));
    localStorage.removeItem('message');
}


// receive message
export function message_receive(ev) {
    if (ev.key !== 'message') return; // ignore other keys
    var message = JSON.parse(ev.newValue);
    if (!message) return; // ignore empty msg or msg reset
}


export const htmlDecode = (input) => {
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function debounce(func, wait) {
    let timeout = null;

    function debounced() {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

    };

    debounced.isPending = () => (timeout !== null);
    debounced.clear = () => {
        clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
};

export function getLanguage(val, field = "iso") {
    let foundLang = { eng: "English", iso: "en" }

    if (val) {
        languages.forEach(lang => {
            if (lang[field] === val) {
                foundLang = lang
                return false;
            }
        })
    }

    return foundLang;
}


export const getLanguages = (filterLangs) => languages.filter((lang) => {
    if (filterLangs)
        return !filterLangs?.includes(lang.iso)

    return true
}).map(l => l.eng)


export const getLanguagesLabelValue = (filterLangs) => languages.filter((lang) => {
    if (filterLangs)
        return !filterLangs?.includes(lang.iso)

    return true
}).map(l => ({ label: l.eng, value: l.iso }))


export const getLanguagesIso = () => languages.map(l => l.iso)
export const getLanguagesIsoToEng = (langs = []) => langs.map(l => getLanguage(l).eng);
export const getLanguagesEngToIso = (langs = []) => langs.map(l => getLanguage(l, "eng").iso);

export const extractInitials = function (str) {
    if (!str) return ""

    var initialsArr = str.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g) || [];
    var initials = ((initialsArr.shift() || '') + (initialsArr.pop() || '')).toUpperCase();

    return initials ? initials : " ";
};

export const checkIfFirstLogin = (allowDelete,) => {
    let selectedOrgRes = document.cookie.match('(^|;)\\s*tw_new_login\\s*=\\s*([^;]+)');
    if (selectedOrgRes) {
        if (allowDelete)
            deleteCookie("tw_new_login")
        return selectedOrgRes.pop()
    }

    return false
}

export const getCurretOrgFromCookie = () => {
    let selectedOrgRes = document.cookie.match('(^|;)\\s*tw_ASI\\s*=\\s*([^;]+)');
    let selectedOrg = selectedOrgRes?.pop()
    return isNaN(selectedOrg) ? 0 : parseInt(selectedOrg)
}

export const checkForValidOrgSession = () => {
    let selectedOrgRes = document.cookie.match('(^|;)\\s*tw_ASI\\s*=\\s*([^;]+)');

    if (selectedOrgRes) {
        let selectedOrg = selectedOrgRes.pop()
        return selectedOrg !== "0"
    }

    return false
}

export const checkForValidSession = () => {
    let authToken = document.cookie.match('(^|;)\\s*tw_token\\s*=\\s*([^;]+)');
    if (authToken) {
        var decoded = jwt_decode(authToken.pop());
        if (decoded.exp < Math.floor(Date.now() / 1000)) {
            localStorage.removeItem('user')
            document.cookie = "tw_token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/ "
            return false
        }

        localStorage.setItem('user', JSON.stringify(decoded))
        return true
    }
}

export const getBrowserTimezone = () => {
    let defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let foundElem = timezones.find(tz => tz.tzCode === defaultTz)
    return foundElem ? foundElem : timezones[0]
}

export const getTimezone = (tzCode) => {
    let foundElem = timezones.find(tz => tz.tzCode === tzCode)
    return foundElem ? foundElem : timezones[0]
}

export const toLowerAndCapitalize = function (str) {
    if (!str) return ""
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1)
};

export function executePromiseMinTime(promise, time) {
    return Promise.all([promise, new Promise(resolve => setTimeout(resolve, time))]).then((([r]) => r));
}

export function deleteCookie(name) {
    document.cookie = name + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/;"
}

export function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


export function scheduleToOpenTimes(schedules) {
    if (schedules && Array.isArray(schedules.times)) {
        let scheduleByDay = {}

        function findNextOpenHours(schedule, currentLocalTime) {
            for (let index = 0; index < 8; index++) {
                let currentDay = currentLocalTime.add(index, 'day')
                let currentDayName = currentDay.dayName()
                if (!schedule[currentDayName])
                    continue

                let res = []

                let isInPast = true;
                for (var i = 0; i < schedule[currentDayName].length; i++) {
                    let temp = {
                        endTime: currentDay.time(schedule[currentDayName][i].endTime).goto(),
                        startTime: currentDay.time(schedule[currentDayName][i].startTime).goto(),
                    }
                    temp.isActive = currentLocalTime.isBetween(temp.startTime, temp.endTime, true)
                    if (index === 0) {
                        if (temp.endTime.isAfter(currentLocalTime)) {
                            isInPast = false;
                        }
                    }
                    res.push(temp)
                }
                if (index === 0 && isInPast)
                    continue

                return res[0]
            }
        }

        let currentSpaceTime = spacetime.now(schedules.timezone)
        schedules.times.forEach(schedule => {
            let cloneDay = { endTime: schedule.endTime, startTime: schedule.startTime }
            if (Array.isArray(schedule.days)) {
                schedule.days.forEach(day => {
                    if (Array.isArray(scheduleByDay[day.toLowerCase()])) {
                        scheduleByDay[day.toLowerCase()].push(cloneDay)
                    } else {
                        scheduleByDay[day.toLowerCase()] = [cloneDay]
                    }
                })
            }
        });

        let nextOpen = findNextOpenHours(scheduleByDay, currentSpaceTime)
        if (!nextOpen) {
            return null
        }

        let openHoursText = nextOpen.startTime.format('{hour-24-pad}:{minute-pad}') + " - " + nextOpen.endTime.format('{hour-24-pad}:{minute-pad}')
        let isLive = currentSpaceTime.isBetween(nextOpen.startTime, nextOpen.endTime, true)

        if (!nextOpen.startTime.isSame(currentSpaceTime, 'date')) {
            openHoursText = nextOpen.startTime.toLocalDate().toLocaleDateString(navigator.language, { weekday: 'short' }) + " " + openHoursText
        }

        return { openHoursText, isLive, nextOpen }
    }
}


export function hexToHSL(H, hOffset = 0, sOffset = 0, lOffset = 0, sOverride, lOverride, opacity) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0)
        h = 0;
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    else if (cmax === g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +((s * 100) + sOffset).toFixed(1);
    if (lOverride) {
        l = lOverride;
    } else {
        l = +((l * 100) + lOffset).toFixed(1);
    }

    h += hOffset
    h = h % 360
    if (opacity) {
        return "hsl(" + h + "," + s + "%," + l + "%, " + opacity + ")";
    }

    return "hsl(" + h + "," + s + "%," + l + "%)";
}

export function useWhiteTextForColor(H) {
    var c = H.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >> 8) & 0xff;  // extract green
    var b = (rgb >> 0) & 0xff;  // extract blue


    const brightness = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
    return (brightness < 150)
}

export function objectEquals(x, y) {
    if (x === y) return true;
    // if both x and y are null or undefined and exactly the same

    if (!(x instanceof Object) || !(y instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

    if (x.constructor !== y.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for (var p in x) {
        if (!x.hasOwnProperty(p)) continue;
        // other properties were tested using x.constructor === y.constructor

        if (!y.hasOwnProperty(p)) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

        if (x[p] === y[p]) continue;
        // if they have the same strict value or identity then they are equal

        if (typeof (x[p]) !== "object") return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

        if (!objectEquals(x[p], y[p])) return false;
        // Objects and Arrays must be tested recursively
    }

    for (p in y)
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
            return false;
    // allows x[ p ] to be set to undefined

    return true;
}

export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


export function changeLanguage(lng) {
    if (localization[lng]) {
        setDefaultOptions({ locale: localization[lng] })
    } else {
        setDefaultOptions({ locale: enUS })
    }

    return i18next.changeLanguage(lng)
}