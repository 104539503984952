// @refresh reset

import { DOMParser } from '@xmldom/xmldom';
import isHotkey from 'is-hotkey';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, useFocused, useSelected, useSlateStatic, withReact } from 'slate-react';
import { LinksPopout, removeLink, updateLink } from '../EmailEditorLinks';
import './EditorHTML.scss';
import { EditorButtons, toggleMark } from './EditorHTMLToolbar';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

export default function EditorHTML({ initialValue, value, onChange, buttons = [] }) {
    let editor = useMemo(() => withHistory(withReact(withImages(withLinks(createEditor())))), [])
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const [renderKey, setRenderKey] = useState(0)

    useEffect(() => {
        setRenderKey(renderKey + 1)
    }, [initialValue])

    return (
        <Slate key={renderKey} editor={editor} initialValue={initialValue ? initialValue : getEmptyInitialValue()} onValueChange={onChange}>
            <div className='html-editor'>
                <Editable
                    className='html-editor-area'
                    style={{ minHeight: 120 }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    spellCheck
                    autoFocus
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
                <EditorButtons />
            </div>
        </Slate>
    )
}

export function getEmptyInitialValue() { return ([{ type: 'paragraph', children: [{ text: '' }] }]) }

export const Element = ({ attributes, children, element, renderEmail }) => {
    const style = { textAlign: element.align }

    switch (element.type) {
        case "image":
            return null
        //return <Image renderEmail={renderEmail} attributes={attributes} element={element} children={children} />
        case "link":
            if (renderEmail)
                return <LinkRendered attributes={attributes} element={element} children={children} />

            return <Link renderEmail={renderEmail} attributes={attributes} element={element}  >{children}</Link>;
        case 'block-quote':
            const blockQuoteDefaultStyles = { borderLeft: "1px solid rgb(204, 204, 204)", paddingLeft: "1ex", margin: "0px 0px 0px 0.8ex" }

            return (
                <blockquote style={{ ...blockQuoteDefaultStyles, ...style }} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            const h1DefaultStyles = { fontSize: 22, fontWeight: 800, margin: 0, marginBottom: 6, padding: 0 }
            return (
                <h1 style={{ ...h1DefaultStyles, ...style }} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            const h2DefaultStyles = { fontSize: 16, fontWeight: 800, margin: 0, marginBottom: 6, padding: 0 }
            return (
                <h2 style={{ ...h2DefaultStyles, ...style }} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            const pDefaultStyles = { fontSize: 14, margin: 0, padding: 0 }
            return (
                <p style={{ ...pDefaultStyles, ...style }} {...attributes}>
                    {children}
                </p>
            )
    }
}

export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}


export const withImages = (editor) => {
    const { isVoid } = editor;

    editor.isVoid = (element) =>
        element.type === "image" ? true : isVoid(element);

    return editor;
};

export const withLinks = (editor) => {
    const { isInline } = editor;

    editor.isInline = (element) =>
        element.type === "link" ? true : isInline(element);

    return editor;
};



const LinkRendered = ({ attributes, element, children }) => {
    return <a {...attributes} href={element.href}>
        {children}
    </a>
}

const Link = ({ attributes, element, children, renderEmail }) => {
    const [show, setShow] = useState(false)
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();

    if (selected && focused) {
        let s = editor.selection
        let node = editor.children[s.anchor.path[0]]

        for (let i = 1; i < s.anchor.path.length; i++) {
            node = node.children[s.anchor.path[i]]
        }

        //(currentLink(editor))
    }
    const getLinkAttributes = () => {
        let s = editor.selection
        let node = editor.children[s.anchor.path[0]]
        const getText = (node) => { return node.text ? node.text : node.children.map(child => getText(child)).join("") }

        for (let i = 1; i < s.anchor.path.length; i++) {
            node = node.children[s.anchor.path[i]]

            if (node.type === "link") {
                let internalText = getText(node)
                return {
                    initialURL: node.href,
                    initialText: internalText,
                    onSave: (url, text) => {
                        updateLink(editor, { url, text: text === internalText ? null : text }, s.anchor.path.slice(0, i + 1))
                        setShow(false)
                    }
                }
            }
        }

        return {}
    }

    const editorLinkBtn = document.getElementById('editor-link-button');
    const fullLink = element.href?.startsWith("http") ? element.href : "//" + element.href

    return (
        <span {...attributes} className="element-link">
            <a href={element.href}>
                {children}
            </a>
            {selected && focused && (
                <span className="popup" contentEditable={false}>
                    <a href={fullLink} rel="noreferrer" target="_blank">
                        Open
                    </a>
                    <button onClick={() => setShow(true)}>
                        {"Edit"}
                    </button>
                    <button onClick={() => removeLink(editor)}>
                        {"Remove"}
                    </button>
                </span>
            )}
            {show && editorLinkBtn && createPortal(<LinksPopout editor={editor} close={() => { setShow(false) }} {...getLinkAttributes()} />, editorLinkBtn)}
        </span>
    );
};

export function HTMLParser(element) {
    const typeMap = {
        "p": "paragraph",
        "h1": "heading-one",
        "h2": "heading-two",
        "blockquote": "block-quote",
        "ul": "bulleted-list",
        "ol": "numbered-list",
        "li": "list-item",
        "a": "link",
        "img": "image"
    }

    const leafMap = {
        "strong": "bold",
        "b": "bold",
        "em": "italic",
        "u": "underline",
        "code": "code"
    }

    try {
        const treeObject = {};
        let elementToParse;

        // If string convert to document Node
        if (typeof element === 'string') {
            const parser = new DOMParser();
            const docNode = parser.parseFromString("<start>" + element + "</start>", 'text/xml');
            if (docNode.firstChild) {
                elementToParse = docNode.firstChild;
            }
        } else {
            elementToParse = element;
        }

        // Recursively loop through DOM elements and assign properties to object
        const treeHTML = (element, object = treeObject) => {
            if (leafMap[element.nodeName]) {
                object[leafMap[element.nodeName]] = true;

                const nodeList = element.childNodes;
                if (nodeList !== null && nodeList.length) {
                    for (let i = 0; i < nodeList.length; i++) {
                        if (nodeList[i].nodeType === 3 && nodeList[i].nodeValue) {
                            object.text = (nodeList[i].nodeValue);
                        } else {
                            treeHTML(nodeList[i], object);
                        }
                    }
                }
            } else if (element.nodeName == 'span') {
                const nodeList = element.childNodes;
                for (let i = 0; i < nodeList.length; i++) {
                    if (nodeList[i].nodeType === 3 && nodeList[i].nodeValue) {
                        if (!object.children) object.children = [];
                        object.children.push({ text: (nodeList[i].nodeValue) });
                    } else {
                        let tmp = {}
                        treeHTML(nodeList[i], tmp);
                        if (Object.keys(tmp).length) {
                            if (!object.children) object.children = [];

                            object.children.push(tmp);
                        }
                    }
                }
            } else {
                object.type = typeMap[element.nodeName];
                const nodeList = element.childNodes;
                if (nodeList !== null && nodeList.length) {
                    for (let i = 0; i < nodeList.length; i++) {
                        if (nodeList[i].nodeType === 3 && nodeList[i].nodeValue) {
                            object.text = (nodeList[i].nodeValue);
                        } else {
                            if (nodeList[i].nodeName === 'span') {
                                treeHTML(nodeList[i], object);
                            } else {
                                let tmp = {}
                                treeHTML(nodeList[i], tmp);
                                if (Object.keys(tmp).length) {
                                    if (!object.children) object.children = [];

                                    object.children.push(tmp);
                                }
                            }
                        }
                    }
                }
                if (!object.children) {
                    object.children = [{ text: '' }];
                }

                if (element.nodeName === 'a') {
                    object.href = element.getAttribute('href');
                }
            }
        };

        // @ts-expect-error
        treeHTML(elementToParse);

        if (treeObject.children.length > 0) {
            return treeObject.children;
        }

        return getEmptyInitialValue();
    } catch (e) {
        return getEmptyInitialValue();
    }
}
