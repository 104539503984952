import cn from 'classnames';
import i18next from 'i18next';
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { changeLanguage } from '../../../logic/util';
import './LanguageSwitch.scss';


export function LanguageSwitch({ className }) {

    function langSwitch(lang) {
        changeLanguage(lang)
    }

    function getCurLang() {
        return i18next.language?.toUpperCase() || "EN"
    }

    return (
        <DropdownButton onSelect={langSwitch} size='sm' className={cn('lng-sw', className)} variant="outline-secondary" title={getCurLang()} menuAlign="right">
            {i18next.language !== 'en' && <Dropdown.Item eventKey='en' >EN</Dropdown.Item>}
            {i18next.language !== 'et' && <Dropdown.Item eventKey='et' >ET</Dropdown.Item>}
            {i18next.language !== 'pl' && <Dropdown.Item eventKey='pl' >PL</Dropdown.Item>}
        </DropdownButton>
    )
}