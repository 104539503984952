import cn from 'classnames';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { addContactTag, deleteContactTag } from '../../../logic/api';
import { addErrorToast, addInfoToast } from '../ToastManager';
import { TagMenu } from './SearchAndTagDropdown';
import './Tag.scss';

export default function Tag({ tag, contactID, allowDelete, onClick, hideX }) {
    const { t } = useTranslation("tags")
    const removeContactTag = (e) => {
        if (e)
            e.stopPropagation()

        if (allowDelete) {
            let data = { tagId: tag.id, contactID }
            deleteContactTag(data).then(() => {
                addInfoToast(t("Tag removed"))
            }).catch(e => {
                addErrorToast(t("Could not remove tag"))
            })
        }
    }

    return (
        <div className={"tw-tag " + (onClick ? " pointer" : "") + (!hideX ? " hasX" : "")} onClick={onClick ? onClick : null} >
            <span>{tag.name || t("Unknown tag")}{!hideX && <i className="ml-1 fal fa-times pointer remove" onClick={allowDelete ? removeContactTag : null}></i>}</span>
        </div>
    )
}

export function AddTag({ className, contactID, allTags = [] }) {
    const [value, setValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const { t } = useTranslation("tags")

    let allowedBadgesElements = allTags.map((tag, i) => (<Tag key={i} tag={tag} contactID={tag.taggedContactID} hideX={true} onClick={() => { setShowDropdown(false); addTag(tag.name) }} />))

    const addTag = tagName => {
        addContactTag({ tagName, contactID }).then(tag => {
            addInfoToast(t("Tag added"))
        }).catch(e => {
            if (e.statusCode === "CONTACT_TAG_EXISTS") {
                addErrorToast(t("Tag already exists"))
            } else {
                addErrorToast(t("Could not add tag"))
            }
        }).finally(() => {
            setValue("")
            setShowDropdown(false)
        })
    }

    const onInputKeyDown = e => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            e.preventDefault();
            addTag(value)
        }
    }

    return (
        <div className={cn("add-tw-tag-wrap", className)} >
            <Dropdown show={showDropdown} onToggle={(open) => { setShowDropdown(open) }} >
                <Dropdown.Toggle onInputKeyDown={onInputKeyDown} value={value} setValue={setValue} as={CustomInputToggle} />
                <Dropdown.Menu externalFilter={true} externalFilterValue={value} noMatchesText={t("Press enter to add new tag")} as={TagMenu}  >
                    {allowedBadgesElements}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export const CustomInputToggle = React.forwardRef(({ className, children, onClick, onInputKeyDown, value, setValue }, ref) => {
    const { t } = useTranslation("tags")

    return (
        <div className="add-tw-tag">
            <input ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}
                onKeyDown={onInputKeyDown}
                placeholder={t("Add a tag")}
                value={value}
                onChange={e => { setValue(e.target.value) }}
                maxLength="120" ></input >
        </div>
    )
});
