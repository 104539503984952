import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useAddEmailDomainMutation } from '../../state/api'
import { addErrorToast } from '../base/ToastManager'
import './AddEmailDomain.scss'
import './Modal.scss'



export default function AddEmailDomain(props) {
    const [addEmailDomain, { isLoading }] = useAddEmailDomainMutation()
    const [domain, setDomain] = useState("");
    const { t } = useTranslation("modal_add_email_domain")


    const createDomain = () => {
        // we async create domain
        let domainRequest = { domain }

        addEmailDomain(domainRequest).unwrap().then(() => {
            props.onClose()
        }).catch((err) => {
            console.log(err)
            addErrorToast(t("Failed to add domain"))
        })
    }

    return (
        <Modal className="tw-modal add-email-domain-modal" size="md" centered onShow={() => setDomain("")} show={props.show} onHide={props.onClose} animation={false}>
            <h5 className="modal-title my-2">{t("Add domain")}</h5>
            <p className="footnote">{t("Add email domain. Verification will be done from domain providers website.")}</p>
            <Form.Group className="mx-4 mt-2">
                <Form.Control type="text" placeholder="example.com" value={domain} maxLength={55} onChange={e => { setDomain(e.target.value) }} />
            </Form.Group>
            <div className="mt-4 mb-2 mx-3 d-flex">
                <div className="ml-auto"></div>
                {!isLoading && <Button className="mr-2" disabled={isLoading} variant="outline-primary" onClick={props.onClose}>
                    {t("Cancel")}
                </Button>}
                <Button disabled={isLoading} variant="primary" onClick={createDomain}>
                    {!isLoading && <span className="confirm-tex">{t("Add domain")}</span>}
                    {isLoading && <div className="loader">{t("Loading...")}</div>}
                </Button>
            </div>
        </Modal>
    );
}
