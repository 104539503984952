import { Button } from "react-bootstrap"
import GuideModal from "../../modals/GuideModal"
import { addModal } from "../../modals/ModalManager"
import "./GuideButton.scss"

const guides = {
    "shoproller": {
        logo: <img className="integration-btn-logo" src="/images/guides/shoproller_logo.png" alt="Shoproller" ></img>,
    },
    "shopify": {
        logo: <img className="integration-btn-logo" src="/images/guides/shopify_logo.svg" alt="Shopify" ></img>,
    },
    "wordpress": {
        logo: <img className="integration-btn-logo" src="/images/guides/wordpress_logo.svg" alt="Wordpress" ></img>,
    },
    "woocommerce": {
        logo: <img className="integration-btn-logo" src="/images/guides/woocommerce_logo.png" alt="Woocommerce" ></img>,
    },
    "magento": {
        logo: <img className="integration-btn-logo" src="/images/guides/magento2_logo.png" alt="Magento" ></img>,
    },
    "wix": {
        logo: <img className="integration-btn-logo" src="/images/guides/wix_logo.svg" alt="Wix" ></img>,
    },
    "voog": {
        logo: <img className="integration-btn-logo" src="/images/guides/voog_logo.svg" alt="Voog" ></img>,
    }
}
export default function GuideButton({ type }) {
    let guide = guides[type]

    if (!guide)
        return null

    function openGuideModal() {
        addModal(<GuideModal />, { type })
    }

    return (
        <Button className="integration-btn" variant="outline-primary" onClick={openGuideModal}>
            {guide?.logo}
        </Button>
    )
}